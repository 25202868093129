import React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import FooterComponent from "../components/footer-component";
import HeroSection from "../components/HeroSection";
import FAQ from "../components/FAQ";
import { Button } from "../components/ui/button";
import { Code, Search, Rocket, Building, ArrowRight, Star, BarChart } from "lucide-react";
import { Testimonial } from "../components/ui/testimonial-card";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getBusinessLocations } from "@/services/organization.service";
import { generateOrganizationSchema, generateWebsiteSchema, generateFAQSchema } from "@/utils/schema";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import SeoService from "../components/services/SeoService";
import SocialMediaService from "../components/services/SocialMediaService";
import GoogleAdsService from "../components/services/GoogleAdsService";
import ContentStrategyService from "../components/services/ContentStrategyService";
import EmailMarketingService from "../components/services/EmailMarketingService";
import EmailOutreachService from "../components/services/EmailOutreachService";

const Index = () => {
  const { data: locations } = useQuery({
    queryKey: ['businessLocations'],
    queryFn: getBusinessLocations,
  });

  const mainLocation = locations?.[0];

  const testimonials = [
    {
      name: "Rich Hill",
      role: "Owner",
      company: "RK Athletics",
      rating: 5,
      testimonial: "Gerald and his team at Ironball Marketing are fantastic to work with. I had a chance to chat with Gerald regarding SEO improvements for my site, and he gave me a laundry list of things to do right off the bat. Knowing nothing about SEO personally, he made the process much easier and layed out tools I can use to optimize my site. I'm looking forward to implementing this into my business. I'd highly recommend their services for anyone looking to improve their site and SEO."
    },
    {
      name: "Alex Aleman",
      role: "Owner",
      company: "Vancouver Bouncy Castle",
      rating: 5,
      testimonial: "Ironball Marketing joined forces with us during our first year in operations. They have helped us accomplish true growth at a scale we did not expect and provided us with the tools and systems to manage it all."
    },
    {
      name: "Adam Giesbrecht",
      role: "Founder",
      company: "Integral Physio",
      rating: 5,
      testimonial: "I wanted to sincerely thank you for everything you have done to make Integral the success it is today."
    }
  ];

  const faqs = [
    {
      question: "What services does Ironball Marketing offer?",
      answer: "We offer a comprehensive suite of digital services including web development, digital marketing, software solutions, and SaaS development. Our expertise spans across custom website creation, SEO optimization, social media marketing, and enterprise-grade software development."
    },
    {
      question: "What is SEO?",
      answer: "Search Engine Optimization (SEO) is the process of optimizing a website to improve its visibility and ranking in search engine results pages (SERPs). It involves various techniques such as keyword research, on-page optimization, content creation, and link building."
    },
    {
      question: "What is SaaS development?",
      answer: "Software as a Service (SaaS) development is the process of creating and delivering software applications over the internet. It allows users to access and use software applications without the need to install or maintain them on their own devices."
    }
  ];

  return (
    <div className="min-h-screen bg-black flex flex-col relative overflow-hidden">
      <Helmet>
        <title>Vancouver Digital Agency | Ironball Marketing</title>
        <meta name="description" content="Vancouver's premier digital agency specializing in custom software development, digital marketing, and SaaS solutions. Transform your business with our innovative approaches." />
        <meta property="og:title" content="Vancouver Digital Agency | Ironball Marketing" />
        <meta property="og:description" content="Partner with Vancouver's leading digital agency for innovative solutions that drive real business growth. Experience our transparent, results-driven approach." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ironballmarketing.com" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Vancouver Digital Agency | Ironball Marketing" />
        <meta name="twitter:description" content="Partner with Vancouver's leading digital agency for innovative solutions that drive real business growth. Experience our transparent, results-driven approach." />
        <meta name="keywords" content="vancouver digital agency, custom software development, digital marketing vancouver, web development, SEO vancouver, project management software, business solutions" />
        <meta name="author" content="Ironball Marketing" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://ironballmarketing.com" />
        <meta name="theme-color" content="#0EA5E9" />
        
        <script type="application/ld+json">
          {JSON.stringify(generateWebsiteSchema(), null, 2)}
        </script>
        
        {mainLocation && (
          <script type="application/ld+json">
            {JSON.stringify(generateOrganizationSchema(mainLocation), null, 2)}
          </script>
        )}
        
        <script type="application/ld+json">
          {JSON.stringify(generateFAQSchema(faqs), null, 2)}
        </script>
      </Helmet>
      <Header />
      <HeroSection />
      <div className="relative z-10">
        <main className="flex-grow">
          <div className="container mx-auto px-4">
            <div className="text-center mb-16 mt-20 animate-fade-in">
              <h2 className="text-4xl font-bold text-white mb-4">Our Digital Services</h2>
              <p className="text-gray-300 text-lg max-w-2xl mx-auto">
                Comprehensive solutions to accelerate your business growth in the digital age
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-20 max-w-5xl mx-auto">
              <div className="group relative overflow-hidden rounded-xl bg-white/5 backdrop-blur-sm border border-white/10 p-8 hover:bg-white/10 transition-all duration-300">
                <div className="absolute inset-0 bg-gradient-to-br from-[#8B5CF6]/10 via-transparent to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                <div className="mb-4 p-3 bg-[#0EA5E9]/10 rounded-lg w-fit relative z-10">
                  <Code className="h-6 w-6 text-[#0EA5E9]" />
                </div>
                <h3 className="text-xl font-semibold text-white mb-3 relative z-10">Web Development</h3>
                <p className="text-gray-400 mb-4 relative z-10">Custom websites and web applications built with modern technologies to deliver exceptional user experiences.</p>
                <Link to="/web-development" className="w-full">
                  <Button variant="ghost" className="w-full text-[#0EA5E9] hover:text-[#0EA5E9] hover:bg-[#0EA5E9]/10 group-hover:translate-x-2 transition-transform duration-300 relative z-10">
                    Explore Web Development Services <ArrowRight className="ml-2 h-4 w-4" />
                  </Button>
                </Link>
              </div>

              <div className="group relative overflow-hidden rounded-xl bg-white/5 backdrop-blur-sm border border-white/10 p-8 hover:bg-white/10 transition-all duration-300">
                <div className="absolute inset-0 bg-gradient-to-br from-[#0EA5E9]/10 via-transparent to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                <div className="mb-4 p-3 bg-[#0EA5E9]/10 rounded-lg w-fit relative z-10">
                  <BarChart className="h-6 w-6 text-[#0EA5E9]" />
                </div>
                <h3 className="text-xl font-semibold text-white mb-3 relative z-10">Digital Marketing</h3>
                <p className="text-gray-400 mb-4 relative z-10">Comprehensive digital marketing solutions including SEO, social media, email campaigns, and paid advertising strategies.</p>
                <Link to="/digital-marketing" className="w-full">
                  <Button variant="ghost" className="w-full text-[#0EA5E9] hover:text-[#0EA5E9] hover:bg-[#0EA5E9]/10 group-hover:translate-x-2 transition-transform duration-300 relative z-10">
                    View Digital Marketing Solutions <ArrowRight className="ml-2 h-4 w-4" />
                  </Button>
                </Link>
              </div>

              <div className="group relative overflow-hidden rounded-xl bg-white/5 backdrop-blur-sm border border-white/10 p-8 hover:bg-white/10 transition-all duration-300">
                <div className="absolute inset-0 bg-gradient-to-br from-[#D946EF]/10 via-transparent to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                <div className="mb-4 p-3 bg-[#0EA5E9]/10 rounded-lg w-fit relative z-10">
                  <Rocket className="h-6 w-6 text-[#0EA5E9]" />
                </div>
                <h3 className="text-xl font-semibold text-white mb-3 relative z-10">SaaS Development</h3>
                <p className="text-gray-400 mb-4 relative z-10">Cloud-based software solutions that scale with your business and provide value to your customers.</p>
                <Link to="/saas-development" className="w-full">
                  <Button variant="ghost" className="w-full text-[#0EA5E9] hover:text-[#0EA5E9] hover:bg-[#0EA5E9]/10 group-hover:translate-x-2 transition-transform duration-300 relative z-10">
                    Discover SaaS Development Services <ArrowRight className="ml-2 h-4 w-4" />
                  </Button>
                </Link>
              </div>

              <div className="group relative overflow-hidden rounded-xl bg-white/5 backdrop-blur-sm border border-white/10 p-8 hover:bg-white/10 transition-all duration-300">
                <div className="absolute inset-0 bg-gradient-to-br from-[#8B5CF6]/10 via-transparent to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                <div className="mb-4 p-3 bg-[#0EA5E9]/10 rounded-lg w-fit relative z-10">
                  <Building className="h-6 w-6 text-[#0EA5E9]" />
                </div>
                <h3 className="text-xl font-semibold text-white mb-3 relative z-10">Enterprise Software</h3>
                <p className="text-gray-400 mb-4 relative z-10">Custom enterprise solutions that streamline operations and improve business efficiency.</p>
                <Link to="/software-solutions" className="w-full">
                  <Button variant="ghost" className="w-full text-[#0EA5E9] hover:text-[#0EA5E9] hover:bg-[#0EA5E9]/10 group-hover:translate-x-2 transition-transform duration-300 relative z-10">
                    Explore Enterprise Software Solutions <ArrowRight className="ml-2 h-4 w-4" />
                  </Button>
                </Link>
              </div>
            </div>

            <section className="mb-20 mt-32">
              <div className="text-center mb-16">
                <h2 className="text-4xl font-bold text-white mb-4">Digital Marketing Solutions</h2>
                <p className="text-gray-300 text-lg max-w-2xl mx-auto">
                  Transform your online presence with our comprehensive digital marketing services. From SEO to content strategy, we help you reach and engage your target audience effectively.
                </p>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                <div className="transform hover:scale-105 transition-transform duration-300">
                  <SeoService />
                </div>
                <div className="transform hover:scale-105 transition-transform duration-300">
                  <GoogleAdsService />
                </div>
                <div className="transform hover:scale-105 transition-transform duration-300">
                  <SocialMediaService />
                </div>
                <div className="transform hover:scale-105 transition-transform duration-300">
                  <ContentStrategyService />
                </div>
                <div className="transform hover:scale-105 transition-transform duration-300">
                  <EmailMarketingService />
                </div>
                <div className="transform hover:scale-105 transition-transform duration-300">
                  <EmailOutreachService />
                </div>
              </div>

              <div className="text-center mt-12">
                <Link to="/digital-marketing">
                  <Button 
                    size="lg" 
                    className="bg-[#0EA5E9]/20 hover:bg-[#0EA5E9]/30 text-white border border-[#0EA5E9]/50"
                  >
                    <span className="bg-gradient-to-r from-white via-white to-white/90 bg-clip-text text-transparent drop-shadow-[0_2px_2px_rgba(0,0,0,0.4)] font-semibold flex items-center gap-2">
                      Explore All Digital Marketing Services
                      <ArrowRight className="h-5 w-5 text-white" />
                    </span>
                  </Button>
                </Link>
              </div>
            </section>

            <section className="mb-20">
              <div className="text-center mb-16">
                <h2 className="text-4xl font-bold text-white mb-4">What Our Clients Say</h2>
                <p className="text-gray-300 text-lg">Trusted by businesses worldwide</p>
              </div>
              <div className="px-12">
                <Carousel
                  opts={{
                    align: "start",
                    loop: true,
                  }}
                  className="w-full"
                >
                  <CarouselContent className="-ml-2 md:-ml-4">
                    {testimonials.map((testimonial, index) => (
                      <CarouselItem key={index} className="pl-2 md:pl-4 md:basis-1/2 lg:basis-1/3">
                        <Testimonial
                          {...testimonial}
                          className="bg-white/5 backdrop-blur-sm border-white/10 hover:bg-white/10 text-white [&_p]:text-gray-300 [&_h3]:text-white [&_.text-muted-foreground]:text-gray-400 [&_.text-muted]:text-gray-500"
                        />
                      </CarouselItem>
                    ))}
                  </CarouselContent>
                  <CarouselPrevious className="border-white/10 bg-white/5 hover:bg-white/10 text-white" />
                  <CarouselNext className="border-white/10 bg-white/5 hover:bg-white/10 text-white" />
                </Carousel>
              </div>
            </section>

            <FAQ />

            <section className="py-24 relative overflow-hidden border-y border-white/5">
              <div className="absolute inset-0 bg-gradient-to-r from-[#0EA5E9]/10 via-[#0EA5E9]/5 to-transparent">
                <div className="absolute inset-0 opacity-30">
                  <div className="absolute h-full w-1/6 bg-gradient-to-b from-[#0EA5E9]/20 via-[#0EA5E9]/10 to-transparent animate-slide-down" style={{ left: '10%', animationDelay: '0s', animationDuration: '3s' }} />
                  <div className="absolute h-full w-1/6 bg-gradient-to-b from-[#0EA5E9]/20 via-[#0EA5E9]/10 to-transparent animate-slide-down" style={{ left: '70%', animationDelay: '1.5s', animationDuration: '4.5s' }} />
                </div>
              </div>
              <div className="container mx-auto px-4 relative z-10">
                <div className="max-w-4xl mx-auto bg-white/5 backdrop-blur-sm rounded-2xl p-12 border border-white/10">
                  <div className="text-center">
                    <h2 className="text-4xl font-bold text-white mb-6">Transform Your Digital Presence</h2>
                    <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
                      Partner with our expert team to elevate your business with innovative digital solutions and strategies.
                    </p>
                    <div className="flex flex-col sm:flex-row gap-4 justify-center">
                      <Link to="/contact" className="min-h-[44px] block">
                        <Button 
                          size="lg" 
                          className="w-full sm:w-auto text-lg py-7 px-8 min-h-[44px] bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] hover:opacity-90 text-white font-semibold shadow-[0_0_20px_rgba(14,165,233,0.3)] hover:shadow-[0_0_30px_rgba(14,165,233,0.5)] transform hover:scale-105 transition-all duration-300"
                          aria-label="Get Started - Contact Us"
                        >
                          <span className="bg-gradient-to-r from-white via-white to-white/90 bg-clip-text text-transparent drop-shadow-[0_2px_2px_rgba(0,0,0,0.4)] min-h-[44px] flex items-center">
                            Get Started
                            <ArrowRight className="ml-2 h-5 w-5 text-white" />
                          </span>
                        </Button>
                      </Link>
                      <Link to="/schedule" className="min-h-[44px] block">
                        <Button 
                          size="lg" 
                          variant="outline" 
                          className="w-full sm:w-auto text-lg py-7 px-8 min-h-[44px] bg-white/5 backdrop-blur-sm border-2 border-white/10 hover:bg-white/10 hover:border-[#0EA5E9]/50 transform hover:scale-105 transition-all duration-300"
                          aria-label="Schedule a Call"
                        >
                          <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent min-h-[44px] flex items-center">
                            Schedule Call
                            <ArrowRight className="ml-2 h-5 w-5 text-[#0EA5E9]" />
                          </span>
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
        <FooterComponent />
      </div>
    </div>
  );
};

export default Index;
