
import { Link } from "react-router-dom";
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from "../ui/card";
import { Edit, ArrowRight } from "lucide-react";
import { Button } from "../ui/button";

const ContentStrategyService = () => {
  return (
    <Card className="w-full h-[400px] bg-white/5 backdrop-blur-sm border-white/10 hover:bg-white/10 transition-all duration-300 flex flex-col">
      <CardHeader className="flex-none">
        <div className="flex items-center gap-4">
          <Edit className="h-8 w-8 text-[#0EA5E9]" />
          <div>
            <CardTitle className="text-white">Content Strategy</CardTitle>
            <CardDescription className="text-gray-400">Create compelling content that converts</CardDescription>
          </div>
        </div>
      </CardHeader>
      <CardContent className="flex-grow">
        <p className="text-gray-300 mb-6">
          Transform your content strategy with data-driven insights and compelling narratives that engage your target audience.
        </p>
        <ul className="space-y-2">
          <li className="flex items-center gap-2 text-gray-300">
            <ArrowRight className="h-4 w-4 text-[#0EA5E9]" />
            <span>Entity-first content development</span>
          </li>
          <li className="flex items-center gap-2 text-gray-300">
            <ArrowRight className="h-4 w-4 text-[#0EA5E9]" />
            <span>YMYL content expertise</span>
          </li>
          <li className="flex items-center gap-2 text-gray-300">
            <ArrowRight className="h-4 w-4 text-[#0EA5E9]" />
            <span>Content performance tracking</span>
          </li>
        </ul>
      </CardContent>
      <CardFooter className="flex-none">
        <Button className="w-full bg-[#0EA5E9]/20 hover:bg-[#0EA5E9]/30 text-white border border-[#0EA5E9]/50">
          <Link to="/digital-marketing/content-strategy" className="w-full">
            <span className="bg-gradient-to-r from-white via-white to-white/90 bg-clip-text text-transparent drop-shadow-[0_2px_2px_rgba(0,0,0,0.4)] font-semibold flex items-center gap-2 justify-center">
              Start Creating
              <ArrowRight className="h-4 w-4 text-white" />
            </span>
          </Link>
        </Button>
      </CardFooter>
    </Card>
  );
};

export default ContentStrategyService;
