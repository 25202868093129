
import React from "react";
import { CheckCircle } from "lucide-react";

const ServiceAreas = () => {
  const cities = [
    "Vancouver",
    "Burnaby",
    "Richmond",
    "Surrey",
    "Coquitlam",
    "Port Coquitlam",
    "Port Moody",
    "North Vancouver",
    "West Vancouver",
    "New Westminster",
    "Delta",
    "White Rock",
    "Langley",
    "Maple Ridge",
    "Pitt Meadows",
    "Abbotsford",
    "Chilliwack"
  ];

  return (
    <section className="py-24 relative overflow-hidden border-b border-white/5">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-white mb-6">Service Areas</h2>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto">
            Serving businesses throughout Greater Vancouver and the Lower Mainland
          </p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 max-w-5xl mx-auto">
          {cities.map((city) => (
            <div key={city} className="flex items-center gap-3">
              <CheckCircle className="h-5 w-5 text-[#0EA5E9] flex-shrink-0" />
              <span className="text-gray-300">{city}</span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServiceAreas;
