
import React from "react";
import { Helmet } from "react-helmet";
import Header from "@/components/Header";
import FooterComponent from "@/components/footer-component";
import { Button } from "@/components/ui/button";
import { 
  ArrowRight, Search, TrendingUp, Lightbulb, ShieldCheck, 
  Users, LayoutDashboard, DollarSign, Target, AlertTriangle, 
  CheckCircle, XCircle, BarChart3, Globe
} from "lucide-react";
import { Link } from "react-router-dom";
import ServiceAreas from "@/components/ServiceAreas";

const GoogleAdsPage = () => {
  return (
    <div className="min-h-screen bg-black flex flex-col">
      <Helmet>
        <title>Expert Google Ads Management Vancouver | PPC Marketing Services</title>
        <meta name="description" content="Maximize ROI with Vancouver's leading Google Ads experts. Specialized in search, display, and remarketing campaigns with proven conversion tracking systems." />
        <meta property="og:title" content="Expert Google Ads Management Vancouver | PPC Marketing Services" />
        <meta property="og:description" content="Transform your ad spend into revenue with data-driven Google Ads campaigns. Vancouver's trusted PPC specialists delivering measurable results." />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Expert Google Ads Management Vancouver | PPC Marketing Services" />
        <meta name="twitter:description" content="Transform your ad spend into revenue with data-driven Google Ads campaigns. Vancouver's trusted PPC specialists delivering measurable results." />
      </Helmet>
      <Header />
      <main className="flex-grow pt-20">
        {/* Hero Section */}
        <section className="relative text-white py-24 px-4 overflow-hidden border-b border-white/5">
          <div className="absolute inset-0">
            <div 
              className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1552664730-d307ca884978?q=80&w=1920&auto=format')] 
              bg-cover bg-center bg-no-repeat"
              style={{ backgroundSize: 'cover' }}
            />
            <div className="absolute inset-0 bg-gradient-to-b from-black/80 via-black/70 to-black" />
          </div>

          <div className="absolute inset-0">
            <div className="absolute -left-20 top-20 h-[400px] w-[400px] rounded-full bg-[#8B5CF6]/30 blur-[100px] animate-pulse" />
            <div className="absolute -right-20 bottom-20 h-[400px] w-[400px] rounded-full bg-[#0EA5E9]/30 blur-[100px] animate-pulse" />
            <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]" />
          </div>

          <div className="container mx-auto relative z-10">
            <div className="max-w-4xl mx-auto text-center">
              <div className="inline-block p-4 bg-white/5 backdrop-blur-sm rounded-2xl mb-6 border border-white/10">
                <Search className="h-8 w-8 text-[#0EA5E9]" />
              </div>
              <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-6">
                Maximize ROI with
                <br />
                <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent">
                  Google Ads
                </span>
              </h1>
              <p className="text-xl text-gray-300 mb-10 leading-relaxed max-w-3xl mx-auto">
                Drive targeted traffic and increase conversions with our expert Google Ads management services.
                We create data-driven campaigns that deliver measurable results.
              </p>
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <Link to="/contact">
                  <Button size="lg" className="w-full sm:w-auto text-lg py-7 px-8 bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] hover:opacity-90 text-white font-semibold shadow-[0_0_20px_rgba(14,165,233,0.3)] hover:shadow-[0_0_30px_rgba(14,165,233,0.5)] transform hover:scale-105 transition-all duration-300">
                    <span className="bg-gradient-to-r from-white via-white to-white/90 bg-clip-text text-transparent drop-shadow-[0_2px_2px_rgba(0,0,0,0.4)]">Get Started</span>
                    <ArrowRight className="ml-2 h-5 w-5 text-white" />
                  </Button>
                </Link>
                <Link to="/case-studies">
                  <Button size="lg" variant="outline" className="w-full sm:w-auto text-lg py-7 px-8 bg-white/5 backdrop-blur-sm border-2 border-white/10 hover:bg-white/10 hover:border-[#0EA5E9]/50 transform hover:scale-105 transition-all duration-300">
                    <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent">View Case Studies</span>
                    <ArrowRight className="ml-2 h-5 w-5 text-[#0EA5E9]" />
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/* Services Section */}
        <section className="py-24 relative overflow-hidden border-b border-white/5">
          <div className="absolute inset-0 bg-gradient-to-r from-[#0EA5E9]/10 via-[#0EA5E9]/5 to-transparent">
            <div className="absolute inset-0 opacity-30">
              <div className="absolute h-full w-1/6 bg-gradient-to-b from-[#0EA5E9]/20 via-[#0EA5E9]/10 to-transparent animate-slide-down" style={{ left: '10%', animationDelay: '0s', animationDuration: '3s' }} />
              <div className="absolute h-full w-1/6 bg-gradient-to-b from-[#0EA5E9]/20 via-[#0EA5E9]/10 to-transparent animate-slide-down" style={{ left: '70%', animationDelay: '1.5s', animationDuration: '4.5s' }} />
            </div>
          </div>
          <div className="container mx-auto px-4 relative z-10">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold text-white mb-6">Our Google Ads Services</h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                Comprehensive Google Ads solutions tailored to your business goals.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <TrendingUp className="h-10 w-10 text-[#0EA5E9] mb-6" />
                <h3 className="text-2xl font-semibold text-white mb-4">PPC Campaign Management</h3>
                <p className="text-gray-300">
                  Maximize your ROI with expertly managed PPC campaigns. We handle everything from keyword research to ad optimization.
                </p>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <Lightbulb className="h-10 w-10 text-[#0EA5E9] mb-6" />
                <h3 className="text-2xl font-semibold text-white mb-4">Conversion Optimization</h3>
                <p className="text-gray-300">
                  Improve your conversion rates with A/B testing, landing page optimization, and data-driven insights.
                </p>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <ShieldCheck className="h-10 w-10 text-[#0EA5E9] mb-6" />
                <h3 className="text-2xl font-semibold text-white mb-4">Remarketing Campaigns</h3>
                <p className="text-gray-300">
                  Re-engage your website visitors with targeted remarketing campaigns that drive conversions and increase brand awareness.
                </p>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <Users className="h-10 w-10 text-[#0EA5E9] mb-6" />
                <h3 className="text-2xl font-semibold text-white mb-4">Audience Targeting</h3>
                <p className="text-gray-300">
                  Reach your ideal customers with advanced audience targeting options, including demographic, interest, and behavioral targeting.
                </p>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <LayoutDashboard className="h-10 w-10 text-[#0EA5E9] mb-6" />
                <h3 className="text-2xl font-semibold text-white mb-4">Performance Reporting</h3>
                <p className="text-gray-300">
                  Track your campaign performance with detailed reports and analytics. We provide insights to help you make informed decisions.
                </p>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <Globe className="h-10 w-10 text-[#0EA5E9] mb-6" />
                <h3 className="text-2xl font-semibold text-white mb-4">Local Ads Expertise</h3>
                <p className="text-gray-300">
                  Dominate your local market with targeted geographic campaigns, location extensions, and local inventory ads optimization.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Why Google Ads Section */}
        <section className="py-24 relative overflow-hidden border-b border-white/5">
          <div className="container mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold text-white mb-6">Why Google Ads is Essential for Your Business</h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                In today's digital landscape, Google Ads dominates the online advertising space, 
                offering unmatched visibility and reach for businesses of all sizes.
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 text-center">
                <h3 className="text-2xl font-semibold text-white mb-4">Digital Real Estate Dominance</h3>
                <p className="text-gray-300">
                  Google Ads takes up to 85% of the above-the-fold content on search results, 
                  making it prime real estate for visibility. When someone searches for your 
                  products or services, your ad appears before organic results, giving you an 
                  immediate advantage over competitors.
                </p>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 text-center">
                <h3 className="text-2xl font-semibold text-white mb-4">Instant Traffic & SEO Benefits</h3>
                <p className="text-gray-300">
                  While organic SEO takes time, Google Ads delivers immediate traffic. This traffic 
                  can improve your site's engagement metrics, indirectly benefiting your SEO. 
                  Plus, the data gathered from ads can inform your organic SEO strategy.
                </p>
              </div>
            </div>

            <div className="bg-gradient-to-r from-[#8B5CF6]/20 via-[#0EA5E9]/20 to-[#38BDF8]/20 rounded-xl p-8 mb-16 text-center">
              <h3 className="text-2xl font-semibold text-white mb-6">ROI-Focused Advertising</h3>
              <p className="text-gray-300 mb-6">
                Every dollar spent on Google Ads should generate measurable returns. Our approach ensures:
              </p>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="flex flex-col items-center gap-3">
                  <DollarSign className="h-6 w-6 text-[#0EA5E9]" />
                  <p className="text-gray-300">Precise budget control with defined cost-per-acquisition targets</p>
                </div>
                <div className="flex flex-col items-center gap-3">
                  <Target className="h-6 w-6 text-[#0EA5E9]" />
                  <p className="text-gray-300">Targeted campaigns that reach your ideal customers</p>
                </div>
                <div className="flex flex-col items-center gap-3">
                  <BarChart3 className="h-6 w-6 text-[#0EA5E9]" />
                  <p className="text-gray-300">Continuous optimization based on performance data</p>
                </div>
              </div>
            </div>

            {/* Professional Management vs. DIY Section */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-16">
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 text-center">
                <div className="flex flex-col items-center gap-4">
                  <CheckCircle className="h-10 w-10 text-green-500" />
                  <h4 className="text-white text-2xl font-semibold mb-6">Professional Management</h4>
                  <ul className="text-gray-300 space-y-4">
                    <li className="flex items-center justify-center gap-2">
                      <CheckCircle className="h-5 w-5 text-green-500 flex-shrink-0" />
                      <span>Advanced fraud detection and bot click prevention</span>
                    </li>
                    <li className="flex items-center justify-center gap-2">
                      <CheckCircle className="h-5 w-5 text-green-500 flex-shrink-0" />
                      <span>Strategic keyword research and competitor analysis</span>
                    </li>
                    <li className="flex items-center justify-center gap-2">
                      <CheckCircle className="h-5 w-5 text-green-500 flex-shrink-0" />
                      <span>Regular A/B testing and optimization</span>
                    </li>
                    <li className="flex items-center justify-center gap-2">
                      <CheckCircle className="h-5 w-5 text-green-500 flex-shrink-0" />
                      <span>Expert ad copy and landing page optimization</span>
                    </li>
                    <li className="flex items-center justify-center gap-2">
                      <CheckCircle className="h-5 w-5 text-green-500 flex-shrink-0" />
                      <span>Detailed ROI tracking and reporting</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 text-center">
                <div className="flex flex-col items-center gap-4">
                  <XCircle className="h-10 w-10 text-red-500" />
                  <h4 className="text-white text-2xl font-semibold mb-6">DIY Approach Risks</h4>
                  <ul className="text-gray-300 space-y-4">
                    <li className="flex items-center justify-center gap-2">
                      <XCircle className="h-5 w-5 text-red-500 flex-shrink-0" />
                      <span>Wasted budget on irrelevant keywords</span>
                    </li>
                    <li className="flex items-center justify-center gap-2">
                      <XCircle className="h-5 w-5 text-red-500 flex-shrink-0" />
                      <span>Vulnerability to click fraud</span>
                    </li>
                    <li className="flex items-center justify-center gap-2">
                      <XCircle className="h-5 w-5 text-red-500 flex-shrink-0" />
                      <span>Poor quality score leading to higher costs</span>
                    </li>
                    <li className="flex items-center justify-center gap-2">
                      <XCircle className="h-5 w-5 text-red-500 flex-shrink-0" />
                      <span>Missed optimization opportunities</span>
                    </li>
                    <li className="flex items-center justify-center gap-2">
                      <XCircle className="h-5 w-5 text-red-500 flex-shrink-0" />
                      <span>Limited access to advanced features</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* The Cost of Not Advertising */}
            <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 mb-16">
              <div className="text-center">
                <h3 className="text-2xl font-semibold text-white mb-6">The Cost of Not Advertising</h3>
                <div className="space-y-8">
                  <div className="space-y-4 text-gray-300 max-w-3xl mx-auto">
                    <p className="text-center">
                      Not investing in Google Ads means missing out on high-intent customers actively 
                      searching for your products or services. Your competitors will capture this 
                      valuable traffic instead.
                    </p>
                    <p className="text-center">
                      Consider this: The average ROI for Google Ads is $8 for every $1 spent. 
                      By not advertising, you're potentially leaving significant revenue on the table.
                    </p>
                  </div>
                  
                  <div className="flex flex-col items-center">
                    <h4 className="text-white font-semibold mb-4">Lost Opportunities:</h4>
                    <ul className="text-gray-300 space-y-3">
                      <li className="flex items-center justify-center gap-2">
                        <AlertTriangle className="h-5 w-5 text-yellow-500" />
                        <span>Immediate market visibility</span>
                      </li>
                      <li className="flex items-center justify-center gap-2">
                        <AlertTriangle className="h-5 w-5 text-yellow-500" />
                        <span>High-intent customer acquisition</span>
                      </li>
                      <li className="flex items-center justify-center gap-2">
                        <AlertTriangle className="h-5 w-5 text-yellow-500" />
                        <span>Brand awareness building</span>
                      </li>
                      <li className="flex items-center justify-center gap-2">
                        <AlertTriangle className="h-5 w-5 text-yellow-500" />
                        <span>Competitive market position</span>
                      </li>
                      <li className="flex items-center justify-center gap-2">
                        <AlertTriangle className="h-5 w-5 text-yellow-500" />
                        <span>Valuable customer insights</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="py-24 relative overflow-hidden border-y border-white/5">
          <div className="absolute inset-0 bg-gradient-to-r from-[#0EA5E9]/10 via-[#0EA5E9]/5 to-transparent">
            <div className="absolute inset-0 opacity-30">
              <div className="absolute h-full w-1/6 bg-gradient-to-b from-[#0EA5E9]/20 via-[#0EA5E9]/10 to-transparent animate-slide-down" style={{ left: '10%', animationDelay: '0s', animationDuration: '3s' }} />
              <div className="absolute h-full w-1/6 bg-gradient-to-b from-[#0EA5E9]/20 via-[#0EA5E9]/10 to-transparent animate-slide-down" style={{ left: '70%', animationDelay: '1.5s', animationDuration: '4.5s' }} />
            </div>
          </div>
          <div className="container mx-auto px-4 relative z-10">
            <div className="max-w-4xl mx-auto bg-white/5 backdrop-blur-sm rounded-2xl p-12 border border-white/10">
              <div className="text-center">
                <h2 className="text-4xl font-bold text-white mb-6">Ready to Maximize Your ROI?</h2>
                <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
                  Get a free Google Ads audit and discover opportunities to improve your campaign performance.
                </p>
                <div className="flex flex-col sm:flex-row gap-4 justify-center">
                  <Link to="/contact">
                    <Button size="lg" className="w-full sm:w-auto text-lg py-7 px-8 bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] hover:opacity-90 text-white font-semibold shadow-[0_0_20px_rgba(14,165,233,0.3)] hover:shadow-[0_0_30px_rgba(14,165,233,0.5)] transform hover:scale-105 transition-all duration-300">
                      <span className="bg-gradient-to-r from-white via-white to-white/90 bg-clip-text text-transparent drop-shadow-[0_2px_2px_rgba(0,0,0,0.4)]">Get Started</span>
                      <ArrowRight className="ml-2 h-5 w-5 text-white" />
                    </Button>
                  </Link>
                  <Link to="/schedule">
                    <Button size="lg" variant="outline" className="w-full sm:w-auto text-lg py-7 px-8 bg-white/5 backdrop-blur-sm border-2 border-white/10 hover:bg-white/10 hover:border-[#0EA5E9]/50 transform hover:scale-105 transition-all duration-300">
                      <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent">Schedule Call</span>
                      <ArrowRight className="ml-2 h-5 w-5 text-[#0EA5E9]" />
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <FooterComponent />
    </div>
  );
};

export default GoogleAdsPage;
