
const CaseStudiesStats = () => {
  return (
    <section className="relative py-20 border-b border-white/5">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="group relative overflow-hidden rounded-xl bg-white/5 backdrop-blur-sm border border-white/10 p-8 hover:bg-white/10 transition-all duration-300">
            <div className="text-4xl font-bold text-[#0EA5E9] mb-2">500+</div>
            <div className="text-white font-medium">Projects Delivered</div>
          </div>
          <div className="group relative overflow-hidden rounded-xl bg-white/5 backdrop-blur-sm border border-white/10 p-8 hover:bg-white/10 transition-all duration-300">
            <div className="text-4xl font-bold text-[#0EA5E9] mb-2">95%</div>
            <div className="text-white font-medium">Client Satisfaction</div>
          </div>
          <div className="group relative overflow-hidden rounded-xl bg-white/5 backdrop-blur-sm border border-white/10 p-8 hover:bg-white/10 transition-all duration-300">
            <div className="text-4xl font-bold text-[#0EA5E9] mb-2">$2B+</div>
            <div className="text-white font-medium">Revenue Generated</div>
          </div>
          <div className="group relative overflow-hidden rounded-xl bg-white/5 backdrop-blur-sm border border-white/10 p-8 hover:bg-white/10 transition-all duration-300">
            <div className="text-4xl font-bold text-[#0EA5E9] mb-2">20+</div>
            <div className="text-white font-medium">Industries Served</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CaseStudiesStats;
