
import { useQuery } from "@tanstack/react-query";
import { getBusinessLocations } from "@/services/organization.service";
import { MapPin, Clock } from "lucide-react";

interface BusinessHours {
  [key: string]: {
    open: string;
    close: string;
  };
}

export const LocationInfo = () => {
  const { data: locations, isLoading } = useQuery({
    queryKey: ['businessLocations'],
    queryFn: getBusinessLocations,
  });

  if (isLoading) return <div>Loading...</div>;
  if (!locations?.length) return null;

  const mainLocation = locations[0];
  const businessHours = mainLocation.business_hours as BusinessHours | undefined;

  return (
    <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6 border border-white/10">
      <div className="flex items-center gap-3 mb-4">
        <MapPin className="h-6 w-6 text-[#0EA5E9]" />
        <h3 className="text-xl font-semibold text-white">{mainLocation.name}</h3>
      </div>
      <div className="space-y-4 text-gray-300">
        <div className="space-y-1">
          <p>{mainLocation.address_line1}</p>
          {mainLocation.address_line2 && <p>{mainLocation.address_line2}</p>}
          <p>{mainLocation.city}, {mainLocation.state} {mainLocation.postal_code}</p>
          <p>{mainLocation.country}</p>
        </div>

        {mainLocation.appointment_only && (
          <div className="flex items-center gap-2 text-sm">
            <Clock className="h-4 w-4 text-[#0EA5E9]" />
            <span>By appointment only</span>
          </div>
        )}

        {businessHours && Object.entries(businessHours).length > 0 && (
          <div className="mt-4">
            <h4 className="text-sm font-medium mb-2">Business Hours</h4>
            <div className="space-y-1 text-sm">
              {Object.entries(businessHours).map(([day, hours]) => (
                <div key={day} className="flex justify-between">
                  <span className="capitalize">{day}</span>
                  <span>{hours.open} - {hours.close}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
