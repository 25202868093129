import React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import FooterComponent from "../components/footer-component";
import { Button } from "../components/ui/button";
import { Code, Shield, Rocket, Layout, FileCode, Server, ArrowRight, Layers, Lock, CheckCircle2, AlertCircle, DollarSign } from "lucide-react";
import { Link } from "react-router-dom";
import ServiceAreas from "@/components/ServiceAreas";

const WebDevelopment = () => {
  return (
    <div className="min-h-screen bg-black flex flex-col">
      <Helmet>
        <title>Professional Web Development Vancouver | Modern Web Solutions</title>
        <meta name="description" content="Expert web development services in Vancouver. Specializing in React, Node.js, and custom web applications that drive business growth and user engagement." />
        <meta property="og:title" content="Professional Web Development Vancouver | Modern Web Solutions" />
        <meta property="og:description" content="Transform your digital presence with Vancouver's web development experts. Custom solutions, modern tech stack, and results-driven development." />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Professional Web Development Vancouver | Modern Web Solutions" />
        <meta name="twitter:description" content="Transform your digital presence with Vancouver's web development experts. Custom solutions, modern tech stack, and results-driven development." />
      </Helmet>
      <Header />
      <main className="flex-grow pt-20">
        <section className="relative text-white py-16 sm:py-24 px-4 overflow-hidden">
          <div className="absolute inset-0">
            <div 
              className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1460925895917-afdab827c52f?q=80&w=1920&auto=format')] 
              bg-cover bg-center bg-no-repeat"
              style={{ backgroundSize: 'cover' }}
            />
            <div className="absolute inset-0 bg-gradient-to-b from-black/80 via-black/70 to-black">
              <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]" />
            </div>
          </div>

          <div className="container mx-auto relative z-10">
            <div className="max-w-4xl mx-auto text-center">
              <div className="inline-block p-4 bg-white/5 backdrop-blur-sm rounded-2xl mb-6 border border-white/10">
                <Code className="h-8 w-8 text-[#0EA5E9]" />
              </div>
              <h1 className="text-3xl sm:text-5xl md:text-6xl font-bold mb-6 leading-tight">
                Custom Web
                <br />
                <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent inline-block">
                  Development Solutions
                </span>
              </h1>
              <p className="text-lg sm:text-xl text-gray-300 mb-10 leading-relaxed max-w-3xl mx-auto">
                We build secure, scalable, and high-performance web applications using cutting-edge technologies.
                No templates, no compromises – just pure custom code tailored to your needs.
              </p>
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <Link to="/contact" className="w-full sm:w-auto">
                  <Button size="lg" className="w-full sm:w-auto text-lg py-6 px-8 bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] hover:opacity-90 text-white">
                    Start Your Custom Project
                    <ArrowRight className="ml-2 h-5 w-5 text-white" />
                  </Button>
                </Link>
                <Link to="/case-studies" className="w-full sm:w-auto">
                  <Button size="lg" variant="outline" className="w-full sm:w-auto text-lg py-6 px-8 bg-white/5 backdrop-blur-sm border border-white/10 hover:bg-white/10">
                    <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent">View Case Studies</span>
                    <ArrowRight className="ml-2 h-5 w-5 text-[#0EA5E9]" />
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="py-24 bg-white/5">
          <div className="container mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold text-white mb-6">Websites for Every Business</h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                Whether you're a small local business or a large enterprise, we build websites 
                that perfectly match your needs and budget. No unnecessary features, 
                just clean, efficient, and secure web solutions.
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <h3 className="text-2xl font-semibold text-white mb-4">Small Business</h3>
                <ul className="space-y-4">
                  <li className="flex items-start gap-3 text-gray-300">
                    <CheckCircle2 className="h-6 w-6 text-[#0EA5E9] flex-shrink-0 mt-1" />
                    <span>Professional business website that loads instantly</span>
                  </li>
                  <li className="flex items-start gap-3 text-gray-300">
                    <CheckCircle2 className="h-6 w-6 text-[#0EA5E9] flex-shrink-0 mt-1" />
                    <span>Mobile-friendly design that works on all devices</span>
                  </li>
                  <li className="flex items-start gap-3 text-gray-300">
                    <CheckCircle2 className="h-6 w-6 text-[#0EA5E9] flex-shrink-0 mt-1" />
                    <span>Easy content updates without technical knowledge</span>
                  </li>
                </ul>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <h3 className="text-2xl font-semibold text-white mb-4">Growing Business</h3>
                <ul className="space-y-4">
                  <li className="flex items-start gap-3 text-gray-300">
                    <CheckCircle2 className="h-6 w-6 text-[#0EA5E9] flex-shrink-0 mt-1" />
                    <span>E-commerce integration with secure payments</span>
                  </li>
                  <li className="flex items-start gap-3 text-gray-300">
                    <CheckCircle2 className="h-6 w-6 text-[#0EA5E9] flex-shrink-0 mt-1" />
                    <span>Customer management and booking systems</span>
                  </li>
                  <li className="flex items-start gap-3 text-gray-300">
                    <CheckCircle2 className="h-6 w-6 text-[#0EA5E9] flex-shrink-0 mt-1" />
                    <span>Integration with your existing business tools</span>
                  </li>
                </ul>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <h3 className="text-2xl font-semibold text-white mb-4">Enterprise</h3>
                <ul className="space-y-4">
                  <li className="flex items-start gap-3 text-gray-300">
                    <CheckCircle2 className="h-6 w-6 text-[#0EA5E9] flex-shrink-0 mt-1" />
                    <span>Custom web applications and portals</span>
                  </li>
                  <li className="flex items-start gap-3 text-gray-300">
                    <CheckCircle2 className="h-6 w-6 text-[#0EA5E9] flex-shrink-0 mt-1" />
                    <span>Advanced security and user management</span>
                  </li>
                  <li className="flex items-start gap-3 text-gray-300">
                    <CheckCircle2 className="h-6 w-6 text-[#0EA5E9] flex-shrink-0 mt-1" />
                    <span>Scalable infrastructure for high traffic</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="py-24">
          <div className="container mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold text-white mb-6">Why Choose Custom Development?</h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                Understand the advantages of our custom development approach versus 
                traditional template-based platforms.
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10">
                <h3 className="text-2xl font-semibold text-white mb-6 flex items-center gap-3">
                  <AlertCircle className="h-8 w-8 text-red-500" />
                  Template-Based Platforms
                </h3>
                <ul className="space-y-4">
                  <li className="flex items-start gap-3 text-gray-300">
                    <AlertCircle className="h-6 w-6 text-red-500 flex-shrink-0 mt-1" />
                    <span>Ongoing costs for plugins and maintenance</span>
                  </li>
                  <li className="flex items-start gap-3 text-gray-300">
                    <AlertCircle className="h-6 w-6 text-red-500 flex-shrink-0 mt-1" />
                    <span>Security vulnerabilities from third-party plugins</span>
                  </li>
                  <li className="flex items-start gap-3 text-gray-300">
                    <AlertCircle className="h-6 w-6 text-red-500 flex-shrink-0 mt-1" />
                    <span>Slow loading times due to bloated code</span>
                  </li>
                  <li className="flex items-start gap-3 text-gray-300">
                    <AlertCircle className="h-6 w-6 text-red-500 flex-shrink-0 mt-1" />
                    <span>Limited customization options</span>
                  </li>
                  <li className="flex items-start gap-3 text-gray-300">
                    <AlertCircle className="h-6 w-6 text-red-500 flex-shrink-0 mt-1" />
                    <span>Constant updates and maintenance required</span>
                  </li>
                </ul>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10">
                <h3 className="text-2xl font-semibold text-white mb-6 flex items-center gap-3">
                  <CheckCircle2 className="h-8 w-8 text-[#0EA5E9]" />
                  Our Custom Development
                </h3>
                <ul className="space-y-4">
                  <li className="flex items-start gap-3 text-gray-300">
                    <CheckCircle2 className="h-6 w-6 text-[#0EA5E9] flex-shrink-0 mt-1" />
                    <span>One-time investment, minimal ongoing costs</span>
                  </li>
                  <li className="flex items-start gap-3 text-gray-300">
                    <CheckCircle2 className="h-6 w-6 text-[#0EA5E9] flex-shrink-0 mt-1" />
                    <span>Built-in security, no vulnerable plugins</span>
                  </li>
                  <li className="flex items-start gap-3 text-gray-300">
                    <CheckCircle2 className="h-6 w-6 text-[#0EA5E9] flex-shrink-0 mt-1" />
                    <span>Lightning-fast performance, clean code</span>
                  </li>
                  <li className="flex items-start gap-3 text-gray-300">
                    <CheckCircle2 className="h-6 w-6 text-[#0EA5E9] flex-shrink-0 mt-1" />
                    <span>Built exactly how you want it</span>
                  </li>
                  <li className="flex items-start gap-3 text-gray-300">
                    <CheckCircle2 className="h-6 w-6 text-[#0EA5E9] flex-shrink-0 mt-1" />
                    <span>Worry-free maintenance and support</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="py-24 bg-white/5">
          <div className="container mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold text-white mb-6">Smart Investment in Your Online Presence</h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                Understand the long-term benefits of choosing our custom development approach.
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10">
                <DollarSign className="h-12 w-12 text-[#0EA5E9] mb-6" />
                <h3 className="text-2xl font-semibold text-white mb-4">Lower Total Cost</h3>
                <p className="text-gray-300">
                  No monthly plugin fees or expensive maintenance contracts. Your website is built 
                  right from the start, saving you money in the long run.
                </p>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10">
                <Shield className="h-12 w-12 text-[#0EA5E9] mb-6" />
                <h3 className="text-2xl font-semibold text-white mb-4">Built-in Security</h3>
                <p className="text-gray-300">
                  Don't worry about plugin vulnerabilities or security updates. Our websites are 
                  secure by design, protecting your business and customers.
                </p>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10">
                <Rocket className="h-12 w-12 text-[#0EA5E9] mb-6" />
                <h3 className="text-2xl font-semibold text-white mb-4">Future-Proof</h3>
                <p className="text-gray-300">
                  Your website grows with your business. Add new features when you need them, 
                  without rebuilding from scratch.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="py-24">
          <div className="container mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold text-white mb-6">Enterprise-Grade Hosting</h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                Your website deserves the best hosting infrastructure. We partner with industry leaders 
                to provide unmatched security, speed, and reliability.
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <Server className="h-12 w-12 text-[#0EA5E9] mb-6" />
                <h3 className="text-2xl font-semibold text-white mb-4">World-Class Infrastructure</h3>
                <p className="text-gray-300">
                  We host exclusively on enterprise platforms trusted by Fortune 500 companies, 
                  ensuring your website stays fast and secure 24/7.
                </p>
                <ul className="space-y-3 mt-4">
                  <li className="flex items-center gap-2 text-gray-300">
                    <CheckCircle2 className="h-5 w-5 text-[#0EA5E9]" />
                    <span>99.99% Uptime Guarantee</span>
                  </li>
                  <li className="flex items-center gap-2 text-gray-300">
                    <CheckCircle2 className="h-5 w-5 text-[#0EA5E9]" />
                    <span>Global Content Delivery Network</span>
                  </li>
                </ul>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <Shield className="h-12 w-12 text-[#0EA5E9] mb-6" />
                <h3 className="text-2xl font-semibold text-white mb-4">Advanced Security</h3>
                <p className="text-gray-300">
                  Enterprise-level security measures protect your website from threats, 
                  keeping your business and customers safe.
                </p>
                <ul className="space-y-3 mt-4">
                  <li className="flex items-center gap-2 text-gray-300">
                    <CheckCircle2 className="h-5 w-5 text-[#0EA5E9]" />
                    <span>DDoS Protection</span>
                  </li>
                  <li className="flex items-center gap-2 text-gray-300">
                    <CheckCircle2 className="h-5 w-5 text-[#0EA5E9]" />
                    <span>24/7 Security Monitoring</span>
                  </li>
                </ul>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <Rocket className="h-12 w-12 text-[#0EA5E9] mb-6" />
                <h3 className="text-2xl font-semibold text-white mb-4">Lightning Performance</h3>
                <p className="text-gray-300">
                  Our hosting infrastructure ensures your website loads instantly anywhere 
                  in the world, improving user experience and SEO.
                </p>
                <ul className="space-y-3 mt-4">
                  <li className="flex items-center gap-2 text-gray-300">
                    <CheckCircle2 className="h-5 w-5 text-[#0EA5E9]" />
                    <span>Automatic Scaling</span>
                  </li>
                  <li className="flex items-center gap-2 text-gray-300">
                    <CheckCircle2 className="h-5 w-5 text-[#0EA5E9]" />
                    <span>Global Edge Network</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="py-24 bg-white/5">
          <div className="container mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold text-white mb-6">Our Technology Stack</h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                We use cutting-edge technologies and follow industry best practices to deliver 
                secure, scalable, and high-performance web applications.
              </p>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <Layout className="h-8 w-8 text-[#0EA5E9] mb-6" />
                <h3 className="text-2xl font-semibold text-white mb-4">Frontend</h3>
                <ul className="space-y-2 text-gray-300">
                  <li>React & Next.js</li>
                  <li>TypeScript</li>
                  <li>Tailwind CSS</li>
                </ul>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <Server className="h-8 w-8 text-[#0EA5E9] mb-6" />
                <h3 className="text-2xl font-semibold text-white mb-4">Backend</h3>
                <ul className="space-y-2 text-gray-300">
                  <li>Node.js</li>
                  <li>PostgreSQL</li>
                  <li>GraphQL</li>
                </ul>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <Shield className="h-8 w-8 text-[#0EA5E9] mb-6" />
                <h3 className="text-2xl font-semibold text-white mb-4">Security</h3>
                <ul className="space-y-2 text-gray-300">
                  <li>JWT Authentication</li>
                  <li>OAuth 2.0</li>
                  <li>HTTPS/SSL</li>
                </ul>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <Layers className="h-8 w-8 text-[#0EA5E9] mb-6" />
                <h3 className="text-2xl font-semibold text-white mb-4">Infrastructure</h3>
                <ul className="space-y-2 text-gray-300">
                  <li>AWS/Azure Cloud</li>
                  <li>Docker</li>
                  <li>CI/CD Pipeline</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="py-24">
          <div className="container mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold text-white mb-6">Our Development Process</h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                We follow a structured approach to ensure your project is delivered on time and within budget.
              </p>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              <div className="p-6 rounded-xl bg-white/5 border border-white/10">
                <Shield className="h-8 w-8 text-[#0EA5E9] mb-4" />
                <h3 className="text-xl font-semibold text-white mb-2">Requirements Analysis</h3>
                <ul className="space-y-2 text-gray-300">
                  <li>Deep dive into business needs and security requirements</li>
                </ul>
              </div>

              <div className="p-6 rounded-xl bg-white/5 border border-white/10">
                <Server className="h-8 w-8 text-[#0EA5E9] mb-4" />
                <h3 className="text-xl font-semibold text-white mb-2">Custom Architecture Design</h3>
                <ul className="space-y-2 text-gray-300">
                  <li>Design scalable architecture tailored to project needs</li>
                </ul>
              </div>

              <div className="p-6 rounded-xl bg-white/5 border border-white/10">
                <FileCode className="h-8 w-8 text-[#0EA5E9] mb-4" />
                <h3 className="text-xl font-semibold text-white mb-2">Development & Testing</h3>
                <ul className="space-y-2 text-gray-300">
                  <li>Write clean, maintainable code with comprehensive testing</li>
                </ul>
              </div>

              <div className="p-6 rounded-xl bg-white/5 border border-white/10">
                <Lock className="h-8 w-8 text-[#0EA5E9] mb-4" />
                <h3 className="text-xl font-semibold text-white mb-2">Deployment & Monitoring</h3>
                <ul className="space-y-2 text-gray-300">
                  <li>Secure deployment with continuous monitoring and support</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="py-24 relative overflow-hidden border-y border-white/5">
          <div className="absolute inset-0 bg-gradient-to-r from-[#0EA5E9]/10 via-[#0EA5E9]/5 to-transparent">
            <div className="absolute inset-0 opacity-30">
              <div className="absolute h-full w-1/6 bg-gradient-to-b from-[#0EA5E9]/20 via-[#0EA5E9]/10 to-transparent animate-slide-down" style={{ left: '10%', animationDelay: '0s', animationDuration: '3s' }} />
              <div className="absolute h-full w-1/6 bg-gradient-to-b from-[#0EA5E9]/20 via-[#0EA5E9]/10 to-transparent animate-slide-down" style={{ left: '70%', animationDelay: '1.5s', animationDuration: '4.5s' }} />
            </div>
          </div>
          <div className="container mx-auto px-4 relative z-10">
            <div className="max-w-4xl mx-auto bg-white/5 backdrop-blur-sm rounded-2xl p-12 border border-white/10">
              <h2 className="text-3xl font-bold text-white mb-8 text-center">Ready to Build Your Next Project?</h2>
              <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
                Let's turn your vision into reality with our expert web development team.
              </p>
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <Link to="/contact">
                  <Button size="lg" className="w-full sm:w-auto text-lg py-7 px-8 bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] hover:opacity-90 text-white font-semibold shadow-[0_0_20px_rgba(14,165,233,0.3)] hover:shadow-[0_0_30px_rgba(14,165,233,0.5)] transform hover:scale-105 transition-all duration-300">
                    <span className="bg-gradient-to-r from-white via-white to-white/90 bg-clip-text text-transparent drop-shadow-[0_2px_2px_rgba(0,0,0,0.4)]">Start Your Custom Project</span>
                    <ArrowRight className="ml-2 h-5 w-5 text-white" />
                  </Button>
                </Link>
                <Link to="/schedule">
                  <Button size="lg" variant="outline" className="w-full sm:w-auto text-lg py-7 px-8 bg-white/5 backdrop-blur-sm border-2 border-white/10 hover:bg-white/10 hover:border-[#0EA5E9]/50 transform hover:scale-105 transition-all duration-300">
                    <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent">Schedule Call</span>
                    <ArrowRight className="ml-2 h-5 w-5 text-[#0EA5E9]" />
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </main>
      <FooterComponent />
    </div>
  );
};

export default WebDevelopment;
