import Header from "../components/Header";
import FooterComponent from "../components/footer-component";
import { Button } from "../components/ui/button";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Rocket, ArrowRight, Code, Cloud, Server, Lock, Database, Network, Globe, Users } from "lucide-react";
import ServiceAreas from "@/components/ServiceAreas";

const SaasDevelopment = () => {
  return (
    <div className="min-h-screen bg-black flex flex-col">
      <Helmet>
        <title>Custom SaaS Development Vancouver | Cloud Software Solutions</title>
        <meta name="description" content="Transform your business idea into a scalable SaaS product. Our Vancouver team specializes in building secure, cloud-native software solutions with modern technologies." />
        <meta property="og:title" content="Custom SaaS Development Vancouver | Cloud Software Solutions" />
        <meta property="og:description" content="Launch your SaaS product with our expert development team. We build scalable, secure cloud applications that grow with your business." />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Custom SaaS Development Vancouver | Cloud Software Solutions" />
        <meta name="twitter:description" content="Launch your SaaS product with our expert development team. We build scalable, secure cloud applications that grow with your business." />
      </Helmet>
      <Header />
      <main className="flex-grow pt-20">
        {/* Hero Section */}
        <section className="relative text-white py-24 px-4 overflow-hidden border-b border-white/5">
          <div className="absolute inset-0">
            <div 
              className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1451187580459-43490279c0fa?q=80')] 
              bg-cover bg-center bg-no-repeat"
            />
            <div className="absolute inset-0 bg-gradient-to-b from-black/80 via-black/70 to-black" />
            <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]" />
          </div>

          <div className="absolute inset-0">
            <div className="absolute -left-20 top-20 h-[400px] w-[400px] rounded-full bg-[#8B5CF6]/30 blur-[100px] animate-pulse" />
            <div className="absolute -right-20 bottom-20 h-[400px] w-[400px] rounded-full bg-[#0EA5E9]/30 blur-[100px] animate-pulse" />
          </div>

          <div className="container mx-auto relative z-10">
            <div className="max-w-4xl mx-auto text-center">
              <div className="inline-block p-4 bg-white/5 backdrop-blur-sm rounded-2xl mb-6 border border-white/10">
                <Cloud className="h-8 w-8 text-[#0EA5E9]" />
              </div>
              <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-6">
                Build Your
                <br />
                <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent">
                  SaaS Product
                </span>
              </h1>
              <p className="text-xl text-gray-300 mb-10 leading-relaxed max-w-3xl mx-auto">
                Transform your vision into a scalable, cloud-native SaaS solution with our expert development services.
              </p>
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <Link to="/contact">
                  <Button size="lg" className="w-full sm:w-auto text-lg py-7 px-8 bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] hover:opacity-90 text-white font-semibold shadow-[0_0_20px_rgba(14,165,233,0.3)] hover:shadow-[0_0_30px_rgba(14,165,233,0.5)] transform hover:scale-105 transition-all duration-300">
                    <span className="bg-gradient-to-r from-white via-white to-white/90 bg-clip-text text-transparent drop-shadow-[0_2px_2px_rgba(0,0,0,0.4)]">Start Your Project</span>
                    <ArrowRight className="ml-2 h-5 w-5 text-white" />
                  </Button>
                </Link>
                <Link to="/case-studies">
                  <Button size="lg" variant="outline" className="w-full sm:w-auto text-lg py-7 px-8 bg-white/5 backdrop-blur-sm border-2 border-white/10 hover:bg-white/10 hover:border-[#0EA5E9]/50 transform hover:scale-105 transition-all duration-300">
                    <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent">View Case Studies</span>
                    <ArrowRight className="ml-2 h-5 w-5 text-[#0EA5E9]" />
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/* Services Grid */}
        <section className="py-24">
          <div className="container mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold text-white mb-6">Complete SaaS Development Services</h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                End-to-end solutions for building and scaling your SaaS product.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-5xl mx-auto">
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <Cloud className="h-10 w-10 text-[#0EA5E9] mb-6" />
                <h3 className="text-2xl font-semibold text-white mb-4">Cloud Architecture</h3>
                <p className="text-gray-300">
                  Scalable cloud infrastructure designed for optimal performance and reliability.
                </p>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <Database className="h-10 w-10 text-[#0EA5E9] mb-6" />
                <h3 className="text-2xl font-semibold text-white mb-4">Database Design</h3>
                <p className="text-gray-300">
                  Efficient data structures and schemas optimized for multi-tenant architecture.
                </p>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <Lock className="h-10 w-10 text-[#0EA5E9] mb-6" />
                <h3 className="text-2xl font-semibold text-white mb-4">Security & Compliance</h3>
                <p className="text-gray-300">
                  Enterprise-grade security with built-in compliance measures.
                </p>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <Users className="h-10 w-10 text-[#0EA5E9] mb-6" />
                <h3 className="text-2xl font-semibold text-white mb-4">Multi-tenancy</h3>
                <p className="text-gray-300">
                  Robust multi-tenant architecture for serving multiple clients efficiently.
                </p>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <Globe className="h-10 w-10 text-[#0EA5E9] mb-6" />
                <h3 className="text-2xl font-semibold text-white mb-4">API Development</h3>
                <p className="text-gray-300">
                  RESTful and GraphQL APIs built for seamless integration and scalability.
                </p>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <Server className="h-10 w-10 text-[#0EA5E9] mb-6" />
                <h3 className="text-2xl font-semibold text-white mb-4">DevOps & CI/CD</h3>
                <p className="text-gray-300">
                  Automated deployment pipelines and infrastructure as code.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section className="py-24 bg-white/5">
          <div className="container mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold text-white mb-6">Why Choose Our SaaS Development</h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                We build scalable, secure, and maintainable SaaS solutions.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-5xl mx-auto">
              <div className="p-8 rounded-xl bg-white/5 border border-white/10">
                <Code className="h-8 w-8 text-[#0EA5E9] mb-4" />
                <h3 className="text-xl font-semibold text-white mb-4">Modern Tech Stack</h3>
                <ul className="space-y-2 text-gray-300">
                  <li>React & Next.js</li>
                  <li>Node.js & Python</li>
                  <li>PostgreSQL & MongoDB</li>
                </ul>
              </div>

              <div className="p-8 rounded-xl bg-white/5 border border-white/10">
                <Cloud className="h-8 w-8 text-[#0EA5E9] mb-4" />
                <h3 className="text-xl font-semibold text-white mb-4">Cloud Native</h3>
                <ul className="space-y-2 text-gray-300">
                  <li>AWS & Azure</li>
                  <li>Kubernetes</li>
                  <li>Microservices</li>
                </ul>
              </div>

              <div className="p-8 rounded-xl bg-white/5 border border-white/10">
                <Lock className="h-8 w-8 text-[#0EA5E9] mb-4" />
                <h3 className="text-xl font-semibold text-white mb-4">Security First</h3>
                <ul className="space-y-2 text-gray-300">
                  <li>End-to-end Encryption</li>
                  <li>OAuth & JWT</li>
                  <li>GDPR Compliance</li>
                </ul>
              </div>

              <div className="p-8 rounded-xl bg-white/5 border border-white/10">
                <Network className="h-8 w-8 text-[#0EA5E9] mb-4" />
                <h3 className="text-xl font-semibold text-white mb-4">Scalable Infrastructure</h3>
                <ul className="space-y-2 text-gray-300">
                  <li>Auto-scaling</li>
                  <li>Load Balancing</li>
                  <li>Global CDN</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="py-24 relative overflow-hidden border-y border-white/5">
          <div className="absolute inset-0 bg-gradient-to-r from-[#0EA5E9]/10 via-[#0EA5E9]/5 to-transparent">
            <div className="absolute inset-0 opacity-30">
              <div className="absolute h-full w-1/6 bg-gradient-to-b from-[#0EA5E9]/20 via-[#0EA5E9]/10 to-transparent animate-slide-down" style={{ left: '10%', animationDelay: '0s', animationDuration: '3s' }} />
              <div className="absolute h-full w-1/6 bg-gradient-to-b from-[#0EA5E9]/20 via-[#0EA5E9]/10 to-transparent animate-slide-down" style={{ left: '70%', animationDelay: '1.5s', animationDuration: '4.5s' }} />
            </div>
          </div>
          <div className="container mx-auto px-4 relative z-10">
            <div className="max-w-4xl mx-auto bg-white/5 backdrop-blur-sm rounded-2xl p-12 border border-white/10">
              <div className="text-center">
                <h2 className="text-4xl font-bold text-white mb-6">Ready to Launch Your SaaS?</h2>
                <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
                  Transform your SaaS idea into a scalable, market-ready solution.
                </p>
                <div className="flex flex-col sm:flex-row gap-4 justify-center">
                  <Link to="/contact">
                    <Button size="lg" className="w-full sm:w-auto text-lg py-7 px-8 bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] hover:opacity-90 text-white font-semibold shadow-[0_0_20px_rgba(14,165,233,0.3)] hover:shadow-[0_0_30px_rgba(14,165,233,0.5)] transform hover:scale-105 transition-all duration-300">
                      <span className="bg-gradient-to-r from-white via-white to-white/90 bg-clip-text text-transparent drop-shadow-[0_2px_2px_rgba(0,0,0,0.4)]">Get Started</span>
                      <ArrowRight className="ml-2 h-5 w-5 text-white" />
                    </Button>
                  </Link>
                  <Button size="lg" variant="outline" className="w-full sm:w-auto text-lg py-7 px-8 bg-white/5 backdrop-blur-sm border-2 border-white/10 hover:bg-white/10 hover:border-[#0EA5E9]/50 transform hover:scale-105 transition-all duration-300">
                    <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent">Schedule Call</span>
                    <ArrowRight className="ml-2 h-5 w-5 text-[#0EA5E9]" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <FooterComponent />
    </div>
  );
};

export default SaasDevelopment;
