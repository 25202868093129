
import { Button } from "./ui/button";
import { Github } from "lucide-react";
import HeroAlternative from "./HeroAlternative";

const HeroSection = () => {
  return (
    <div className="min-h-screen bg-black flex flex-col relative overflow-hidden">
      <div className="absolute inset-0 bg-black/70 z-0">
        {/* Background Image - Optimized loading */}
        <div className="absolute inset-0">
          <div 
            className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1526374965328-7f61d4dc18c5?q=80&w=1200&auto=format&quality=75')] 
            bg-cover bg-center bg-no-repeat md:bg-contain md:bg-center"
            style={{
              width: '100%',
              height: '100%'
            }}
          >
            <div className="absolute inset-0 bg-gradient-to-b from-black/80 via-black/70 to-black" />
          </div>
        </div>

        {/* Primary gradient orbs - Hardware accelerated */}
        <div className="absolute top-0 -left-4 w-[500px] h-[500px] bg-[#D946EF] rounded-full mix-blend-multiply opacity-30 will-change-transform" style={{ filter: 'blur(128px)' }} />
        <div className="absolute top-0 -right-4 w-[500px] h-[500px] bg-[#8B5CF6] rounded-full mix-blend-multiply opacity-30 will-change-transform" style={{ filter: 'blur(128px)' }} />
        <div className="absolute -bottom-8 left-20 w-[500px] h-[500px] bg-[#0EA5E9] rounded-full mix-blend-multiply opacity-30 will-change-transform" style={{ filter: 'blur(128px)' }} />
        
        {/* Secondary subtle orbs - Hardware accelerated */}
        <div className="absolute top-1/2 left-1/3 w-[400px] h-[400px] bg-[#F97316] rounded-full mix-blend-multiply opacity-20 will-change-transform" style={{ filter: 'blur(96px)' }} />
        <div className="absolute bottom-1/4 right-1/4 w-[300px] h-[300px] bg-[#E5DEFF] rounded-full mix-blend-screen opacity-30 will-change-transform" style={{ filter: 'blur(96px)' }} />

        {/* Enhanced grid overlay - Pre-rendered pattern */}
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:32px_32px]" />
        
        {/* Gradient overlay - Simplified layers */}
        <div className="absolute inset-0 bg-gradient-to-b from-black/10 via-black/50 to-black/80" />
      </div>

      <div className="relative z-10">
        <HeroAlternative
          badge={{
            text: "Vancouver's Premier Digital Agency",
            action: {
              text: "View Our Work",
              href: "/case-studies",
            },
          }}
          title="Custom Software & Digital Marketing Solutions"
          description="We build powerful custom software solutions and deliver strategic digital marketing campaigns to help your business thrive in the modern digital landscape."
          actions={[
            {
              text: "Get Started",
              href: "/contact",
              variant: "default",
            },
            {
              text: "View Case Studies",
              href: "/case-studies",
              variant: "outline",
              icon: <Github className="h-5 w-5" />,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default HeroSection;
