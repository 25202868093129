import Header from "@/components/Header";
import FooterComponent from "@/components/footer-component";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import {
  ArrowRight,
  Search,
  MapPin,
  Globe,
  Building2,
  ShoppingCart,
  Laptop,
  Users,
  Brain,
  BarChart,
  FileCheck,
  Shield,
  Award,
  Star,
  TrendingUp
} from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Helmet } from "react-helmet";
import ServiceAreas from "@/components/ServiceAreas";

const SEOServicesPage = () => {
  const industrySpecificSEO = [
    {
      title: "E-commerce SEO",
      description: "Optimize product pages, categories, and checkout flows to increase online sales",
      icon: <ShoppingCart className="h-8 w-8 text-[#0EA5E9]" />,
      features: [
        "Product page optimization",
        "Schema markup implementation",
        "Shopping feed optimization",
        "Conversion rate optimization"
      ]
    },
    {
      title: "Local Business SEO",
      description: "Dominate local search results and attract more customers in Vancouver",
      icon: <MapPin className="h-8 w-8 text-[#0EA5E9]" />,
      features: [
        "Google Business Profile optimization",
        "Local citation building",
        "Location page optimization",
        "Local link building"
      ]
    },
    {
      title: "SaaS SEO",
      description: "Generate qualified leads and reduce customer acquisition costs",
      icon: <Laptop className="h-8 w-8 text-[#0EA5E9]" />,
      features: [
        "Feature page optimization",
        "Integration documentation SEO",
        "Competitor gap analysis",
        "User intent optimization"
      ]
    },
    {
      title: "Enterprise SEO",
      description: "Scale your SEO efforts across large, complex websites",
      icon: <Building2 className="h-8 w-8 text-[#0EA5E9]" />,
      features: [
        "International SEO",
        "Site architecture optimization",
        "Enterprise-level reporting",
        "Scalable SEO strategies"
      ]
    }
  ];

  const modernSEOFactors = [
    {
      title: "Experience",
      description: "Create content that demonstrates first-hand expertise and authenticity",
      icon: <Brain className="h-8 w-8 text-[#0EA5E9]" />
    },
    {
      title: "Expertise",
      description: "Showcase deep subject matter knowledge and industry authority",
      icon: <Award className="h-8 w-8 text-[#0EA5E9]" />
    },
    {
      title: "Authoritativeness",
      description: "Build recognition and trust within your industry",
      icon: <Star className="h-8 w-8 text-[#0EA5E9]" />
    },
    {
      title: "Trust",
      description: "Demonstrate reliability and transparency in all content",
      icon: <Shield className="h-8 w-8 text-[#0EA5E9]" />
    }
  ];

  const legacyFactors = [
    {
      title: "25+ Years",
      description: "Navigating search engine algorithms since 1997",
      icon: <Globe className="h-8 w-8 text-[#0EA5E9]" />,
      stats: "Successfully adapted to 50+ major algorithm updates"
    },
    {
      title: "Data-Driven",
      description: "Enterprise-grade SEO tools and methodologies",
      icon: <BarChart className="h-8 w-8 text-[#0EA5E9]" />,
      stats: "$10M+ in tracked ROI for clients"
    },
    {
      title: "Technical Mastery",
      description: "Deep technical expertise beyond basic optimization",
      icon: <Brain className="h-8 w-8 text-[#0EA5E9]" />,
      stats: "100+ technical SEO audits annually"
    },
    {
      title: "Proven Results",
      description: "Track record of sustainable ranking improvements",
      icon: <TrendingUp className="h-8 w-8 text-[#0EA5E9]" />,
      stats: "Average 3x organic traffic increase"
    }
  ];

  const services = [
    {
      title: "Technical SEO",
      description: "Optimize your website's technical foundation for better rankings",
      points: [
        "Site architecture optimization",
        "Speed optimization",
        "Technical implementations aligned with content strategy for maximum impact",
        <>
          Core Web Vitals and <Link to="/digital-marketing/technical-seo" className="text-[#0EA5E9] hover:underline">Google compliance</Link> optimization
        </>
      ]
    },
    {
      title: "On-Page SEO",
      description: "Optimize your content and metadata for search engines",
      points: [
        "Keyword research and optimization",
        <>
          Integration with <Link to="/digital-marketing/content-strategy" className="text-[#0EA5E9] hover:underline">content strategy</Link> for consistent messaging
        </>,
        <>
          Complement organic efforts with <Link to="/digital-marketing/google-ads" className="text-[#0EA5E9] hover:underline">targeted paid campaigns</Link>
        </>,
        "Meta data and schema optimization"
      ]
    },
    {
      title: "Off-Page SEO",
      description: "Build authority and trust through external signals",
      points: [
        "High-quality link building",
        <>
          Leverage <Link to="/digital-marketing/social-media" className="text-[#0EA5E9] hover:underline">social media</Link> for brand visibility
        </>,
        "Brand mention monitoring",
        "Authority building campaigns"
      ]
    }
  ];

  const serviceAreas = [
    {
      region: "Vancouver & Downtown",
      cities: ["Vancouver", "Downtown Vancouver", "West End", "Yaletown", "Gastown", "Coal Harbour"]
    },
    {
      region: "North Shore",
      cities: ["North Vancouver", "West Vancouver", "Deep Cove", "Lynn Valley", "Lower Lonsdale"]
    },
    {
      region: "Burnaby & New West",
      cities: ["Burnaby", "New Westminster", "Metrotown", "Brentwood", "Edmonds"]
    },
    {
      region: "Tri-Cities",
      cities: ["Coquitlam", "Port Coquitlam", "Port Moody", "Anmore", "Belcarra"]
    },
    {
      region: "Richmond & South",
      cities: ["Richmond", "Delta", "Tsawwassen", "Ladner", "Steveston"]
    },
    {
      region: "Surrey & Fraser Valley",
      cities: ["Surrey", "White Rock", "Langley", "Maple Ridge", "Pitt Meadows"]
    }
  ];

  return (
    <div className="min-h-screen bg-black flex flex-col">
      <Helmet>
        <title>Vancouver SEO Services | Search Engine Optimization Expert</title>
        <meta name="description" content="Unlock top search rankings with Vancouver's SEO specialists. Technical optimization, content strategy, and link building for sustainable organic growth." />
        <meta property="og:title" content="Vancouver SEO Services | Search Engine Optimization Expert" />
        <meta property="og:description" content="Achieve page one rankings with proven SEO strategies. Vancouver's experts in technical SEO, content optimization, and authority building." />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Vancouver SEO Services | Search Engine Optimization Expert" />
        <meta name="twitter:description" content="Achieve page one rankings with proven SEO strategies. Vancouver's experts in technical SEO, content optimization, and authority building." />
      </Helmet>
      <Header />
      <main className="flex-grow pt-20">
        {/* Hero Section */}
        <section className="relative text-white py-24 px-4 overflow-hidden border-b border-white/5">
          <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1460925895917-afdab827c52f?q=80')] bg-cover bg-center bg-fixed">
            <div className="absolute inset-0 bg-gradient-to-b from-black/80 via-black/70 to-black" />
          </div>

          <div className="absolute inset-0">
            <div className="absolute -left-20 top-20 h-[400px] w-[400px] rounded-full bg-[#8B5CF6]/30 blur-[100px] animate-pulse" />
            <div className="absolute -right-20 bottom-20 h-[400px] w-[400px] rounded-full bg-[#0EA5E9]/30 blur-[100px] animate-pulse" />
            <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]" />
          </div>

          <div className="container mx-auto relative z-10">
            <div className="max-w-4xl mx-auto text-center">
              <div className="inline-block p-4 bg-white/5 backdrop-blur-sm rounded-2xl mb-6 border border-white/10">
                <Search className="h-8 w-8 text-[#0EA5E9]" />
              </div>
              <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-6">
                Vancouver SEO Services
                <br />
                <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent">
                  That Drive Real Results
                </span>
              </h1>
              <p className="text-xl text-gray-300 mb-10 leading-relaxed max-w-3xl mx-auto">
                Future-proof your website with our cutting-edge SEO strategies. We combine E-E-A-T principles, technical excellence, and local expertise to help you dominate search rankings in 2025 and beyond.
              </p>
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <Link to="/contact">
                  <Button size="lg" className="w-full sm:w-auto text-lg py-7 px-8 bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] hover:opacity-90 text-white font-semibold shadow-[0_0_20px_rgba(14,165,233,0.3)] hover:shadow-[0_0_30px_rgba(14,165,233,0.5)] transform hover:scale-105 transition-all duration-300">
                    <span className="bg-gradient-to-r from-white via-white to-white/90 bg-clip-text text-transparent drop-shadow-[0_2px_2px_rgba(0,0,0,0.4)]">Get Free SEO Audit</span>
                    <ArrowRight className="ml-2 h-5 w-5 text-white" />
                  </Button>
                </Link>
                <Link to="/schedule">
                  <Button size="lg" variant="outline" className="w-full sm:w-auto text-lg py-7 px-8 bg-white/5 backdrop-blur-sm border-2 border-white/10 hover:bg-white/10 hover:border-[#0EA5E9]/50 transform hover:scale-105 transition-all duration-300">
                    <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent">Schedule Strategy Call</span>
                    <ArrowRight className="ml-2 h-5 w-5 text-[#0EA5E9]" />
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/* Legacy Section */}
        <section className="py-24 relative border-b border-white/5">
          <div className="container mx-auto px-4">
            <div className="text-center mb-16">
              <span className="text-[#0EA5E9] font-semibold tracking-wider text-sm uppercase">Why Professional SEO Matters</span>
              <h2 className="text-4xl font-bold text-white mt-4 mb-6">
                Pioneers in Search Engine Optimization
                <br />
                <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent">
                  Since Search Engines Began
                </span>
              </h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                While many claim to "do SEO," true search engine optimization requires decades of experience,
                enterprise-grade tools, and deep technical expertise that goes far beyond basic content writing or web design.
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
              {legacyFactors.map((factor) => (
                <Card key={factor.title} className="bg-white/5 backdrop-blur-sm border-white/10 hover:bg-white/10 transition-all duration-300">
                  <CardHeader>
                    <div className="flex items-center gap-4">
                      {factor.icon}
                      <CardTitle className="text-white text-xl">{factor.title}</CardTitle>
                    </div>
                  </CardHeader>
                  <CardContent>
                    <p className="text-gray-300 mb-4">{factor.description}</p>
                    <p className="text-sm text-[#0EA5E9]">{factor.stats}</p>
                  </CardContent>
                </Card>
              ))}
            </div>

            <div className="bg-white/5 backdrop-blur-sm border border-white/10 rounded-2xl p-8 max-w-4xl mx-auto">
              <h3 className="text-2xl font-semibold text-white mb-4">Why Amateur SEO Can Be Costly</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="space-y-3">
                  <p className="text-gray-300">
                    <ArrowRight className="h-4 w-4 text-[#0EA5E9] inline mr-2" />
                    Content writers often lack technical SEO expertise
                  </p>
                  <p className="text-gray-300">
                    <ArrowRight className="h-4 w-4 text-[#0EA5E9] inline mr-2" />
                    Web designers may not understand search engine algorithms
                  </p>
                  <p className="text-gray-300">
                    <ArrowRight className="h-4 w-4 text-[#0EA5E9] inline mr-2" />
                    Missing enterprise-grade SEO tools and analytics
                  </p>
                </div>
                <div className="space-y-3">
                  <p className="text-gray-300">
                    <ArrowRight className="h-4 w-4 text-[#0EA5E9] inline mr-2" />
                    No experience handling algorithm updates
                  </p>
                  <p className="text-gray-300">
                    <ArrowRight className="h-4 w-4 text-[#0EA5E9] inline mr-2" />
                    Limited understanding of technical SEO factors
                  </p>
                  <p className="text-gray-300">
                    <ArrowRight className="h-4 w-4 text-[#0EA5E9] inline mr-2" />
                    Inability to diagnose complex SEO issues
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Modern SEO Section */}
        <section className="py-24 relative">
          <div className="container mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold text-white mb-6">Modern SEO for 2025</h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                Google's latest algorithms prioritize E-E-A-T (Experience, Expertise, Authoritativeness, and Trust). Our strategies are built to exceed these requirements.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {modernSEOFactors.map((factor) => (
                <Card key={factor.title} className="bg-white/5 backdrop-blur-sm border-white/10 hover:bg-white/10 transition-all duration-300">
                  <CardHeader>
                    <div className="flex items-center gap-4">
                      {factor.icon}
                      <CardTitle className="text-white text-xl">{factor.title}</CardTitle>
                    </div>
                  </CardHeader>
                  <CardContent>
                    <p className="text-gray-300">{factor.description}</p>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </section>

        {/* Industry-Specific SEO */}
        <section className="py-24 bg-white/5">
          <div className="container mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold text-white mb-6">Industry-Specific SEO Solutions</h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                Tailored SEO strategies for your specific industry and business model.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {industrySpecificSEO.map((industry) => (
                <Card key={industry.title} className="bg-white/5 backdrop-blur-sm border-white/10 hover:bg-white/10 transition-all duration-300">
                  <CardHeader>
                    <div className="flex items-center gap-4">
                      {industry.icon}
                      <div>
                        <CardTitle className="text-white text-xl">{industry.title}</CardTitle>
                        <p className="text-gray-400 mt-1">{industry.description}</p>
                      </div>
                    </div>
                  </CardHeader>
                  <CardContent>
                    <ul className="space-y-3">
                      {industry.features.map((feature) => (
                        <li key={feature} className="flex items-center gap-2 text-gray-300">
                          <ArrowRight className="h-4 w-4 text-[#0EA5E9]" />
                          <span>{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </section>

        {/* Our Services Section */}
        <section className="py-24">
          <div className="container mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold text-white mb-6">Our SEO Services</h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                Comprehensive SEO solutions tailored to your business needs.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {services.map((service) => (
                <Card key={service.title} className="bg-white/5 backdrop-blur-sm border-white/10 hover:bg-white/10 transition-all duration-300">
                  <CardHeader>
                    <CardTitle className="text-white text-xl">{service.title}</CardTitle>
                    <p className="text-gray-400">{service.description}</p>
                  </CardHeader>
                  <CardContent>
                    <ul className="space-y-3">
                      {service.points.map((point, index) => (
                        <li key={index} className="flex items-center gap-2 text-gray-300">
                          <ArrowRight className="h-4 w-4 text-[#0EA5E9] flex-shrink-0" />
                          <span>{point}</span>
                        </li>
                      ))}
                    </ul>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </section>

        {/* Service Areas Section */}
        <section className="py-24 bg-white/5">
          <div className="container mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold text-white mb-6">Serving Greater Vancouver</h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                We deliver comprehensive SEO services throughout Greater Vancouver and surrounding areas, helping local businesses improve their online visibility and organic rankings.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {serviceAreas.map((area) => (
                <Card key={area.region} className="bg-white/5 backdrop-blur-sm border-white/10 hover:bg-white/10 transition-all duration-300">
                  <CardHeader>
                    <div className="flex items-center gap-4">
                      <MapPin className="h-8 w-8 text-[#0EA5E9]" />
                      <CardTitle className="text-white text-xl">{area.region}</CardTitle>
                    </div>
                  </CardHeader>
                  <CardContent>
                    <ul className="space-y-2">
                      {area.cities.map((city) => (
                        <li key={city} className="text-gray-300">
                          {city}
                        </li>
                      ))}
                    </ul>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </section>

        {/* Why Choose Us */}
        <section className="py-24">
          <div className="container mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold text-white mb-6">Why Choose Our SEO Services?</h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                We combine technical expertise with local Vancouver market knowledge to deliver exceptional results.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="p-8 rounded-xl bg-white/5 backdrop-blur-sm border border-white/10 text-center">
                <TrendingUp className="h-12 w-12 text-[#0EA5E9] mx-auto mb-4" />
                <div className="text-4xl font-bold text-white mb-2">300%</div>
                <p className="text-gray-300">Average Traffic Increase</p>
              </div>
              <div className="p-8 rounded-xl bg-white/5 backdrop-blur-sm border border-white/10 text-center">
                <FileCheck className="h-12 w-12 text-[#0EA5E9] mx-auto mb-4" />
                <div className="text-4xl font-bold text-white mb-2">100+</div>
                <p className="text-gray-300">SEO Audits Completed</p>
              </div>
              <div className="p-8 rounded-xl bg-white/5 backdrop-blur-sm border border-white/10 text-center">
                <Users className="h-12 w-12 text-[#0EA5E9] mx-auto mb-4" />
                <div className="text-4xl font-bold text-white mb-2">50+</div>
                <p className="text-gray-300">Vancouver Businesses Ranked</p>
              </div>
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="py-24 relative overflow-hidden border-y border-white/5">
          <div className="absolute inset-0 bg-gradient-to-r from-[#0EA5E9]/10 via-[#0EA5E9]/5 to-transparent">
            <div className="absolute inset-0 opacity-30">
              <div className="absolute h-full w-1/6 bg-gradient-to-b from-[#0EA5E9]/20 via-[#0EA5E9]/10 to-transparent animate-slide-down" style={{ left: '10%', animationDelay: '0s', animationDuration: '3s' }} />
              <div className="absolute h-full w-1/6 bg-gradient-to-b from-[#0EA5E9]/20 via-[#0EA5E9]/10 to-transparent animate-slide-down" style={{ left: '70%', animationDelay: '1.5s', animationDuration: '4.5s' }} />
            </div>
          </div>
          <div className="container mx-auto px-4 relative z-10">
            <div className="max-w-4xl mx-auto bg-white/5 backdrop-blur-sm rounded-2xl p-12 border border-white/10">
              <div className="text-center">
                <h2 className="text-4xl font-bold text-white mb-6">Ready to Dominate Search Rankings?</h2>
                <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
                  Get a comprehensive SEO audit and discover untapped opportunities for your website's growth.
                </p>
                <div className="flex flex-col sm:flex-row gap-4 justify-center">
                  <Link to="/contact">
                    <Button size="lg" className="w-full sm:w-auto text-lg py-7 px-8 bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] hover:opacity-90 text-white font-semibold shadow-[0_0_20px_rgba(14,165,233,0.3)] hover:shadow-[0_0_30px_rgba(14,165,233,0.5)] transform hover:scale-105 transition-all duration-300">
                      <span className="bg-gradient-to-r from-white via-white to-white/90 bg-clip-text text-transparent drop-shadow-[0_2px_2px_rgba(0,0,0,0.4)]">Get Started</span>
                      <ArrowRight className="ml-2 h-5 w-5 text-white" />
                    </Button>
                  </Link>
                  <Link to="/schedule">
                    <Button size="lg" variant="outline" className="w-full sm:w-auto text-lg py-7 px-8 bg-white/5 backdrop-blur-sm border-2 border-white/10 hover:bg-white/10 hover:border-[#0EA5E9]/50 transform hover:scale-105 transition-all duration-300">
                      <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent">Schedule Call</span>
                      <ArrowRight className="ml-2 h-5 w-5 text-[#0EA5E9]" />
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <FooterComponent />
    </div>
  );
};

export default SEOServicesPage;
