
import { useState, useEffect } from "react";
import { Button } from "./ui/button";
import { X } from "lucide-react";

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accepted");
    setIsVisible(false);
  };

  const handleDecline = () => {
    localStorage.setItem("cookieConsent", "declined");
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-black/95 backdrop-blur-md border-t border-white/10 p-4 z-[100]">
      <div className="container mx-auto flex flex-col sm:flex-row items-center justify-between gap-4">
        <div className="flex-1">
          <p className="text-white/90 text-sm">
            We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking "Accept All", you consent to our use of cookies.{" "}
            <a href="/cookie-policy" className="text-[#0EA5E9] hover:underline font-medium">
              Cookie Policy
            </a>
          </p>
        </div>
        <div className="flex items-center gap-4">
          <button
            onClick={handleDecline}
            className="px-4 py-2 text-white font-medium bg-black/80 border border-white/20 hover:bg-white/10 rounded-md transition-colors"
          >
            Decline
          </button>
          <button
            onClick={handleAccept}
            className="px-4 py-2 bg-[#0EA5E9] hover:bg-[#0EA5E9]/90 text-white font-semibold rounded-md shadow-lg transition-colors"
          >
            Accept All
          </button>
          <button
            onClick={handleDecline}
            className="text-white hover:text-white"
            aria-label="Close cookie consent"
          >
            <X className="h-4 w-4" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
