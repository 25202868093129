import React from "react";
import { Helmet } from "react-helmet";
import AboutUs from "@/components/AboutUs";
import Header from "@/components/Header";
import FooterComponent from "@/components/footer-component";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { ArrowRight } from "lucide-react";

const AboutPage = () => {
  const teamMembers = [
    {
      name: "Gerald Leckie",
      role: "Chief Executive Officer",
      photo: "/lovable-uploads/9a5d131c-adba-4068-956f-24c2304c0d7d.png",
    }
  ];

  const description = "Founded in 2015, Ironball Marketing has been at the forefront of digital innovation, helping businesses transform their online presence. Our team of experts combines creativity with technical excellence to deliver cutting-edge solutions that drive real results. With a track record of successful projects across various industries, we've established ourselves as a trusted partner for businesses looking to thrive in the digital age.";

  return (
    <div className="flex min-h-screen flex-col bg-black">
      <Helmet>
        <title>About Ironball Marketing | Digital Innovation & Development</title>
        <meta name="description" content="Discover how Ironball Marketing combines technical excellence with innovative solutions. Our custom project management tools and expert team deliver exceptional digital experiences." />
        <meta property="og:title" content="About Ironball Marketing | Digital Innovation & Development" />
        <meta property="og:description" content="Learn about our journey, team, and commitment to delivering exceptional digital solutions through innovation and custom-built tools." />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Ironball Marketing | Digital Innovation & Development" />
        <meta name="twitter:description" content="Learn about our journey, team, and commitment to delivering exceptional digital solutions through innovation and custom-built tools." />
      </Helmet>
      <Header />
      <main className="flex-grow pt-20">
        <AboutUs teamMembers={teamMembers} description={description} />
        <section className="py-24 relative overflow-hidden border-y border-white/5">
          <div className="absolute inset-0 bg-gradient-to-r from-[#0EA5E9]/10 via-[#0EA5E9]/5 to-transparent">
            <div className="absolute inset-0 opacity-30">
              <div className="absolute h-full w-1/6 bg-gradient-to-b from-[#0EA5E9]/20 via-[#0EA5E9]/10 to-transparent animate-slide-down" style={{ left: '10%', animationDelay: '0s', animationDuration: '3s' }} />
              <div className="absolute h-full w-1/6 bg-gradient-to-b from-[#0EA5E9]/20 via-[#0EA5E9]/10 to-transparent animate-slide-down" style={{ left: '70%', animationDelay: '1.5s', animationDuration: '4.5s' }} />
            </div>
          </div>
          <div className="container mx-auto px-4 relative z-10">
            <div className="max-w-4xl mx-auto bg-white/5 backdrop-blur-sm rounded-2xl p-12 border border-white/10">
              <div className="text-center">
                <h2 className="text-4xl font-bold text-white mb-6">Transform Your Digital Presence</h2>
                <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
                  Partner with our expert team to elevate your business with innovative digital solutions and strategies.
                </p>
                <div className="flex flex-col sm:flex-row gap-4 justify-center">
                  <Link to="/contact">
                    <Button size="lg" className="w-full sm:w-auto text-lg py-7 px-8 bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] hover:opacity-90 text-white font-semibold shadow-[0_0_20px_rgba(14,165,233,0.3)] hover:shadow-[0_0_30px_rgba(14,165,233,0.5)] transform hover:scale-105 transition-all duration-300">
                      <span className="bg-gradient-to-r from-white via-white to-white/90 bg-clip-text text-transparent drop-shadow-[0_2px_2px_rgba(0,0,0,0.4)]">Get Started</span>
                      <ArrowRight className="ml-2 h-5 w-5 text-white" />
                    </Button>
                  </Link>
                  <Link to="/schedule">
                    <Button size="lg" variant="outline" className="w-full sm:w-auto text-lg py-7 px-8 bg-white/5 backdrop-blur-sm border-2 border-white/10 hover:bg-white/10 hover:border-[#0EA5E9]/50 transform hover:scale-105 transition-all duration-300">
                      <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent">Schedule Call</span>
                      <ArrowRight className="ml-2 h-5 w-5 text-[#0EA5E9]" />
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <FooterComponent />
    </div>
  );
};

export default AboutPage;
