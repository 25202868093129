
import { Button } from "./ui/button";
import { ArrowRight } from "lucide-react";
import { Link } from "react-router-dom";

const ProcessComponent = () => {
  return (
    <div className="min-h-screen bg-black">
      {/* Hero Section */}
      <section className="relative text-white py-24 px-4 overflow-hidden border-b border-white/5">
        <div className="absolute inset-0">
          <div 
            className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1451187580459-43490279c0fa?q=80&w=1920&auto=format')] 
            bg-cover bg-center bg-no-repeat"
            style={{ backgroundSize: 'cover' }}
          />
          <div className="absolute inset-0 bg-gradient-to-b from-black/80 via-black/70 to-black" />
        </div>

        <div className="absolute inset-0">
          <div className="absolute -left-20 top-20 h-[400px] w-[400px] rounded-full bg-[#8B5CF6]/30 blur-[100px] animate-pulse" />
          <div className="absolute -right-20 bottom-20 h-[400px] w-[400px] rounded-full bg-[#0EA5E9]/30 blur-[100px] animate-pulse" />
          <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]" />
        </div>

        <div className="container mx-auto relative z-10">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-6">
              Our Proven
              <br />
              <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent">
                Development Process
              </span>
            </h1>
            <p className="text-xl text-gray-300 mb-8">
              A systematic approach to delivering exceptional digital solutions that drive real business results
            </p>
            <Link to="/contact">
              <Button size="lg" className="w-full sm:w-auto text-lg py-7 px-8 bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] hover:opacity-90 text-white font-semibold shadow-[0_0_20px_rgba(14,165,233,0.3)] hover:shadow-[0_0_30px_rgba(14,165,233,0.5)] transform hover:scale-105 transition-all duration-300">
                <span className="bg-gradient-to-r from-white via-white to-white/90 bg-clip-text text-transparent drop-shadow-[0_2px_2px_rgba(0,0,0,0.4)]">Start Your Project</span>
                <ArrowRight className="ml-2 h-5 w-5 text-white" />
              </Button>
            </Link>
          </div>
        </div>
      </section>

      {/* Process Steps Section */}
      <section className="py-20 px-4">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Step 1 */}
            <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6 border border-white/10">
              <h3 className="text-xl font-semibold text-white mb-3">1. Discovery & Planning</h3>
              <p className="text-gray-400 mb-4">We start by understanding your business goals and requirements to create a detailed project plan.</p>
            </div>

            {/* Step 2 */}
            <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6 border border-white/10">
              <h3 className="text-xl font-semibold text-white mb-3">2. Design & Prototyping</h3>
              <p className="text-gray-400 mb-4">Our design team creates wireframes and prototypes to visualize the user experience and gather feedback.</p>
            </div>

            {/* Step 3 */}
            <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6 border border-white/10">
              <h3 className="text-xl font-semibold text-white mb-3">3. Development & Testing</h3>
              <p className="text-gray-400 mb-4">Our developers build the solution using the latest technologies, with rigorous testing at every stage.</p>
            </div>

            {/* Step 4 */}
            <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6 border border-white/10">
              <h3 className="text-xl font-semibold text-white mb-3">4. Deployment & Integration</h3>
              <p className="text-gray-400 mb-4">We deploy the solution to your environment and integrate it with your existing systems.</p>
            </div>

            {/* Step 5 */}
            <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6 border border-white/10">
              <h3 className="text-xl font-semibold text-white mb-3">5. Training & Documentation</h3>
              <p className="text-gray-400 mb-4">We provide comprehensive training and documentation to ensure your team can effectively use the new solution.</p>
            </div>

            {/* Step 6 */}
            <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6 border border-white/10">
              <h3 className="text-xl font-semibold text-white mb-3">6. Support & Maintenance</h3>
              <p className="text-gray-400 mb-4">We offer ongoing support and maintenance to keep your solution running smoothly and address any issues that arise.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProcessComponent;
