import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import FooterComponent from "../../components/footer-component";
import { MessageSquare, ArrowRight } from "lucide-react";
import { Button } from "../../components/ui/button";
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from "../../components/ui/card";
import { Link } from "react-router-dom";
import ServiceAreas from "@/components/ServiceAreas";

const EmailOutreachPage = () => {
  return (
    <div className="min-h-screen bg-black flex flex-col">
      <Helmet>
        <title>Email Outreach Services | Vancouver</title>
        <meta name="description" content="Vancouver's top email outreach services. Convert prospects into customers with personalized email sequences and A/B testing." />
        <meta property="og:title" content="Email Outreach Services | Vancouver" />
        <meta property="og:description" content="Transform your B2B sales outreach with personalized email sequences to engage prospects and close deals." />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Email Outreach Services | Vancouver" />
        <meta name="twitter:description" content="Transform your B2B sales outreach with personalized email sequences to engage prospects and close deals." />
      </Helmet>
      <Header />
      <main className="flex-grow pt-20">
        <section className="relative text-white py-24 px-4 overflow-hidden border-b border-white/5">
          <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1460925895917-afdab827c52f?q=80')] bg-cover bg-center bg-fixed">
            <div className="absolute inset-0 bg-gradient-to-b from-black/80 via-black/70 to-black" />
          </div>

          <div className="absolute inset-0">
            <div className="absolute -left-20 top-20 h-[400px] w-[400px] rounded-full bg-[#8B5CF6]/30 blur-[100px] animate-pulse" />
            <div className="absolute -right-20 bottom-20 h-[400px] w-[400px] rounded-full bg-[#0EA5E9]/30 blur-[100px] animate-pulse" />
            <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]" />
          </div>

          <div className="container mx-auto relative z-10">
            <div className="max-w-4xl mx-auto text-center">
              <div className="inline-block p-4 bg-white/5 backdrop-blur-sm rounded-2xl mb-6 border border-white/10">
                <MessageSquare className="h-8 w-8 text-[#0EA5E9]" />
              </div>
              <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-6">
                B2B Email
                <br />
                <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent">
                  Sales Outreach
                </span>
              </h1>
              <p className="text-xl text-gray-300 mb-10 leading-relaxed max-w-3xl mx-auto">
                Transform your B2B sales outreach with personalized email sequences to engage prospects and close deals.
              </p>
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <Link to="/contact">
                  <Button size="lg" className="w-full sm:w-auto text-lg py-7 px-8 bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] hover:opacity-90 text-white font-semibold shadow-[0_0_20px_rgba(14,165,233,0.3)] hover:shadow-[0_0_30px_rgba(14,165,233,0.5)] transform hover:scale-105 transition-all duration-300">
                    <span className="bg-gradient-to-r from-white via-white to-white/90 bg-clip-text text-transparent drop-shadow-[0_2px_2px_rgba(0,0,0,0.4)]">Get Started</span>
                    <ArrowRight className="ml-2 h-5 w-5 text-white" />
                  </Button>
                </Link>
                <Link to="/case-studies">
                  <Button size="lg" variant="outline" className="w-full sm:w-auto text-lg py-7 px-8 bg-white/5 backdrop-blur-sm border-2 border-white/10 hover:bg-white/10 hover:border-[#0EA5E9]/50 transform hover:scale-105 transition-all duration-300">
                    <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent">View Case Studies</span>
                    <ArrowRight className="ml-2 h-5 w-5 text-[#0EA5E9]" />
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="py-24 relative overflow-hidden border-b border-white/5">
          <div className="absolute inset-0 bg-gradient-to-r from-[#0EA5E9]/10 via-[#0EA5E9]/5 to-transparent">
            <div className="absolute inset-0 opacity-30">
              <div className="absolute h-full w-1/6 bg-gradient-to-b from-[#0EA5E9]/20 via-[#0EA5E9]/10 to-transparent animate-slide-down" style={{ left: '10%', animationDelay: '0s', animationDuration: '3s' }} />
              <div className="absolute h-full w-1/6 bg-gradient-to-b from-[#0EA5E9]/20 via-[#0EA5E9]/10 to-transparent animate-slide-down" style={{ left: '70%', animationDelay: '1.5s', animationDuration: '4.5s' }} />
            </div>
          </div>
          <div className="container mx-auto px-4 relative z-10">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold text-white mb-6">Our Services</h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                Comprehensive email outreach solutions tailored to your business needs.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <div className="transform hover:scale-105 transition-transform duration-300">
                <Card className="w-full h-[400px] bg-white/5 backdrop-blur-sm border-white/10 hover:bg-white/10 transition-all duration-300 flex flex-col">
                  <CardHeader className="flex-none">
                    <div className="flex items-center gap-4">
                      <MessageSquare className="h-8 w-8 text-[#0EA5E9]" />
                      <div>
                        <CardTitle className="text-white">Personalized Sequences</CardTitle>
                        <CardDescription className="text-gray-400">Craft tailored email sequences</CardDescription>
                      </div>
                    </div>
                  </CardHeader>
                  <CardContent className="flex-grow">
                    <p className="text-gray-300 mb-6">
                      Create personalized email sequences that resonate with your prospects.
                      We help you craft the perfect message to engage and convert.
                    </p>
                    <ul className="space-y-2">
                      <li className="flex items-center gap-2 text-gray-300">
                        <ArrowRight className="h-4 w-4 text-[#0EA5E9]" />
                        <span>Customized messaging</span>
                      </li>
                      <li className="flex items-center gap-2 text-gray-300">
                        <ArrowRight className="h-4 w-4 text-[#0EA5E9]" />
                        <span>Behavior-based triggers</span>
                      </li>
                      <li className="flex items-center gap-2 text-gray-300">
                        <ArrowRight className="h-4 w-4 text-[#0EA5E9]" />
                        <span>Multi-channel integration</span>
                      </li>
                    </ul>
                  </CardContent>
                  <CardFooter className="flex-none">
                    <Link to="/digital-marketing/email-outreach/personalized-sequences" className="w-full">
                      <Button className="w-full bg-[#0EA5E9]/20 hover:bg-[#0EA5E9]/30 text-white border border-[#0EA5E9]/50">
                        <span className="bg-gradient-to-r from-white via-white to-white/90 bg-clip-text text-transparent drop-shadow-[0_2px_2px_rgba(0,0,0,0.4)] font-semibold flex items-center gap-2">
                          Learn More
                          <ArrowRight className="h-4 w-4 text-white" />
                        </span>
                      </Button>
                    </Link>
                  </CardFooter>
                </Card>
              </div>

              <div className="transform hover:scale-105 transition-transform duration-300">
                <Card className="w-full h-[400px] bg-white/5 backdrop-blur-sm border-white/10 hover:bg-white/10 transition-all duration-300 flex flex-col">
                  <CardHeader className="flex-none">
                    <div className="flex items-center gap-4">
                      <MessageSquare className="h-8 w-8 text-[#0EA5E9]" />
                      <div>
                        <CardTitle className="text-white">A/B Testing</CardTitle>
                        <CardDescription className="text-gray-400">Optimize your email performance</CardDescription>
                      </div>
                    </div>
                  </CardHeader>
                  <CardContent className="flex-grow">
                    <p className="text-gray-300 mb-6">
                      Improve your email performance with A/B testing.
                      We help you test different elements to optimize your campaigns.
                    </p>
                    <ul className="space-y-2">
                      <li className="flex items-center gap-2 text-gray-300">
                        <ArrowRight className="h-4 w-4 text-[#0EA5E9]" />
                        <span>Subject line testing</span>
                      </li>
                      <li className="flex items-center gap-2 text-gray-300">
                        <ArrowRight className="h-4 w-4 text-[#0EA5E9]" />
                        <span>Content optimization</span>
                      </li>
                      <li className="flex items-center gap-2 text-gray-300">
                        <ArrowRight className="h-4 w-4 text-[#0EA5E9]" />
                        <span>Call-to-action variations</span>
                      </li>
                    </ul>
                  </CardContent>
                  <CardFooter className="flex-none">
                    <Link to="/digital-marketing/email-outreach/ab-testing" className="w-full">
                      <Button className="w-full bg-[#0EA5E9]/20 hover:bg-[#0EA5E9]/30 text-white border border-[#0EA5E9]/50">
                        <span className="bg-gradient-to-r from-white via-white to-white/90 bg-clip-text text-transparent drop-shadow-[0_2px_2px_rgba(0,0,0,0.4)] font-semibold flex items-center gap-2">
                          Start Testing
                          <ArrowRight className="h-4 w-4 text-white" />
                        </span>
                      </Button>
                    </Link>
                  </CardFooter>
                </Card>
              </div>

              <div className="transform hover:scale-105 transition-transform duration-300">
                <Card className="w-full h-[400px] bg-white/5 backdrop-blur-sm border-white/10 hover:bg-white/10 transition-all duration-300 flex flex-col">
                  <CardHeader className="flex-none">
                    <div className="flex items-center gap-4">
                      <MessageSquare className="h-8 w-8 text-[#0EA5E9]" />
                      <div>
                        <CardTitle className="text-white">Response Optimization</CardTitle>
                        <CardDescription className="text-gray-400">Maximize your response rates</CardDescription>
                      </div>
                    </div>
                  </CardHeader>
                  <CardContent className="flex-grow">
                    <p className="text-gray-300 mb-6">
                      Maximize your response rates with our response optimization strategies.
                      We help you fine-tune your approach to get more replies and conversions.
                    </p>
                    <ul className="space-y-2">
                      <li className="flex items-center gap-2 text-gray-300">
                        <ArrowRight className="h-4 w-4 text-[#0EA5E9]" />
                        <span>Follow-up automation</span>
                      </li>
                      <li className="flex items-center gap-2 text-gray-300">
                        <ArrowRight className="h-4 w-4 text-[#0EA5E9]" />
                        <span>Personalized replies</span>
                      </li>
                      <li className="flex items-center gap-2 text-gray-300">
                        <ArrowRight className="h-4 w-4 text-[#0EA5E9]" />
                        <span>Performance analysis</span>
                      </li>
                    </ul>
                  </CardContent>
                  <CardFooter className="flex-none">
                    <Link to="/digital-marketing/email-outreach/response-optimization" className="w-full">
                      <Button className="w-full bg-[#0EA5E9]/20 hover:bg-[#0EA5E9]/30 text-white border border-[#0EA5E9]/50">
                        <span className="bg-gradient-to-r from-white via-white to-white/90 bg-clip-text text-transparent drop-shadow-[0_2px_2px_rgba(0,0,0,0.4)] font-semibold flex items-center gap-2">
                          Optimize Now
                          <ArrowRight className="h-4 w-4 text-white" />
                        </span>
                      </Button>
                    </Link>
                  </CardFooter>
                </Card>
              </div>
            </div>
          </div>
        </section>
        <ServiceAreas />
      </main>
      <FooterComponent />
    </div>
  );
};

export default EmailOutreachPage;
