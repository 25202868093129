
import { ChartBar, ArrowRight } from "lucide-react";
import { Button } from "../ui/button";
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from "../ui/card";
import { Link } from "react-router-dom";

const SeoService = () => {
  return (
    <Card className="w-full h-[400px] bg-white/5 backdrop-blur-sm border-white/10 hover:bg-white/10 transition-all duration-300 flex flex-col">
      <CardHeader className="flex-none">
        <div className="flex items-center gap-4">
          <ChartBar className="h-8 w-8 text-[#0EA5E9]" />
          <div>
            <CardTitle className="text-white">SEO Services</CardTitle>
            <CardDescription className="text-gray-400">Improve your search engine rankings</CardDescription>
          </div>
        </div>
      </CardHeader>
      <CardContent className="flex-grow">
        <p className="text-gray-300 mb-6">
          Boost your online visibility with our comprehensive SEO services.
          We help you rank higher in search results and attract more qualified leads.
        </p>
        <ul className="space-y-2">
          <li className="flex items-center gap-2 text-gray-300">
            <ArrowRight className="h-4 w-4 text-[#0EA5E9]" />
            <span>Keyword research and optimization</span>
          </li>
          <li className="flex items-center gap-2 text-gray-300">
            <ArrowRight className="h-4 w-4 text-[#0EA5E9]" />
            <span>Content strategy development</span>
          </li>
          <li className="flex items-center gap-2 text-gray-300">
            <ArrowRight className="h-4 w-4 text-[#0EA5E9]" />
            <span>Technical SEO improvements</span>
          </li>
        </ul>
      </CardContent>
      <CardFooter className="flex-none">
        <Link to="/digital-marketing/seo-services" className="w-full">
          <Button className="w-full bg-[#0EA5E9]/20 hover:bg-[#0EA5E9]/30 text-white border border-[#0EA5E9]/50">
            <span className="bg-gradient-to-r from-white via-white to-white/90 bg-clip-text text-transparent drop-shadow-[0_2px_2px_rgba(0,0,0,0.4)] font-semibold flex items-center gap-2">
              Improve Rankings
              <ArrowRight className="h-4 w-4 text-white" />
            </span>
          </Button>
        </Link>
      </CardFooter>
    </Card>
  );
};

export default SeoService;
