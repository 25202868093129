
import { HelpCircle } from "lucide-react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

const FAQ = () => {
  const faqs = [
    {
      question: "What services does Ironball Marketing offer?",
      answer: "We offer a comprehensive suite of digital services including web development, digital marketing, software solutions, and SaaS development. Our expertise spans across custom website creation, SEO optimization, social media marketing, and enterprise-grade software development."
    },
    {
      question: "How long does it typically take to complete a project?",
      answer: "Project timelines vary depending on scope and complexity. A typical website development project can take 4-8 weeks, while more complex software solutions may take 3-6 months. We'll provide a detailed timeline during our initial consultation."
    },
    {
      question: "Do you offer ongoing support and maintenance?",
      answer: "Yes, we provide comprehensive support and maintenance packages for all our services. This includes regular updates, security patches, performance monitoring, and technical support to ensure your digital assets continue to perform optimally."
    },
    {
      question: "How do you handle project communication?",
      answer: "We maintain clear and consistent communication throughout the project lifecycle using project management tools and regular check-ins. You'll have a dedicated project manager and access to our team for updates and discussions."
    },
    {
      question: "What makes Ironball Marketing different from other agencies?",
      answer: "Our approach combines technical excellence with strategic thinking, but what truly sets us apart is our commitment to innovation through custom-built solutions. We've developed our own project management software specifically designed for handling digital projects, providing unprecedented transparency to our clients. This proprietary tool allows our clients to track progress in real-time, ensuring clear communication and accountability throughout the project lifecycle. We don't just build solutions for clients; we build tools to deliver better results and maintain complete transparency in our process."
    }
  ];

  return (
    <section className="py-24 px-4 relative overflow-hidden border-y border-white/5">
      <div className="absolute inset-0 bg-gradient-to-r from-[#0EA5E9]/10 via-[#0EA5E9]/5 to-transparent">
        <div className="absolute inset-0 opacity-30">
          <div className="absolute h-full w-1/6 bg-gradient-to-b from-[#0EA5E9]/20 via-[#0EA5E9]/10 to-transparent animate-slide-down" style={{ left: '10%', animationDelay: '0s', animationDuration: '3s' }} />
          <div className="absolute h-full w-1/6 bg-gradient-to-b from-[#0EA5E9]/20 via-[#0EA5E9]/10 to-transparent animate-slide-down" style={{ left: '70%', animationDelay: '1.5s', animationDuration: '4.5s' }} />
        </div>
      </div>
      <div className="container mx-auto relative z-10">
        <div className="text-center mb-12">
          <div className="flex items-center justify-center mb-4">
            <div className="p-3 bg-[#0EA5E9]/10 rounded-lg">
              <HelpCircle className="h-6 w-6 text-[#0EA5E9]" />
            </div>
          </div>
          <h2 className="text-4xl font-bold text-white mb-4">Frequently Asked Questions</h2>
          <p className="text-xl text-gray-300 max-w-2xl mx-auto">
            Find answers to common questions about our services and process
          </p>
        </div>
        
        <div className="max-w-3xl mx-auto">
          <Accordion type="single" collapsible className="w-full space-y-4">
            {faqs.map((faq, index) => (
              <AccordionItem
                key={index}
                value={`item-${index}`}
                className="bg-white/5 backdrop-blur-sm border border-white/10 rounded-lg px-6"
              >
                <AccordionTrigger className="text-white hover:text-[#0EA5E9] text-left">
                  {faq.question}
                </AccordionTrigger>
                <AccordionContent className="text-gray-300">
                  {faq.answer}
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
