
import { useQuery } from "@tanstack/react-query";
import { getOrganizationInfo } from "@/services/organization.service";
import { Building2 } from "lucide-react";

export const CompanyInfo = () => {
  const { data: orgInfo, isLoading } = useQuery({
    queryKey: ['organizationInfo'],
    queryFn: getOrganizationInfo,
  });

  if (isLoading) return <div>Loading...</div>;
  if (!orgInfo) return null;

  return (
    <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6 border border-white/10">
      <div className="flex items-center gap-3 mb-4">
        <Building2 className="h-6 w-6 text-[#0EA5E9]" />
        <h3 className="text-xl font-semibold text-white">{orgInfo.name}</h3>
      </div>
      <div className="space-y-2 text-gray-300">
        <p>{orgInfo.description}</p>
        <p className="text-sm">Established {new Date(orgInfo.founding_date).getFullYear()}</p>
      </div>
    </div>
  );
};
