import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import FooterComponent from "../../components/footer-component";
import ServiceAreas from "@/components/ServiceAreas";
import { 
  Globe, ArrowRight, ChartBar, Users, Target, MessageSquare, 
  BarChart, TrendingUp, PieChart, LineChart, ShoppingCart, 
  MapPin, Cloud, Building2
} from "lucide-react";
import { Button } from "../../components/ui/button";
import { Link } from "react-router-dom";

const SocialMediaPage = () => {
  return (
    <div className="min-h-screen bg-black flex flex-col">
      <Helmet>
        <title>Strategic Social Media Marketing Vancouver | Brand Growth Experts</title>
        <meta name="description" content="Elevate your brand with Vancouver's social media marketing specialists. From content strategy to paid campaigns, we drive engagement and measurable results." />
        <meta property="og:title" content="Strategic Social Media Marketing Vancouver | Brand Growth Experts" />
        <meta property="og:description" content="Power your brand's social presence with expert strategy and management. Vancouver's leading social media team delivering engagement and growth." />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Strategic Social Media Marketing Vancouver | Brand Growth Experts" />
        <meta name="twitter:description" content="Power your brand's social presence with expert strategy and management. Vancouver's leading social media team delivering engagement and growth." />
      </Helmet>
      <Header />
      <main className="flex-grow pt-20">
        {/* Hero Section */}
        <section className="relative text-white py-24 px-4 overflow-hidden border-b border-white/5">
          <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1460925895917-afdab827c52f?q=80')] bg-cover bg-center bg-fixed">
            <div className="absolute inset-0 bg-gradient-to-b from-black/80 via-black/70 to-black" />
          </div>

          <div className="absolute inset-0">
            <div className="absolute -left-20 top-20 h-[400px] w-[400px] rounded-full bg-[#8B5CF6]/30 blur-[100px] animate-pulse" />
            <div className="absolute -right-20 bottom-20 h-[400px] w-[400px] rounded-full bg-[#0EA5E9]/30 blur-[100px] animate-pulse" />
            <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]" />
          </div>

          <div className="container mx-auto relative z-10">
            <div className="max-w-4xl mx-auto text-center">
              <div className="inline-block p-4 bg-white/5 backdrop-blur-sm rounded-2xl mb-6 border border-white/10">
                <Users className="h-8 w-8 text-[#0EA5E9]" />
              </div>
              <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-6">
                Elevate Your Brand with
                <br />
                <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent">
                  Social Media Marketing
                </span>
              </h1>
              <p className="text-xl text-gray-300 mb-10 leading-relaxed max-w-3xl mx-auto">
                Connect with your audience, build brand loyalty, and drive business growth through strategic social media campaigns.
              </p>
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <Link to="/contact">
                  <Button size="lg" className="w-full sm:w-auto text-lg py-7 px-8 bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] hover:opacity-90 text-white font-semibold shadow-[0_0_20px_rgba(14,165,233,0.3)] hover:shadow-[0_0_30px_rgba(14,165,233,0.5)] transform hover:scale-105 transition-all duration-300">
                    <span className="bg-gradient-to-r from-white via-white to-white/90 bg-clip-text text-transparent drop-shadow-[0_2px_2px_rgba(0,0,0,0.4)]">Get Started</span>
                    <ArrowRight className="ml-2 h-5 w-5 text-white" />
                  </Button>
                </Link>
                <Link to="/case-studies">
                  <Button size="lg" variant="outline" className="w-full sm:w-auto text-lg py-7 px-8 bg-white/5 backdrop-blur-sm border-2 border-white/10 hover:bg-white/10 hover:border-[#0EA5E9]/50 transform hover:scale-105 transition-all duration-300">
                    <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent">View Case Studies</span>
                    <ArrowRight className="ml-2 h-5 w-5 text-[#0EA5E9]" />
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="py-24 relative overflow-hidden border-b border-white/5">
          <div className="absolute inset-0 bg-gradient-to-r from-[#0EA5E9]/10 via-[#0EA5E9]/5 to-transparent">
            <div className="absolute inset-0 opacity-30">
              <div className="absolute h-full w-1/6 bg-gradient-to-b from-[#0EA5E9]/20 via-[#0EA5E9]/10 to-transparent animate-slide-down" style={{ left: '10%', animationDelay: '0s', animationDuration: '3s' }} />
              <div className="absolute h-full w-1/6 bg-gradient-to-b from-[#0EA5E9]/20 via-[#0EA5E9]/10 to-transparent animate-slide-down" style={{ left: '70%', animationDelay: '1.5s', animationDuration: '4.5s' }} />
            </div>
          </div>
          <div className="container mx-auto px-4 relative z-10">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold text-white mb-6">Our Social Media Services</h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                Comprehensive social media solutions tailored to your business needs.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <Globe className="h-10 w-10 text-[#0EA5E9] mb-6" />
                <h3 className="text-2xl font-semibold text-white mb-4">Social Media Strategy</h3>
                <p className="text-gray-300">
                  Develop a winning social media strategy that aligns with your business goals and target audience.
                </p>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <Target className="h-10 w-10 text-[#0EA5E9] mb-6" />
                <h3 className="text-2xl font-semibold text-white mb-4">Content Creation</h3>
                <p className="text-gray-300">
                  Engage your audience with high-quality, platform-specific content that tells your brand story.
                </p>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <MessageSquare className="h-10 w-10 text-[#0EA5E9] mb-6" />
                <h3 className="text-2xl font-semibold text-white mb-4">Community Management</h3>
                <p className="text-gray-300">
                  Build a thriving online community by actively engaging with your followers and fostering meaningful conversations.
                </p>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <BarChart className="h-10 w-10 text-[#0EA5E9] mb-6" />
                <h3 className="text-2xl font-semibold text-white mb-4">Social Media Advertising</h3>
                <p className="text-gray-300">
                  Reach a wider audience and drive conversions with targeted social media advertising campaigns.
                </p>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <TrendingUp className="h-10 w-10 text-[#0EA5E9] mb-6" />
                <h3 className="text-2xl font-semibold text-white mb-4">Analytics & Reporting</h3>
                <p className="text-gray-300">
                  Track your social media performance and gain valuable insights with detailed analytics and reporting.
                </p>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <PieChart className="h-10 w-10 text-[#0EA5E9] mb-6" />
                <h3 className="text-2xl font-semibold text-white mb-4">Influencer Marketing</h3>
                <p className="text-gray-300">
                  Partner with influencers to reach new audiences and build brand credibility.
                </p>
              </div>
            </div>
          </div>
        </section>
        <ServiceAreas />
      </main>
      <FooterComponent />
    </div>
  );
};

export default SocialMediaPage;
