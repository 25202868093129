
import { Link } from "react-router-dom";
import { CompanyInfo } from "./organization/CompanyInfo";
import { LocationInfo } from "./organization/LocationInfo";
import { ContactMethods } from "./organization/ContactMethods";
import { Building2, ArrowBigUp } from "lucide-react";

const FooterComponent = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <footer className="bg-black border-t border-white/5">
      <div className="container mx-auto px-4 py-12">
        <div className="flex items-center justify-center mb-8">
          <Building2 className="h-12 w-12 text-[#0EA5E9]" />
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <CompanyInfo />
          <LocationInfo />
          <ContactMethods />
        </div>
        
        <div className="mt-12 pt-8 border-t border-white/5">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-sm text-gray-400">
            <div className="flex flex-wrap gap-4">
              <Link to="/privacy" className="hover:text-[#0EA5E9]">Privacy Policy</Link>
              <Link to="/terms" className="hover:text-[#0EA5E9]">Terms of Service</Link>
              <Link to="/cookie-policy" className="hover:text-[#0EA5E9]">Cookie Policy</Link>
            </div>
            <div className="text-right md:text-right flex items-center justify-end gap-4">
              <button
                onClick={scrollToTop}
                className="inline-flex items-center gap-2 bg-[#0EA5E9] hover:bg-[#0EA5E9]/90 text-white font-medium px-4 py-2 rounded-md shadow-lg transition-colors"
                aria-label="Scroll to top of page"
              >
                <ArrowBigUp className="h-5 w-5" />
                <span className="font-semibold">Back to Top</span>
              </button>
              <Link to="/" aria-label="Go to homepage">
                <img 
                  src="/lovable-uploads/7a8b1227-ba3f-445b-a121-9519a366f800.png" 
                  alt="Ironball Marketing" 
                  className="h-8 w-auto"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
