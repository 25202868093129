
import React from "react";
import { AlertTriangle } from "lucide-react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import FooterComponent from "../components/footer-component";
import { Button } from "../components/ui/button";

const NotFound = () => {
  return (
    <div className="min-h-screen bg-black flex flex-col">
      <Header />
      <main className="flex-grow flex items-center justify-center pt-32 pb-20">
        <div className="container px-4 mx-auto">
          <div className="max-w-2xl mx-auto text-center">
            <AlertTriangle className="h-24 w-24 text-[#0EA5E9] mx-auto mb-8" />
            <h1 className="text-6xl font-bold text-white mb-6">404</h1>
            <h2 className="text-2xl font-semibold text-white mb-4">Page Not Found</h2>
            <p className="text-gray-400 mb-8">
              The page you're looking for doesn't exist or has been moved.
            </p>
            <div className="flex justify-center gap-4">
              <Link to="/">
                <Button className="relative overflow-hidden group bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] hover:from-[#38BDF8] hover:via-[#0EA5E9] hover:to-[#8B5CF6] text-white px-6 py-2.5 rounded-lg transition-all duration-300 transform hover:scale-105 shadow-lg">
                  <span className="absolute inset-0 bg-black/10 group-hover:translate-y-12 transition-transform duration-300" />
                  <span className="relative font-semibold text-white drop-shadow-[0_2px_2px_rgba(0,0,0,0.3)]">
                    Back to Home
                  </span>
                </Button>
              </Link>
              <Link to="/contact">
                <Button variant="outline" className="bg-white/5 backdrop-blur-sm border-white/10 hover:bg-white/10 text-white transition-all duration-200">
                  Contact Support
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </main>
      <FooterComponent />
    </div>
  );
};

export default NotFound;
