import { BusinessLocation } from "@/models/organization";

export const generateOrganizationSchema = (location: BusinessLocation) => {
  return {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "Ironball Marketing",
    image: "https://ironballmarketing.com/lovable-uploads/7a8b1227-ba3f-445b-a121-9519a366f800.png",
    "@id": "https://ironballmarketing.com",
    url: "https://ironballmarketing.com",
    telephone: location?.phone,
    address: {
      "@type": "PostalAddress",
      streetAddress: location?.address_line1,
      addressLocality: location?.city,
      addressRegion: location?.state,
      postalCode: location?.postal_code,
      addressCountry: location?.country
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: 49.2827,
      longitude: -123.1207
    },
    openingHoursSpecification: location?.business_hours ? 
      Object.entries(location.business_hours).map(([day, hours]: [string, any]) => ({
        "@type": "OpeningHoursSpecification",
        dayOfWeek: day.charAt(0).toUpperCase() + day.slice(1),
        opens: hours.open,
        closes: hours.close
      })) : [],
    sameAs: [
      "https://www.facebook.com/ironballmarketing",
      "https://www.linkedin.com/company/ironballmarketing",
      "https://twitter.com/ironballmktg",
      "https://www.instagram.com/ironballmarketing"
    ]
  };
};

export const generateWebsiteSchema = () => {
  return {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: "Ironball Marketing",
    url: "https://ironballmarketing.com",
    potentialAction: {
      "@type": "SearchAction",
      target: "https://ironballmarketing.com/search?q={search_term_string}",
      "query-input": "required name=search_term_string"
    }
  };
};

export const generateServiceSchema = (service: {
  title: string;
  description: string;
  slug: string;
  priceRange?: string;
  aggregateRating?: {
    ratingValue: number;
    reviewCount: number;
  };
}) => {
  return {
    "@context": "https://schema.org",
    "@type": "Service",
    name: service.title,
    description: service.description,
    provider: {
      "@type": "LocalBusiness",
      name: "Ironball Marketing"
    },
    url: `https://ironballmarketing.com/${service.slug}`,
    ...(service.priceRange && { priceRange: service.priceRange }),
    ...(service.aggregateRating && {
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: service.aggregateRating.ratingValue,
        reviewCount: service.aggregateRating.reviewCount
      }
    })
  };
};

export const generateFAQSchema = (faqs: Array<{ question: string; answer: string }>) => {
  return {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: faqs.map(faq => ({
      "@type": "Question",
      name: faq.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: faq.answer
      }
    }))
  };
};

export const generateArticleSchema = (article: {
  title: string;
  description: string;
  publishDate: string;
  modifiedDate: string;
  image: string;
}) => {
  return {
    "@context": "https://schema.org",
    "@type": "Article",
    headline: article.title,
    description: article.description,
    image: article.image,
    datePublished: article.publishDate,
    dateModified: article.modifiedDate,
    author: {
      "@type": "Organization",
      name: "Ironball Marketing"
    }
  };
};

export const generateBreadcrumbSchema = (breadcrumbs: Array<{
  name: string;
  item: string;
  position: number;
}>) => {
  return {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: breadcrumbs.map(crumb => ({
      "@type": "ListItem",
      position: crumb.position,
      name: crumb.name,
      item: `https://ironballmarketing.com${crumb.item}`
    }))
  };
};

export const generatePersonSchema = (person: {
  name: string;
  jobTitle: string;
  image: string;
  description: string;
  email?: string;
  telephone?: string;
  socialProfiles?: string[];
}) => {
  return {
    "@context": "https://schema.org",
    "@type": "Person",
    name: person.name,
    jobTitle: person.jobTitle,
    image: person.image,
    description: person.description,
    ...(person.email && { email: person.email }),
    ...(person.telephone && { telephone: person.telephone }),
    ...(person.socialProfiles && { sameAs: person.socialProfiles })
  };
};

export const generateReviewSchema = (review: {
  reviewBody: string;
  author: string;
  reviewRating: number;
  datePublished: string;
}) => {
  return {
    "@context": "https://schema.org",
    "@type": "Review",
    reviewBody: review.reviewBody,
    author: {
      "@type": "Person",
      name: review.author
    },
    reviewRating: {
      "@type": "Rating",
      ratingValue: review.reviewRating,
      bestRating: "5",
      worstRating: "1"
    },
    datePublished: review.datePublished
  };
};

export const generateJobPostingSchema = (job: {
  title: string;
  description: string;
  datePosted: string;
  validThrough: string;
  employmentType: string;
  hiringOrganization: string;
  jobLocation: {
    addressLocality: string;
    addressRegion: string;
    addressCountry: string;
  };
  baseSalary?: {
    minValue: number;
    maxValue: number;
    currency: string;
  };
}) => {
  return {
    "@context": "https://schema.org",
    "@type": "JobPosting",
    title: job.title,
    description: job.description,
    datePosted: job.datePosted,
    validThrough: job.validThrough,
    employmentType: job.employmentType,
    hiringOrganization: {
      "@type": "Organization",
      name: job.hiringOrganization
    },
    jobLocation: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        addressLocality: job.jobLocation.addressLocality,
        addressRegion: job.jobLocation.addressRegion,
        addressCountry: job.jobLocation.addressCountry
      }
    },
    ...(job.baseSalary && {
      baseSalary: {
        "@type": "MonetaryAmount",
        currency: job.baseSalary.currency,
        value: {
          "@type": "QuantitativeValue",
          minValue: job.baseSalary.minValue,
          maxValue: job.baseSalary.maxValue,
          unitText: "YEAR"
        }
      }
    })
  };
};

export const generateEventSchema = (event: {
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  location: {
    name: string;
    address: string;
    city: string;
    state: string;
    country: string;
    postalCode: string;
  };
  image?: string;
  eventStatus?: string;
  eventAttendanceMode?: string;
  offers?: {
    price: number;
    currency: string;
    validFrom: string;
    url: string;
  };
}) => {
  return {
    "@context": "https://schema.org",
    "@type": "Event",
    name: event.name,
    description: event.description,
    startDate: event.startDate,
    endDate: event.endDate,
    location: {
      "@type": "Place",
      name: event.location.name,
      address: {
        "@type": "PostalAddress",
        streetAddress: event.location.address,
        addressLocality: event.location.city,
        addressRegion: event.location.state,
        postalCode: event.location.postalCode,
        addressCountry: event.location.country
      }
    },
    ...(event.image && { image: event.image }),
    ...(event.eventStatus && { eventStatus: event.eventStatus }),
    ...(event.eventAttendanceMode && { eventAttendanceMode: event.eventAttendanceMode }),
    ...(event.offers && {
      offers: {
        "@type": "Offer",
        price: event.offers.price,
        priceCurrency: event.offers.currency,
        validFrom: event.offers.validFrom,
        url: event.offers.url
      }
    })
  };
};
