
import { supabase } from "@/integrations/supabase/client";
import { type TeamMember, type Event, type JobPosting, type Review, type BusinessLocation } from "@/models/organization";
import { type Json } from "@/integrations/supabase/types";

export async function getOrganizationInfo() {
  const { data, error } = await supabase
    .from('organization_info')
    .select('*')
    .single();

  if (error) {
    console.error('Error fetching organization info:', error);
    return null;
  }

  return data;
}

export async function getBusinessLocations(): Promise<BusinessLocation[]> {
  const { data, error } = await supabase
    .from('business_locations')
    .select('*')
    .order('created_at', { ascending: true });

  if (error) {
    console.error('Error fetching business locations:', error);
    return [];
  }

  return data;
}

export async function getContactMethods() {
  const { data, error } = await supabase
    .from('contact_methods')
    .select('*')
    .order('display_order', { ascending: true });

  if (error) {
    console.error('Error fetching contact methods:', error);
    return [];
  }

  return data;
}

export async function getTeamMembers(): Promise<TeamMember[]> {
  const { data, error } = await supabase
    .from('team_members')
    .select('*')
    .order('created_at', { ascending: true });

  if (error) {
    console.error('Error fetching team members:', error);
    return [];
  }

  return data.map(member => ({
    id: member.id,
    name: member.name,
    jobTitle: member.job_title,
    description: member.description,
    imageUrl: member.image_url,
    email: member.email || undefined,
    phone: member.phone || undefined,
    socialProfiles: member.social_profiles as { [key: string]: string } | undefined,
    createdAt: member.created_at,
    updatedAt: member.updated_at
  }));
}

export async function getEvents(): Promise<Event[]> {
  const { data, error } = await supabase
    .from('events')
    .select('*')
    .order('start_date', { ascending: true });

  if (error) {
    console.error('Error fetching events:', error);
    return [];
  }

  return data.map(event => ({
    id: event.id,
    name: event.name,
    description: event.description,
    startDate: event.start_date,
    endDate: event.end_date,
    locationName: event.location_name,
    locationAddress: event.location_address,
    locationCity: event.location_city,
    locationState: event.location_state,
    locationCountry: event.location_country,
    locationPostalCode: event.location_postal_code,
    imageUrl: event.image_url,
    eventStatus: event.event_status,
    eventAttendanceMode: event.event_attendance_mode,
    price: event.price,
    priceCurrency: event.price_currency,
    createdAt: event.created_at,
    updatedAt: event.updated_at
  }));
}

export async function getJobPostings(): Promise<JobPosting[]> {
  const { data, error } = await supabase
    .from('job_postings')
    .select('*')
    .order('created_at', { ascending: false });

  if (error) {
    console.error('Error fetching job postings:', error);
    return [];
  }

  return data.map(job => ({
    id: job.id,
    title: job.title,
    description: job.description,
    datePosted: job.date_posted,
    validThrough: job.valid_through,
    employmentType: job.employment_type,
    locationCity: job.location_city,
    locationState: job.location_state,
    locationCountry: job.location_country,
    salaryMin: job.salary_min,
    salaryMax: job.salary_max,
    salaryCurrency: job.salary_currency,
    createdAt: job.created_at,
    updatedAt: job.updated_at
  }));
}

export async function getServiceReviews(serviceId: string): Promise<Review[]> {
  const { data, error } = await supabase
    .from('reviews')
    .select('*')
    .eq('service_id', serviceId)
    .order('created_at', { ascending: false });

  if (error) {
    console.error('Error fetching service reviews:', error);
    return [];
  }

  return data.map(review => ({
    id: review.id,
    serviceId: review.service_id,
    authorName: review.author_name,
    reviewBody: review.review_body,
    rating: review.rating,
    datePublished: review.date_published,
    createdAt: review.created_at,
    updatedAt: review.updated_at
  }));
}
