import React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import { generateServiceSchema } from "@/utils/schema";
import FooterComponent from "../components/footer-component";
import { Button } from "../components/ui/button";
import { 
  Globe, ArrowRight, ChartBar, Users, Target, MessageSquare, 
  BarChart, TrendingUp, PieChart, LineChart, ShoppingCart, 
  MapPin, Cloud, Building2
} from "lucide-react";
import GoogleAdsService from "../components/services/GoogleAdsService";
import SeoService from "../components/services/SeoService";
import SocialMediaService from "../components/services/SocialMediaService";
import ContentStrategyService from "../components/services/ContentStrategyService";
import EmailMarketingService from "../components/services/EmailMarketingService";
import EmailOutreachService from "../components/services/EmailOutreachService";
import { Link } from "react-router-dom";
import ServiceAreas from "@/components/ServiceAreas";

const DigitalMarketing = () => {
  const serviceData = {
    title: "Digital Marketing Services",
    description: "Drive growth with data-driven digital marketing strategies. From SEO and PPC to social media and content marketing, our proven approach delivers measurable results.",
    slug: "digital-marketing"
  };

  return (
    <div className="min-h-screen bg-black flex flex-col">
      <Helmet>
        <title>Digital Marketing Agency Vancouver | ROI-Focused Solutions</title>
        <meta name="description" content="Vancouver's full-service digital marketing agency. Comprehensive strategies spanning SEO, PPC, social media, and content marketing for sustainable business growth." />
        <meta property="og:title" content="Digital Marketing Agency Vancouver | ROI-Focused Solutions" />
        <meta property="og:description" content="Partner with Vancouver's innovative digital marketing team. Integrated strategies that drive brand awareness, leads, and revenue growth." />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Digital Marketing Agency Vancouver | ROI-Focused Solutions" />
        <meta name="twitter:description" content="Partner with Vancouver's innovative digital marketing team. Integrated strategies that drive brand awareness, leads, and revenue growth." />
      </Helmet>
      <Header />
      <main className="flex-grow pt-20">
        {/* Hero Section */}
        <section className="relative text-white py-24 px-4 overflow-hidden border-b border-white/5">
          <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1460925895917-afdab827c52f?q=80')] bg-cover bg-center bg-fixed">
            <div className="absolute inset-0 bg-gradient-to-b from-black/80 via-black/70 to-black" />
          </div>

          <div className="absolute inset-0">
            <div className="absolute -left-20 top-20 h-[400px] w-[400px] rounded-full bg-[#8B5CF6]/30 blur-[100px] animate-pulse" />
            <div className="absolute -right-20 bottom-20 h-[400px] w-[400px] rounded-full bg-[#0EA5E9]/30 blur-[100px] animate-pulse" />
            <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]" />
          </div>

          <div className="container mx-auto relative z-10">
            <div className="max-w-4xl mx-auto text-center">
              <div className="inline-block p-4 bg-white/5 backdrop-blur-sm rounded-2xl mb-6 border border-white/10">
                <ChartBar className="h-8 w-8 text-[#0EA5E9]" />
              </div>
              <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-6">
                Data-Driven
                <br />
                <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent">
                  Digital Marketing
                </span>
              </h1>
              <p className="text-xl text-gray-300 mb-10 leading-relaxed max-w-3xl mx-auto">
                Transform your online presence with our comprehensive digital marketing strategies.
                We combine analytics, creativity, and proven methodologies to drive real results.
              </p>
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <Link to="/contact">
                  <Button size="lg" className="w-full sm:w-auto text-lg py-7 px-8 bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] hover:opacity-90 text-white font-semibold shadow-[0_0_20px_rgba(14,165,233,0.3)] hover:shadow-[0_0_30px_rgba(14,165,233,0.5)] transform hover:scale-105 transition-all duration-300">
                    <span className="bg-gradient-to-r from-white via-white to-white/90 bg-clip-text text-transparent drop-shadow-[0_2px_2px_rgba(0,0,0,0.4)]">Get Started</span>
                    <ArrowRight className="ml-2 h-5 w-5 text-white" />
                  </Button>
                </Link>
                <Link to="/case-studies">
                  <Button size="lg" variant="outline" className="w-full sm:w-auto text-lg py-7 px-8 bg-white/5 backdrop-blur-sm border-2 border-white/10 hover:bg-white/10 hover:border-[#0EA5E9]/50 transform hover:scale-105 transition-all duration-300">
                    <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent">View Case Studies</span>
                    <ArrowRight className="ml-2 h-5 w-5 text-[#0EA5E9]" />
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="py-24 relative overflow-hidden border-b border-white/5">
          <div className="absolute inset-0 bg-gradient-to-r from-[#0EA5E9]/10 via-[#0EA5E9]/5 to-transparent">
            <div className="absolute inset-0 opacity-30">
              <div className="absolute h-full w-1/6 bg-gradient-to-b from-[#0EA5E9]/20 via-[#0EA5E9]/10 to-transparent animate-slide-down" style={{ left: '10%', animationDelay: '0s', animationDuration: '3s' }} />
              <div className="absolute h-full w-1/6 bg-gradient-to-b from-[#0EA5E9]/20 via-[#0EA5E9]/10 to-transparent animate-slide-down" style={{ left: '70%', animationDelay: '1.5s', animationDuration: '4.5s' }} />
            </div>
          </div>
          <div className="container mx-auto px-4 relative z-10">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold text-white mb-6">Our Services</h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                Comprehensive digital marketing solutions tailored to your business needs.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <div className="transform hover:scale-105 transition-transform duration-300">
                <GoogleAdsService />
              </div>
              <div className="transform hover:scale-105 transition-transform duration-300">
                <SeoService />
              </div>
              <div className="transform hover:scale-105 transition-transform duration-300">
                <SocialMediaService />
              </div>
              <div className="transform hover:scale-105 transition-transform duration-300">
                <ContentStrategyService />
              </div>
              <div className="transform hover:scale-105 transition-transform duration-300">
                <EmailMarketingService />
              </div>
              <div className="transform hover:scale-105 transition-transform duration-300">
                <EmailOutreachService />
              </div>
            </div>
          </div>
        </section>

        <section className="py-24">
          <div className="container mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold text-white mb-6">Industry-Specific Solutions</h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                Tailored digital marketing strategies for your specific industry needs and goals.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <ShoppingCart className="h-10 w-10 text-[#0EA5E9] mb-6" />
                <h3 className="text-2xl font-semibold text-white mb-4">E-commerce Marketing</h3>
                <p className="text-gray-300">
                  Drive online sales and revenue with targeted campaigns, optimized product listings, and seamless shopping experiences.
                </p>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <MapPin className="h-10 w-10 text-[#0EA5E9] mb-6" />
                <h3 className="text-2xl font-semibold text-white mb-4">Local Marketing</h3>
                <p className="text-gray-300">
                  Dominate your local market with geo-targeted campaigns, local SEO, and community-focused strategies.
                </p>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <Cloud className="h-10 w-10 text-[#0EA5E9] mb-6" />
                <h3 className="text-2xl font-semibold text-white mb-4">SaaS Marketing</h3>
                <p className="text-gray-300">
                  Accelerate user acquisition and reduce churn with data-driven SaaS growth strategies and automation.
                </p>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <Building2 className="h-10 w-10 text-[#0EA5E9] mb-6" />
                <h3 className="text-2xl font-semibold text-white mb-4">B2B Marketing</h3>
                <p className="text-gray-300">
                  Generate high-quality leads and nurture enterprise relationships with sophisticated B2B marketing tactics.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="py-24 bg-white/5">
          <div className="container mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold text-white mb-6">Proven Results</h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                Our clients see real, measurable growth in their digital presence and bottom line.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="p-8 rounded-xl bg-white/5 border border-white/10 text-center">
                <TrendingUp className="h-12 w-12 text-[#0EA5E9] mx-auto mb-4" />
                <div className="text-4xl font-bold text-white mb-2">300%</div>
                <p className="text-gray-300">Average ROI Increase</p>
              </div>

              <div className="p-8 rounded-xl bg-white/5 border border-white/10 text-center">
                <PieChart className="h-12 w-12 text-[#0EA5E9] mx-auto mb-4" />
                <div className="text-4xl font-bold text-white mb-2">150%</div>
                <p className="text-gray-300">Traffic Growth</p>
              </div>

              <div className="p-8 rounded-xl bg-white/5 border border-white/10 text-center">
                <LineChart className="h-12 w-12 text-[#0EA5E9] mx-auto mb-4" />
                <div className="text-4xl font-bold text-white mb-2">200%</div>
                <p className="text-gray-300">Conversion Rate</p>
              </div>
            </div>
          </div>
        </section>

        <section className="py-24 relative overflow-hidden border-y border-white/5">
          <div className="absolute inset-0 bg-gradient-to-r from-[#0EA5E9]/10 via-[#0EA5E9]/5 to-transparent">
            <div className="absolute inset-0 opacity-30">
              <div className="absolute h-full w-1/6 bg-gradient-to-b from-[#0EA5E9]/20 via-[#0EA5E9]/10 to-transparent animate-slide-down" style={{ left: '10%', animationDelay: '0s', animationDuration: '3s' }} />
              <div className="absolute h-full w-1/6 bg-gradient-to-b from-[#0EA5E9]/20 via-[#0EA5E9]/10 to-transparent animate-slide-down" style={{ left: '70%', animationDelay: '1.5s', animationDuration: '4.5s' }} />
            </div>
          </div>
          <div className="container mx-auto px-4 relative z-10">
            <div className="max-w-4xl mx-auto bg-white/5 backdrop-blur-sm rounded-2xl p-12 border border-white/10">
              <div className="text-center">
                <h2 className="text-4xl font-bold text-white mb-6">Ready to Grow Your Business?</h2>
                <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
                  Get a free digital marketing audit and discover opportunities for growth in your online presence.
                </p>
                <div className="flex flex-col sm:flex-row gap-4 justify-center">
                  <Link to="/contact">
                    <Button size="lg" className="w-full sm:w-auto text-lg py-7 px-8 bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] hover:opacity-90 text-white font-semibold shadow-[0_0_20px_rgba(14,165,233,0.3)] hover:shadow-[0_0_30px_rgba(14,165,233,0.5)] transform hover:scale-105 transition-all duration-300">
                      <span className="bg-gradient-to-r from-white via-white to-white/90 bg-clip-text text-transparent drop-shadow-[0_2px_2px_rgba(0,0,0,0.4)]">Get Started</span>
                      <ArrowRight className="ml-2 h-5 w-5 text-white" />
                    </Button>
                  </Link>
                  <Link to="/schedule">
                    <Button size="lg" variant="outline" className="w-full sm:w-auto text-lg py-7 px-8 bg-white/5 backdrop-blur-sm border-2 border-white/10 hover:bg-white/10 hover:border-[#0EA5E9]/50 transform hover:scale-105 transition-all duration-300">
                      <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent">Schedule Call</span>
                      <ArrowRight className="ml-2 h-5 w-5 text-[#0EA5E9]" />
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <FooterComponent />
    </div>
  );
};

export default DigitalMarketing;
