import React from "react";
import { Helmet } from "react-helmet";
import Header from "@/components/Header";
import FooterComponent from "@/components/footer-component";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Mail, Phone, MapPin, ArrowRight, X } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useState, FormEvent } from "react";
import { Link, useNavigate } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";

const ContactPage = () => {
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();
  const navigate = useNavigate();

  const services = [
    "Web Development",
    "Digital Marketing",
    "SaaS Development",
    "Enterprise Software",
    "SEO Optimization",
    "Content Strategy",
    "Social Media Marketing",
    "Email Marketing",
    "Google Ads",
    "Business Software",
  ];

  const handleServiceSelect = (service: string) => {
    setSelectedServices((prev) => {
      if (prev.includes(service)) {
        return prev.filter((s) => s !== service);
      } else {
        return [...prev, service];
      }
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData(e.currentTarget);
    const data = {
      name: formData.get('name'),
      email: formData.get('email'),
      phone: formData.get('phone'),
      services: selectedServices,
      message: formData.get('message')
    };

    try {
      const response = await supabase.functions.invoke('send-contact-notification', {
        body: data
      });

      if (response.error) throw response.error;

      // Redirect to thank you page
      navigate('/thank-you?ref=contact-form');
      
    } catch (error: any) {
      console.error('Error submitting form:', error);
      toast({
        title: "Error sending message",
        description: "Please try again later or contact us directly.",
        variant: "destructive",
        duration: 5000,
      });
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-black flex flex-col">
      <Helmet>
        <title>Contact Ironball Marketing | Let's Build Something Amazing</title>
        <meta name="description" content="Ready to transform your digital presence? Contact Ironball Marketing for web development, digital marketing, and custom software solutions. Get in touch for a free consultation." />
        <meta property="og:title" content="Contact Ironball Marketing | Let's Build Something Amazing" />
        <meta property="og:description" content="Transform your digital presence with Ironball Marketing. Connect with us for innovative solutions and expert guidance." />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact Ironball Marketing | Let's Build Something Amazing" />
        <meta name="twitter:description" content="Transform your digital presence with Ironball Marketing. Connect with us for innovative solutions and expert guidance." />
      </Helmet>
      <Header />
      <main className="flex-grow pt-20">
        <section className="relative text-white py-24 px-4 overflow-hidden border-b border-white/5">
          <div className="absolute inset-0">
            <div className="absolute -left-20 top-20 h-[400px] w-[400px] rounded-full bg-[#8B5CF6]/30 blur-[100px] animate-pulse" />
            <div className="absolute -right-20 bottom-20 h-[400px] w-[400px] rounded-full bg-[#0EA5E9]/30 blur-[100px] animate-pulse" />
            <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]" />
          </div>

          <div className="container mx-auto relative z-10">
            <div className="max-w-4xl mx-auto text-center">
              <div className="inline-block p-4 bg-white/5 backdrop-blur-sm rounded-2xl mb-6 border border-white/10">
                <Mail className="h-8 w-8 text-[#0EA5E9]" />
              </div>
              <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-6">
                Let's Build Something
                <br />
                <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent">
                  Amazing Together
                </span>
              </h1>
              <p className="text-xl text-gray-300 mb-10 leading-relaxed max-w-3xl mx-auto">
                Ready to transform your digital presence? Our team of experts is here to help you achieve your business goals.
              </p>
            </div>
          </div>
        </section>

        <section className="py-24">
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-12 max-w-6xl mx-auto">
              <div className="space-y-8">
                <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6 border border-white/10">
                  <Mail className="h-6 w-6 text-[#0EA5E9] mb-4" />
                  <h3 className="text-xl font-semibold text-white mb-2">Email Us</h3>
                  <p className="text-gray-400">
                    <a href="mailto:hello@ironballmarketing.com" className="hover:text-[#0EA5E9] transition-colors">
                      hello@ironballmarketing.com
                    </a>
                  </p>
                </div>

                <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6 border border-white/10">
                  <Phone className="h-6 w-6 text-[#0EA5E9] mb-4" />
                  <h3 className="text-xl font-semibold text-white mb-2">Call Us</h3>
                  <p className="text-gray-400">
                    <a href="tel:+16043184483" className="hover:text-[#0EA5E9] transition-colors">
                      1 (604) 318-4483
                    </a>
                  </p>
                </div>

                <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6 border border-white/10">
                  <MapPin className="h-6 w-6 text-[#0EA5E9] mb-4" />
                  <h3 className="text-xl font-semibold text-white mb-2">Visit Us</h3>
                  <p className="text-gray-400">
                    Our Location<br />
                    Vancouver, BC<br />
                    <span className="italic">By appointment only</span><br />
                    <Link to="/schedule" className="text-[#0EA5E9] hover:text-[#0EA5E9]/80 transition-colors">
                      Schedule a meeting →
                    </Link>
                  </p>
                </div>
              </div>

              <div className="lg:col-span-2">
                <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10">
                  <h2 className="text-3xl font-bold text-white mb-6">Get in Touch</h2>
                  <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                      <div>
                        <label htmlFor="name" className="block text-sm font-medium text-gray-300 mb-2">
                          Name
                        </label>
                        <Input
                          id="name"
                          name="name"
                          placeholder="Your name"
                          className="bg-white/5 border-white/10 text-white placeholder:text-gray-500"
                          required
                        />
                      </div>
                      <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-2">
                          Email
                        </label>
                        <Input
                          id="email"
                          name="email"
                          type="email"
                          placeholder="your@email.com"
                          className="bg-white/5 border-white/10 text-white placeholder:text-gray-500"
                          required
                        />
                      </div>
                      <div>
                        <label htmlFor="phone" className="block text-sm font-medium text-gray-300 mb-2">
                          Phone
                        </label>
                        <Input
                          id="phone"
                          name="phone"
                          type="tel"
                          placeholder="(123) 456-7890"
                          className="bg-white/5 border-white/10 text-white placeholder:text-gray-500"
                          required
                        />
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-300 mb-2">
                        Services Interested In
                      </label>
                      <div className="flex flex-wrap gap-2 mb-3">
                        {selectedServices.map((service) => (
                          <div
                            key={service}
                            className="bg-[#0EA5E9]/20 text-white px-3 py-1 rounded-full flex items-center gap-2 border border-[#0EA5E9]/30"
                          >
                            {service}
                            <button
                              type="button"
                              onClick={() => handleServiceSelect(service)}
                              className="hover:text-red-400 transition-colors"
                            >
                              <X className="h-4 w-4" />
                            </button>
                          </div>
                        ))}
                      </div>
                      <Select
                        onValueChange={(value) => handleServiceSelect(value)}
                        value=""
                      >
                        <SelectTrigger className="bg-white/5 border-white/10 text-white">
                          <SelectValue placeholder="Add a service" />
                        </SelectTrigger>
                        <SelectContent className="bg-black/95 border-white/10">
                          {services
                            .filter((service) => !selectedServices.includes(service))
                            .map((service) => (
                              <SelectItem
                                key={service}
                                value={service}
                                className="text-white hover:bg-white/10 focus:bg-white/10 focus:text-white"
                              >
                                {service}
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                    </div>

                    <div>
                      <label htmlFor="message" className="block text-sm font-medium text-gray-300 mb-2">
                        Message
                      </label>
                      <Textarea
                        id="message"
                        name="message"
                        placeholder="Tell us about your project..."
                        className="bg-white/5 border-white/10 text-white placeholder:text-gray-500 min-h-[150px]"
                        required
                      />
                    </div>

                    <Button 
                      type="submit"
                      className="w-full bg-[#0EA5E9] hover:bg-[#0EA5E9]/90 text-white py-6 text-lg rounded-xl"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        "Sending..."
                      ) : (
                        <>
                          Send Message <ArrowRight className="ml-2 h-5 w-5" />
                        </>
                      )}
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <FooterComponent />
    </div>
  );
};

export default ContactPage;
