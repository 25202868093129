
import { Button } from "../ui/button";
import { Badge } from "../ui/badge";
import { ArrowRight, CheckCircle2 } from "lucide-react";

interface CaseStudy {
  title: string;
  client: string;
  industry: string;
  results: string[];
  image: string;
  tags: string[];
}

interface CaseStudyCardProps {
  study: CaseStudy;
}

const CaseStudyCard = ({ study }: CaseStudyCardProps) => {
  return (
    <div className="group relative overflow-hidden rounded-xl bg-white/5 backdrop-blur-sm border border-white/10 flex flex-col">
      <div className="relative h-64 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent z-10" />
        <img 
          src={study.image} 
          alt={study.title}
          className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
        />
      </div>

      <div className="p-6 flex flex-col flex-grow">
        <div className="flex flex-wrap gap-2 mb-4">
          {study.tags.map((tag, tagIndex) => (
            <Badge 
              key={tagIndex}
              variant="secondary" 
              className="bg-white/5 text-[#0EA5E9] border-white/10"
            >
              {tag}
            </Badge>
          ))}
        </div>

        <h3 className="text-xl font-bold text-white mb-2">{study.title}</h3>
        <p className="text-gray-400 mb-4">
          {study.client} • {study.industry}
        </p>

        <div className="space-y-2 mb-6">
          {study.results.map((result, resultIndex) => (
            <div key={resultIndex} className="flex items-start gap-2">
              <CheckCircle2 className="h-5 w-5 text-[#0EA5E9] mt-0.5" />
              <span className="text-gray-300">{result}</span>
            </div>
          ))}
        </div>

        <div className="mt-auto hidden">
          <Button 
            className="w-full bg-white/5 hover:bg-white/10 text-white border border-white/10"
          >
            <span className="flex items-center justify-center gap-2">
              View Case Study
              <ArrowRight className="w-4 h-4" />
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CaseStudyCard;
