import React from "react";
import { Helmet } from "react-helmet";
import Header from "@/components/Header";
import FooterComponent from "@/components/footer-component";
import { useEffect } from "react";

const SchedulePage = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://link.msgsndr.com/js/form_embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="min-h-screen bg-black flex flex-col">
      <Helmet>
        <title>Schedule a Strategy Call | Ironball Marketing Vancouver</title>
        <meta name="description" content="Book a free strategy call with our Vancouver digital experts. Discuss your project needs and discover how we can transform your digital presence with innovative solutions." />
        <meta property="og:title" content="Schedule a Strategy Call | Ironball Marketing Vancouver" />
        <meta property="og:description" content="Take the first step towards transforming your digital presence. Book a free strategy call with our Vancouver experts today." />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Schedule a Strategy Call | Ironball Marketing Vancouver" />
        <meta name="twitter:description" content="Take the first step towards transforming your digital presence. Book a free strategy call with our Vancouver experts today." />
      </Helmet>
      <Header />
      <main className="flex-grow pt-20">
        <section className="relative text-white py-24 px-4 overflow-hidden border-b border-white/5">
          <div className="absolute inset-0">
            <div className="absolute -left-20 top-20 h-[400px] w-[400px] rounded-full bg-[#8B5CF6]/30 blur-[100px] animate-pulse" />
            <div className="absolute -right-20 bottom-20 h-[400px] w-[400px] rounded-full bg-[#0EA5E9]/30 blur-[100px] animate-pulse" />
            <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]" />
          </div>

          <div className="container mx-auto relative z-10">
            <div className="max-w-4xl mx-auto text-center">
              <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-6">
                Schedule a
                <br />
                <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent">
                  Strategy Call
                </span>
              </h1>
              <p className="text-xl text-gray-300 mb-10 leading-relaxed max-w-3xl mx-auto">
                Book a call with our team to discuss your project and explore how we can help you achieve your business goals.
              </p>
            </div>
          </div>
        </section>

        <section className="py-16 px-4">
          <div className="container mx-auto">
            <div className="max-w-4xl mx-auto bg-white/5 backdrop-blur-lg rounded-2xl border border-white/10 p-8">
              <div className="booking-widget-container min-h-[600px]">
                <iframe 
                  src="https://api.leadconnectorhq.com/widget/booking/aW2nyDjA8WYRMWair7Zh" 
                  style={{
                    width: '100%',
                    border: 'none',
                    overflow: 'hidden',
                    minHeight: '700px',
                    backgroundColor: 'transparent'
                  }}
                  scrolling="no" 
                  id="aW2nyDjA8WYRMWair7Zh_1739694742906"
                />
              </div>
            </div>
          </div>
        </section>
      </main>
      <FooterComponent />
    </div>
  );
};

export default SchedulePage;
