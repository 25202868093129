import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import FooterComponent from "../../components/footer-component";
import { Button } from "../../components/ui/button";
import { ArrowRight, Edit } from "lucide-react";
import { Link } from "react-router-dom";
import ServiceAreas from "@/components/ServiceAreas";

const ContentStrategyPage = () => {
  return (
    <div className="min-h-screen bg-black flex flex-col">
      <Helmet>
        <title>Content Strategy Services | Data-Driven Content Solutions</title>
        <meta
          name="description"
          content="Elevate your brand with our data-driven content strategy services. We create compelling narratives that resonate with your audience and drive conversions."
        />
        <meta
          property="og:title"
          content="Content Strategy Services | Data-Driven Content Solutions"
        />
        <meta
          property="og:description"
          content="Transform your content into a powerful marketing asset. Our expert content strategists craft engaging content that aligns with your business goals."
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Content Strategy Services | Data-Driven Content Solutions"
        />
        <meta
          name="twitter:description"
          content="Unlock the potential of your content with our strategic content solutions. We help you create, distribute, and optimize content for maximum impact."
        />
      </Helmet>
      <Header />
      <main className="flex-grow pt-20">
        <section className="relative text-white py-24 px-4 overflow-hidden border-b border-white/5">
          <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1554475901-45ef4c444a44?q=80')] bg-cover bg-center bg-fixed">
            <div className="absolute inset-0 bg-gradient-to-b from-black/80 via-black/70 to-black" />
          </div>

          <div className="absolute inset-0">
            <div className="absolute -left-20 top-20 h-[400px] w-[400px] rounded-full bg-[#8B5CF6]/30 blur-[100px] animate-pulse" />
            <div className="absolute -right-20 bottom-20 h-[400px] w-[400px] rounded-full bg-[#0EA5E9]/30 blur-[100px] animate-pulse" />
            <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]" />
          </div>

          <div className="container mx-auto relative z-10">
            <div className="max-w-4xl mx-auto text-center">
              <div className="inline-block p-4 bg-white/5 backdrop-blur-sm rounded-2xl mb-6 border border-white/10">
                <Edit className="h-8 w-8 text-[#0EA5E9]" />
              </div>
              <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-6">
                Data-Driven
                <br />
                <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent">
                  Content Strategy
                </span>
              </h1>
              <p className="text-xl text-gray-300 mb-10 leading-relaxed max-w-3xl mx-auto">
                Transform your content into a powerful marketing asset with our
                expert content strategy services.
              </p>
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <Link to="/contact">
                  <Button
                    size="lg"
                    className="w-full sm:w-auto text-lg py-7 px-8 bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] hover:opacity-90 text-white font-semibold shadow-[0_0_20px_rgba(14,165,233,0.3)] hover:shadow-[0_0_30px_rgba(14,165,233,0.5)] transform hover:scale-105 transition-all duration-300"
                  >
                    <span className="bg-gradient-to-r from-white via-white to-white/90 bg-clip-text text-transparent drop-shadow-[0_2px_2px_rgba(0,0,0,0.4)]">
                      Get Started
                    </span>
                    <ArrowRight className="ml-2 h-5 w-5 text-white" />
                  </Button>
                </Link>
                <Link to="/case-studies">
                  <Button
                    size="lg"
                    variant="outline"
                    className="w-full sm:w-auto text-lg py-7 px-8 bg-white/5 backdrop-blur-sm border-2 border-white/10 hover:bg-white/10 hover:border-[#0EA5E9]/50 transform hover:scale-105 transition-all duration-300"
                  >
                    <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent">
                      View Case Studies
                    </span>
                    <ArrowRight className="ml-2 h-5 w-5 text-[#0EA5E9]" />
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="py-24 relative overflow-hidden border-b border-white/5">
          <div className="absolute inset-0 bg-gradient-to-r from-[#0EA5E9]/10 via-[#0EA5E9]/5 to-transparent">
            <div className="absolute inset-0 opacity-30">
              <div
                className="absolute h-full w-1/6 bg-gradient-to-b from-[#0EA5E9]/20 via-[#0EA5E9]/10 to-transparent animate-slide-down"
                style={{
                  left: "10%",
                  animationDelay: "0s",
                  animationDuration: "3s",
                }}
              />
              <div
                className="absolute h-full w-1/6 bg-gradient-to-b from-[#0EA5E9]/20 via-[#0EA5E9]/10 to-transparent animate-slide-down"
                style={{
                  left: "70%",
                  animationDelay: "1.5s",
                  animationDuration: "4.5s",
                }}
              />
            </div>
          </div>
          <div className="container mx-auto px-4 relative z-10">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold text-white mb-6">
                Our Content Strategy Services
              </h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                Elevate your brand with our comprehensive content strategy
                services.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <h3 className="text-2xl font-semibold text-white mb-4">
                  Content Audit and Analysis
                </h3>
                <p className="text-gray-300">
                  We conduct a thorough audit of your existing content to
                  identify strengths, weaknesses, and opportunities for
                  improvement.
                </p>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <h3 className="text-2xl font-semibold text-white mb-4">
                  Audience Persona Development
                </h3>
                <p className="text-gray-300">
                  We create detailed audience personas to understand your target
                  audience's needs, preferences, and behaviors.
                </p>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <h3 className="text-2xl font-semibold text-white mb-4">
                  Content Calendar and Planning
                </h3>
                <p className="text-gray-300">
                  We develop a comprehensive content calendar and plan to ensure
                  consistent and effective content delivery.
                </p>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <h3 className="text-2xl font-semibold text-white mb-4">
                  Content Creation and Optimization
                </h3>
                <p className="text-gray-300">
                  We create high-quality, engaging content that is optimized for
                  search engines and your target audience.
                </p>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <h3 className="text-2xl font-semibold text-white mb-4">
                  Content Distribution and Promotion
                </h3>
                <p className="text-gray-300">
                  We distribute and promote your content across multiple channels
                  to reach a wider audience and drive engagement.
                </p>
              </div>

              <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                <h3 className="text-2xl font-semibold text-white mb-4">
                  Content Performance Tracking and Reporting
                </h3>
                <p className="text-gray-300">
                  We track and report on your content's performance to measure
                  its effectiveness and identify areas for improvement.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="py-24 bg-white/5">
          <div className="container mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold text-white mb-6">
                Why Choose Our Content Strategy Services?
              </h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                We deliver measurable results and help you achieve your business
                goals.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="p-8 rounded-xl bg-white/5 border border-white/10 text-center">
                <div className="text-4xl font-bold text-white mb-2">
                  Data-Driven Approach
                </div>
                <p className="text-gray-300">
                  We use data and analytics to inform our content strategy and
                  ensure that it is aligned with your business goals.
                </p>
              </div>

              <div className="p-8 rounded-xl bg-white/5 border border-white/10 text-center">
                <div className="text-4xl font-bold text-white mb-2">
                  Experienced Team
                </div>
                <p className="text-gray-300">
                  Our team of experienced content strategists has a proven track
                  record of success.
                </p>
              </div>

              <div className="p-8 rounded-xl bg-white/5 border border-white/10 text-center">
                <div className="text-4xl font-bold text-white mb-2">
                  Customized Solutions
                </div>
                <p className="text-gray-300">
                  We tailor our content strategy services to meet your specific
                  needs and goals.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="py-24 relative overflow-hidden border-y border-white/5">
          <div className="absolute inset-0 bg-gradient-to-r from-[#0EA5E9]/10 via-[#0EA5E9]/5 to-transparent">
            <div className="absolute inset-0 opacity-30">
              <div
                className="absolute h-full w-1/6 bg-gradient-to-b from-[#0EA5E9]/20 via-[#0EA5E9]/10 to-transparent animate-slide-down"
                style={{
                  left: "10%",
                  animationDelay: "0s",
                  animationDuration: "3s",
                }}
              />
              <div
                className="absolute h-full w-1/6 bg-gradient-to-b from-[#0EA5E9]/20 via-[#0EA5E9]/10 to-transparent animate-slide-down"
                style={{
                  left: "70%",
                  animationDelay: "1.5s",
                  animationDuration: "4.5s",
                }}
              />
            </div>
          </div>
          <div className="container mx-auto px-4 relative z-10">
            <div className="max-w-4xl mx-auto bg-white/5 backdrop-blur-sm rounded-2xl p-12 border border-white/10">
              <div className="text-center">
                <h2 className="text-4xl font-bold text-white mb-6">
                  Ready to Transform Your Content Strategy?
                </h2>
                <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
                  Get a free consultation and discover how our content strategy
                  services can help you achieve your business goals.
                </p>
                <div className="flex flex-col sm:flex-row gap-4 justify-center">
                  <Link to="/contact">
                    <Button
                      size="lg"
                      className="w-full sm:w-auto text-lg py-7 px-8 bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] hover:opacity-90 text-white font-semibold shadow-[0_0_20px_rgba(14,165,233,0.3)] hover:shadow-[0_0_30px_rgba(14,165,233,0.5)] transform hover:scale-105 transition-all duration-300"
                    >
                      <span className="bg-gradient-to-r from-white via-white to-white/90 bg-clip-text text-transparent drop-shadow-[0_2px_2px_rgba(0,0,0,0.4)]">
                        Get Started
                      </span>
                      <ArrowRight className="ml-2 h-5 w-5 text-white" />
                    </Button>
                  </Link>
                  <Link to="/schedule">
                    <Button
                      size="lg"
                      variant="outline"
                      className="w-full sm:w-auto text-lg py-7 px-8 bg-white/5 backdrop-blur-sm border-2 border-white/10 hover:bg-white/10 hover:border-[#0EA5E9]/50 transform hover:scale-105 transition-all duration-300"
                    >
                      <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent">
                        Schedule Call
                      </span>
                      <ArrowRight className="ml-2 h-5 w-5 text-[#0EA5E9]" />
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ServiceAreas />
      </main>
      <FooterComponent />
    </div>
  );
};

export default ContentStrategyPage;
