import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Index from "./pages/Index";
import WebDevelopment from "./pages/web-development";
import DigitalMarketing from "./pages/digital-marketing";
import SEOServicesPage from "./pages/digital-marketing/seo-services";
import GoogleAdsPage from "./pages/digital-marketing/google-ads";
import SocialMediaPage from "./pages/digital-marketing/social-media";
import ContentStrategyPage from "./pages/digital-marketing/content-strategy";
import EmailMarketingPage from "./pages/digital-marketing/email-marketing";
import EmailOutreachPage from "./pages/digital-marketing/email-outreach";
import SoftwareSolutions from "./pages/software-solutions";
import SaasDevelopment from "./pages/saas-development";
import AboutPage from "./pages/about";
import ProcessPage from "./pages/process";
import CaseStudiesPage from "./pages/case-studies";
import ContactPage from "./pages/contact";
import SchedulePage from "./pages/schedule";
import CookiePolicy from "./pages/cookie-policy";
import NotFound from "./pages/NotFound";
import CookieConsent from "./components/CookieConsent";
import ThankYouPage from "./pages/thank-you";

const queryClient = new QueryClient();

const App = () => (
  <QueryClientProvider client={queryClient}>
    <TooltipProvider>
      <Toaster />
      <Sonner />
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/web-development" element={<WebDevelopment />} />
          <Route path="/digital-marketing" element={<DigitalMarketing />} />
          <Route path="/digital-marketing/seo-services" element={<SEOServicesPage />} />
          <Route path="/digital-marketing/google-ads" element={<GoogleAdsPage />} />
          <Route path="/digital-marketing/social-media" element={<SocialMediaPage />} />
          <Route path="/digital-marketing/content-strategy" element={<ContentStrategyPage />} />
          <Route path="/digital-marketing/email-marketing" element={<EmailMarketingPage />} />
          <Route path="/digital-marketing/email-outreach" element={<EmailOutreachPage />} />
          <Route path="/software-solutions" element={<SoftwareSolutions />} />
          <Route path="/saas-development" element={<SaasDevelopment />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/process" element={<ProcessPage />} />
          <Route path="/case-studies" element={<CaseStudiesPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/schedule" element={<SchedulePage />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <CookieConsent />
      </BrowserRouter>
    </TooltipProvider>
  </QueryClientProvider>
);

export default App;
