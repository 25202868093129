
import React from "react";
import { Helmet } from "react-helmet";
import Header from "@/components/Header";
import FooterComponent from "@/components/footer-component";
import { CheckCircle2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

const ThankYouPage = () => {
  return (
    <div className="min-h-screen bg-black flex flex-col">
      <Helmet>
        <title>Thank You | Ironball Marketing</title>
        <meta name="description" content="Thank you for contacting Ironball Marketing. Our team will be in touch with you shortly." />
        {/* Preload critical assets */}
        <link 
          rel="preload" 
          href="https://images.unsplash.com/photo-1582562124811-c09040d0a901?w=800&auto=format&quality=75"
          as="image"
        />
      </Helmet>
      
      <Header />
      
      <main className="flex-grow pt-20">
        <div className="container mx-auto px-4 py-24">
          <div className="max-w-2xl mx-auto text-center">
            {/* Optimized image-based hero section */}
            <div 
              className="w-full h-48 mb-8 rounded-lg bg-cover bg-center relative overflow-hidden"
              style={{
                backgroundImage: `url(https://images.unsplash.com/photo-1582562124811-c09040d0a901?w=800&auto=format&quality=75)`,
                backgroundColor: '#1a1a1a', // Placeholder color while image loads
              }}
            >
              <div className="absolute inset-0 bg-black/50 flex items-center justify-center">
                <div className="inline-flex items-center justify-center w-16 h-16 bg-[#0EA5E9]/20 rounded-full">
                  <CheckCircle2 className="w-8 h-8 text-[#0EA5E9]" />
                </div>
              </div>
            </div>
            
            <h1 className="text-4xl font-bold text-white mb-6 loading='eager'">
              Thank You for Contacting Us!
            </h1>
            
            {/* Reduced text content for faster paint */}
            <p className="text-lg text-gray-400 mb-8 max-w-lg mx-auto">
              We've received your message and will be in touch shortly.
            </p>
            
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <Button
                asChild
                variant="default"
                className="bg-[#0EA5E9] hover:bg-[#0EA5E9]/90"
              >
                <Link to="/case-studies">
                  View Our Case Studies
                </Link>
              </Button>
              
              <Button
                asChild
                variant="outline"
                className="border-white/10 hover:bg-white/5"
              >
                <Link to="/">
                  Return to Homepage
                </Link>
              </Button>
            </div>
          </div>
        </div>
      </main>
      
      <FooterComponent />
    </div>
  );
};

export default ThankYouPage;
