import React from "react";
import Header from "@/components/Header";
import FooterComponent from "@/components/footer-component";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ServiceAreas from "@/components/ServiceAreas";
import {
  Rocket, ArrowRight, Code, Shield, Layout, FileCode, Server,
  Lock, Globe, Database, Cloud, Settings, CheckCircle, XCircle,
  ThumbsUp, ThumbsDown
} from "lucide-react";

const SoftwareSolutions = () => {
  const softwareServices = [
    {
      title: "Custom Development",
      description: "Tailored software solutions designed specifically for your business needs and goals",
      icon: <Code className="h-10 w-10 text-[#0EA5E9] mb-6" />,
      features: [
        "Scalable architecture design",
        "Custom feature development",
        "Third-party integrations",
        "Performance optimization"
      ]
    },
    {
      title: "Enterprise Solutions",
      description: "Robust and secure enterprise-grade applications built for scale and performance",
      icon: <Shield className="h-10 w-10 text-[#0EA5E9] mb-6" />,
      features: [
        "Enterprise architecture",
        "Legacy system integration",
        "Security compliance",
        "High availability design"
      ]
    },
    {
      title: "Cloud Integration",
      description: "Seamless cloud integration and migration services for modern businesses",
      icon: <Server className="h-10 w-10 text-[#0EA5E9] mb-6" />,
      features: [
        "Cloud migration strategy",
        "Multi-cloud solutions",
        "Serverless architecture",
        "DevOps implementation"
      ]
    },
    {
      title: "Security Implementation",
      description: "Built-in security measures and compliance with industry standards",
      icon: <Lock className="h-10 w-10 text-[#0EA5E9] mb-6" />,
      features: [
        "Security best practices",
        "Compliance frameworks",
        "Data encryption",
        "Access control systems"
      ]
    }
  ];

  const solutionTypes = [
    {
      title: "Web Applications",
      description: "Modern web solutions with responsive design",
      icon: <Globe className="h-8 w-8 text-[#0EA5E9]" />,
      metrics: "99.9% uptime guarantee"
    },
    {
      title: "Database Systems",
      description: "Scalable data management solutions",
      icon: <Database className="h-8 w-8 text-[#0EA5E9]" />,
      metrics: "50% faster queries"
    },
    {
      title: "Cloud Solutions",
      description: "Flexible and scalable cloud infrastructure",
      icon: <Cloud className="h-8 w-8 text-[#0EA5E9]" />,
      metrics: "40% cost reduction"
    },
    {
      title: "API Integration",
      description: "Seamless system connectivity",
      icon: <Settings className="h-8 w-8 text-[#0EA5E9]" />,
      metrics: "30% efficiency boost"
    }
  ];

  const pros = [
    "Customized to your needs",
    "Scalable architecture",
    "Enhanced security",
    "Improved efficiency",
    "Competitive advantage",
    "Future-proof solutions",
    "Integration capability",
    "ROI-focused development"
  ];

  const cons = [
    "Initial investment required",
    "Development timeline",
    "Regular maintenance needed",
    "Team training required",
    "Change management",
    "System dependencies",
    "Updates management",
    "Resource allocation"
  ];

  return (
    <div className="min-h-screen bg-black flex flex-col">
      <Helmet>
        <title>Enterprise Software Development Vancouver | Custom Solutions</title>
        <meta name="description" content="Custom enterprise software solutions built for scalability and security. From system integration to cloud migration, we deliver robust solutions for Vancouver businesses." />
        <meta property="og:title" content="Enterprise Software Development Vancouver | Custom Solutions" />
        <meta property="og:description" content="Transform your business with custom enterprise software solutions. Expert development team delivering secure, scalable applications for Vancouver organizations." />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Enterprise Software Development Vancouver | Custom Solutions" />
        <meta name="twitter:description" content="Transform your business with custom enterprise software solutions. Expert development team delivering secure, scalable applications for Vancouver organizations." />
      </Helmet>
      <Header />
      <main className="flex-grow pt-20">
        <section className="relative text-white py-24 px-4 overflow-hidden border-b border-white/5">
          <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?q=80')] bg-cover bg-center bg-fixed">
            <div className="absolute inset-0 bg-gradient-to-b from-black/80 via-black/70 to-black" />
          </div>

          <div className="absolute inset-0">
            <div className="absolute -left-20 top-20 h-[400px] w-[400px] rounded-full bg-[#8B5CF6]/30 blur-[100px] animate-pulse" />
            <div className="absolute -right-20 bottom-20 h-[400px] w-[400px] rounded-full bg-[#0EA5E9]/30 blur-[100px] animate-pulse" />
            <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]" />
          </div>

          <div className="container mx-auto relative z-10">
            <div className="max-w-4xl mx-auto text-center">
              <div className="inline-block p-4 bg-white/5 backdrop-blur-sm rounded-2xl mb-6 border border-white/10">
                <Rocket className="h-8 w-8 text-[#0EA5E9]" />
              </div>
              <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-6">
                Enterprise
                <br />
                <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent">
                  Software Solutions
                </span>
              </h1>
              <p className="text-xl text-gray-300 mb-10 leading-relaxed max-w-3xl mx-auto">
                Transform your business with custom software solutions built for scale, security, and performance.
                From enterprise applications to cloud solutions, we deliver results.
              </p>
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <Button size="lg" className="w-full sm:w-auto text-lg py-7 px-8 bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] hover:opacity-90 text-white font-semibold shadow-[0_0_20px_rgba(14,165,233,0.3)] hover:shadow-[0_0_30px_rgba(14,165,233,0.5)] transform hover:scale-105 transition-all duration-300">
                  <span className="bg-gradient-to-r from-white via-white to-white/90 bg-clip-text text-transparent drop-shadow-[0_2px_2px_rgba(0,0,0,0.4)]">Start Your Project</span>
                  <ArrowRight className="ml-2 h-5 w-5 text-white" />
                </Button>
                <Button size="lg" variant="outline" className="w-full sm:w-auto text-lg py-7 px-8 bg-white/5 backdrop-blur-sm border-2 border-white/10 hover:bg-white/10 hover:border-[#0EA5E9]/50 transform hover:scale-105 transition-all duration-300">
                  <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent">View Case Studies</span>
                  <ArrowRight className="ml-2 h-5 w-5 text-[#0EA5E9]" />
                </Button>
              </div>
            </div>
          </div>
        </section>

        <section className="py-24 relative overflow-hidden border-b border-white/5">
          <div className="container mx-auto px-4 relative z-10">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold text-white mb-6">Enterprise Software Solutions</h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                Comprehensive software solutions tailored to your business requirements and industry standards.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {softwareServices.map((service) => (
                <div key={service.title} className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                  {service.icon}
                  <h3 className="text-2xl font-semibold text-white mb-4">{service.title}</h3>
                  <p className="text-gray-300 mb-6">{service.description}</p>
                  <ul className="space-y-3">
                    {service.features.map((feature, index) => (
                      <li key={index} className="flex items-center gap-3 text-gray-300">
                        <CheckCircle className="h-5 w-5 text-[#0EA5E9] flex-shrink-0" />
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="py-24 relative overflow-hidden border-b border-white/5">
          <div className="container mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold text-white mb-6">Solution Types</h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                Diverse software solutions designed to meet your specific business needs.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {solutionTypes.map((type) => (
                <Card key={type.title} className="bg-white/5 backdrop-blur-sm border-white/10 hover:bg-white/10 transition-all duration-300">
                  <CardContent className="p-6">
                    <div className="mb-4">{type.icon}</div>
                    <h3 className="text-xl font-semibold text-white mb-2">{type.title}</h3>
                    <p className="text-gray-300 mb-4">{type.description}</p>
                    <p className="text-sm text-[#0EA5E9]">{type.metrics}</p>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </section>

        <section className="py-24 relative overflow-hidden border-b border-white/5">
          <div className="container mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold text-white mb-6">Software Development Insights</h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                Understanding the advantages and considerations of custom software development.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-5xl mx-auto">
              <div className="bg-gradient-to-br from-[#0EA5E9]/10 to-[#0EA5E9]/5 backdrop-blur-sm rounded-xl p-8 border border-[#0EA5E9]/20">
                <div className="flex items-center gap-3 mb-6">
                  <ThumbsUp className="h-8 w-8 text-[#0EA5E9]" />
                  <h3 className="text-2xl font-semibold text-white">Benefits</h3>
                </div>
                <ul className="space-y-4">
                  {pros.map((pro, index) => (
                    <li key={index} className="flex items-center gap-3 text-gray-300">
                      <CheckCircle className="h-5 w-5 text-[#0EA5E9] flex-shrink-0" />
                      <span>{pro}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="bg-gradient-to-br from-[#ea384c]/10 to-[#ea384c]/5 backdrop-blur-sm rounded-xl p-8 border border-[#ea384c]/20">
                <div className="flex items-center gap-3 mb-6">
                  <ThumbsDown className="h-8 w-8 text-[#ea384c]" />
                  <h3 className="text-2xl font-semibold text-white">Considerations</h3>
                </div>
                <ul className="space-y-4">
                  {cons.map((con, index) => (
                    <li key={index} className="flex items-center gap-3 text-gray-300">
                      <XCircle className="h-5 w-5 text-[#ea384c] flex-shrink-0" />
                      <span>{con}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="py-24 bg-white/5">
          <div className="container mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold text-white mb-6">Our Technology Stack</h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                We use cutting-edge technologies to build robust and scalable solutions.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              <div className="p-8 rounded-xl bg-white/5 border border-white/10">
                <Layout className="h-8 w-8 text-[#0EA5E9] mb-4" />
                <h3 className="text-xl font-semibold text-white mb-4">Frontend</h3>
                <ul className="space-y-2 text-gray-300">
                  <li>React & Next.js</li>
                  <li>TypeScript</li>
                  <li>Progressive Web Apps</li>
                </ul>
              </div>

              <div className="p-8 rounded-xl bg-white/5 border border-white/10">
                <Server className="h-8 w-8 text-[#0EA5E9] mb-4" />
                <h3 className="text-xl font-semibold text-white mb-4">Backend</h3>
                <ul className="space-y-2 text-gray-300">
                  <li>Node.js & Python</li>
                  <li>PostgreSQL & MongoDB</li>
                  <li>GraphQL & REST APIs</li>
                </ul>
              </div>

              <div className="p-8 rounded-xl bg-white/5 border border-white/10">
                <Shield className="h-8 w-8 text-[#0EA5E9] mb-4" />
                <h3 className="text-xl font-semibold text-white mb-4">Security</h3>
                <ul className="space-y-2 text-gray-300">
                  <li>OAuth & JWT</li>
                  <li>End-to-end Encryption</li>
                  <li>GDPR Compliance</li>
                </ul>
              </div>

              <div className="p-8 rounded-xl bg-white/5 border border-white/10">
                <FileCode className="h-8 w-8 text-[#0EA5E9] mb-4" />
                <h3 className="text-xl font-semibold text-white mb-4">DevOps</h3>
                <ul className="space-y-2 text-gray-300">
                  <li>AWS & Azure</li>
                  <li>Docker & Kubernetes</li>
                  <li>CI/CD Pipelines</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="py-24 relative overflow-hidden border-y border-white/5">
          <div className="absolute inset-0 bg-gradient-to-r from-[#0EA5E9]/10 via-[#0EA5E9]/5 to-transparent">
            <div className="absolute inset-0 opacity-30">
              <div className="absolute h-full w-1/6 bg-gradient-to-b from-[#0EA5E9]/20 via-[#0EA5E9]/10 to-transparent animate-slide-down" style={{ left: '10%', animationDelay: '0s', animationDuration: '3s' }} />
              <div className="absolute h-full w-1/6 bg-gradient-to-b from-[#0EA5E9]/20 via-[#0EA5E9]/10 to-transparent animate-slide-down" style={{ left: '70%', animationDelay: '1.5s', animationDuration: '4.5s' }} />
            </div>
          </div>
          <div className="container mx-auto px-4 relative z-10">
            <div className="max-w-4xl mx-auto bg-white/5 backdrop-blur-sm rounded-2xl p-12 border border-white/10">
              <div className="text-center">
                <h2 className="text-4xl font-bold text-white mb-6">Ready to Transform Your Business?</h2>
                <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
                  Let's build custom software solutions that drive efficiency and growth.
                </p>
                <div className="flex flex-col sm:flex-row gap-4 justify-center">
                  <Link to="/contact">
                    <Button size="lg" className="w-full sm:w-auto text-lg py-7 px-8 bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] hover:opacity-90 text-white font-semibold shadow-[0_0_20px_rgba(14,165,233,0.3)] hover:shadow-[0_0_30px_rgba(14,165,233,0.5)] transform hover:scale-105 transition-all duration-300">
                      <span className="bg-gradient-to-r from-white via-white to-white/90 bg-clip-text text-transparent drop-shadow-[0_2px_2px_rgba(0,0,0,0.4)]">Get Started</span>
                      <ArrowRight className="ml-2 h-5 w-5 text-white" />
                    </Button>
                  </Link>
                  <Link to="/schedule">
                    <Button size="lg" variant="outline" className="w-full sm:w-auto text-lg py-7 px-8 bg-white/5 backdrop-blur-sm border-2 border-white/10 hover:bg-white/10 hover:border-[#0EA5E9]/50 transform hover:scale-105 transition-all duration-300">
                      <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent">Schedule Call</span>
                      <ArrowRight className="ml-2 h-5 w-5 text-[#0EA5E9]" />
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <ServiceAreas />
      </main>
      <FooterComponent />
    </div>
  );
};

export default SoftwareSolutions;
