
import { FileText } from "lucide-react";
import CaseStudyCard from "./CaseStudyCard";

const caseStudies = [
  {
    title: "Enterprise E-commerce Transformation",
    client: "Global Retail Solutions",
    industry: "Retail",
    results: [
      "250% increase in online sales",
      "45% improvement in conversion rate",
      "2.5x faster page load times",
      "99.99% uptime achieved"
    ],
    image: "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?q=80",
    tags: ["E-commerce", "Web Development", "Performance Optimization"]
  },
  {
    title: "AI-Powered Marketing Platform",
    client: "TechStart Innovation",
    industry: "Technology",
    results: [
      "3x increase in qualified leads",
      "68% reduction in customer acquisition cost",
      "89% automation of routine tasks",
      "4.8/5 client satisfaction score"
    ],
    image: "https://images.unsplash.com/photo-1488590528505-98d2b5aba04b?q=80",
    tags: ["AI/ML", "SaaS", "Digital Marketing"]
  },
  {
    title: "Healthcare Management System",
    client: "MedCare Solutions",
    industry: "Healthcare",
    results: [
      "75% reduction in processing time",
      "100% HIPAA compliance achieved",
      "95% patient satisfaction rate",
      "500k+ patient records managed"
    ],
    image: "https://images.unsplash.com/photo-1518770660439-4636190af475?q=80",
    tags: ["Healthcare", "Software Development", "Security"]
  }
];

const CaseStudyGrid = () => {
  return (
    <section className="relative py-20">
      <div className="container mx-auto px-4">
        <div className="flex items-center gap-4 mb-12">
          <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-[#0EA5E9]/10">
            <FileText className="h-6 w-6 text-[#0EA5E9]" />
          </div>
          <div>
            <h2 className="text-2xl font-bold text-white">Featured Case Studies</h2>
            <p className="text-gray-400">Real results for real businesses</p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {caseStudies.map((study, index) => (
            <CaseStudyCard key={index} study={study} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default CaseStudyGrid;
