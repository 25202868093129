
import { Helmet } from "react-helmet";
import Header from "@/components/Header";
import FooterComponent from "@/components/footer-component";

const CookiePolicy = () => {
  return (
    <div className="min-h-screen bg-black text-white">
      <Helmet>
        <title>Cookie Policy | Ironball Marketing</title>
        <meta name="description" content="Learn about how we use cookies and collect data to improve your experience on our website." />
      </Helmet>

      <Header />

      <div className="container mx-auto px-4 pt-32 pb-16 max-w-4xl">
        <h1 className="text-4xl font-bold mb-8">Cookie Policy</h1>
        
        <div className="space-y-8 text-gray-300">
          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">What Are Cookies?</h2>
            <p className="mb-4">
              Cookies are small text files that are placed on your computer or mobile device when you visit our website. They are widely used to make websites work more efficiently and provide valuable information to website owners.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">How We Use Cookies</h2>
            <p className="mb-4">We use cookies for the following purposes:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Essential cookies: Required for the website to function properly</li>
              <li>Preference cookies: Remember your settings and preferences</li>
              <li>Analytics cookies: Help us understand how visitors use our website</li>
              <li>Marketing cookies: Used to deliver relevant advertisements</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">Specific Cookies We Use</h2>
            <div className="space-y-4">
              <div>
                <h3 className="text-xl font-medium text-white mb-2">Google Analytics</h3>
                <p>We use Google Analytics to understand how visitors interact with our website. This service may collect:</p>
                <ul className="list-disc pl-6 space-y-1 mt-2">
                  <li>Pages visited and time spent</li>
                  <li>Device and browser information</li>
                  <li>Geographic location (country/city level)</li>
                  <li>Referral sources</li>
                </ul>
              </div>
              
              <div>
                <h3 className="text-xl font-medium text-white mb-2">Essential Website Cookies</h3>
                <p>Our website uses cookies to:</p>
                <ul className="list-disc pl-6 space-y-1 mt-2">
                  <li>Remember your cookie consent preferences</li>
                  <li>Maintain your session information</li>
                  <li>Enable basic website functionality</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">Your Cookie Choices</h2>
            <p className="mb-4">
              You can control and/or delete cookies as you wish. You can delete all cookies that are already on your computer and you can set most browsers to prevent them from being placed. However, if you do this, you may have to manually adjust some preferences every time you visit our site and some services and functionalities may not work.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">Changes to This Policy</h2>
            <p className="mb-4">
              We may update our Cookie Policy from time to time. We will notify you of any changes by posting the new Cookie Policy on this page and updating the "Last Updated" date.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">Contact Us</h2>
            <p>
              If you have any questions about our Cookie Policy, please contact us at:{" "}
              <a href="mailto:hello@ironballmarketing.com" className="text-blue-400 hover:underline">
                hello@ironballmarketing.com
              </a>
            </p>
          </section>

          <p className="text-sm text-gray-400 pt-8">Last Updated: March 14, 2024</p>
        </div>
      </div>

      <FooterComponent />
    </div>
  );
};

export default CookiePolicy;
