
import CaseStudiesHero from "./case-studies/CaseStudiesHero";
import CaseStudiesStats from "./case-studies/CaseStudiesStats";
import CaseStudyGrid from "./case-studies/CaseStudyGrid";

const CaseStudiesComponent = () => {
  return (
    <div className="min-h-screen bg-black">
      <CaseStudiesHero />
      <CaseStudiesStats />
      <CaseStudyGrid />
    </div>
  );
};

export default CaseStudiesComponent;
