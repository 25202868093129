
import { useQuery } from "@tanstack/react-query";
import { getContactMethods } from "@/services/organization.service";
import { Building2, Mail, Phone, Facebook, Twitter, Instagram, Linkedin } from "lucide-react";

const iconMap: { [key: string]: any } = {
  facebook: Facebook,
  twitter: Twitter,
  instagram: Instagram,
  linkedin: Linkedin,
  phone: Phone,
  email: Mail
};

const socialMediaLabels: { [key: string]: string } = {
  facebook: "Visit our Facebook page",
  twitter: "Follow us on Twitter",
  instagram: "Follow us on Instagram",
  linkedin: "Connect with us on LinkedIn"
};

export const ContactMethods = () => {
  const { data: contactMethods, isLoading } = useQuery({
    queryKey: ['contactMethods'],
    queryFn: getContactMethods,
  });

  if (isLoading) return <div>Loading...</div>;
  if (!contactMethods?.length) return null;

  const socialMediaMethods = contactMethods.filter(method => 
    ['facebook', 'twitter', 'instagram', 'linkedin'].includes(method.type)
  );

  const primaryContactMethods = contactMethods.filter(method =>
    ['phone', 'email'].includes(method.type)
  );

  return (
    <div className="bg-white/5 backdrop-blur-sm rounded-xl p-6 border border-white/10">
      <div className="flex items-center gap-3 mb-4">
        <Building2 className="h-6 w-6 text-[#0EA5E9]" />
        <h3 className="text-xl font-semibold text-white">Contact Us</h3>
      </div>

      <div className="space-y-4 text-gray-300">
        {primaryContactMethods.map((method) => {
          const Icon = iconMap[method.type];
          return (
            <div key={method.id} className="flex items-center gap-2">
              <Icon className="h-4 w-4 text-[#0EA5E9]" />
              <a
                href={method.type === 'email' ? `mailto:${method.value}` : `tel:${method.value}`}
                className="hover:text-[#0EA5E9] transition-colors"
                aria-label={method.type === 'email' ? `Email us at ${method.value}` : `Call us at ${method.value}`}
              >
                {method.value}
              </a>
            </div>
          );
        })}

        <div className="flex gap-4 mt-6">
          {socialMediaMethods.map((method) => {
            const Icon = iconMap[method.type];
            return (
              <a
                key={method.id}
                href={method.value}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-[#0EA5E9] transition-colors p-2"
                aria-label={socialMediaLabels[method.type]}
              >
                <Icon className="h-5 w-5" aria-hidden="true" />
                <span className="sr-only">{socialMediaLabels[method.type]}</span>
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};
