
import { useState } from "react";
import { Menu, X, ArrowRight } from "lucide-react";
import { Button } from "./ui/button";
import { Link, useLocation } from "react-router-dom";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "./ui/navigation-menu";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const services = [
    {
      title: "Web Development",
      description: "Custom enterprise-grade web solutions",
      href: "/web-development",
    },
    {
      title: "Digital Marketing",
      description: "Data-driven marketing strategies",
      href: "/digital-marketing",
    },
    {
      title: "Software Development",
      description: "Scalable custom applications",
      href: "/software-solutions",
    },
    {
      title: "SaaS Development",
      description: "Build your software-as-a-service product",
      href: "/saas-development",
    },
  ];

  const handleNavigation = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  return (
    <header className="fixed w-full top-0 z-50 bg-black/50 backdrop-blur-md border-b border-white/5">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-20">
          <Link to="/" className="flex items-center space-x-3" onClick={handleNavigation}>
            <img 
              src="/lovable-uploads/7a8b1227-ba3f-445b-a121-9519a366f800.png" 
              alt="Ironball Marketing" 
              className="h-12 w-auto"
            />
          </Link>

          <NavigationMenu className="hidden lg:flex">
            <NavigationMenuList className="gap-8">
              <NavigationMenuItem>
                <Link to="/" className="text-white hover:text-[#0EA5E9] transition-colors duration-200 font-medium">
                  Home
                </Link>
              </NavigationMenuItem>

              <NavigationMenuItem>
                <NavigationMenuTrigger 
                  className="text-white hover:text-[#0EA5E9] bg-transparent font-medium text-base"
                  aria-label="Open Services Menu"
                >
                  Services
                </NavigationMenuTrigger>
                <NavigationMenuContent>
                  <div className="grid gap-2 p-6 w-[400px] bg-black/90 backdrop-blur-md border border-white/5 rounded-xl">
                    {services.map((service) => (
                      <Link
                        key={service.title}
                        to={service.href}
                        className="block p-4 hover:bg-white/5 rounded-lg transition-colors"
                      >
                        <div className="text-white font-medium">{service.title}</div>
                        <div className="text-gray-400 text-sm">{service.description}</div>
                      </Link>
                    ))}
                  </div>
                </NavigationMenuContent>
              </NavigationMenuItem>

              <NavigationMenuItem>
                <Link to="/about" className="text-white hover:text-[#0EA5E9] transition-colors duration-200 font-medium">
                  About Us
                </Link>
              </NavigationMenuItem>

              <NavigationMenuItem>
                <Link to="/process" className="text-white hover:text-[#0EA5E9] transition-colors duration-200 font-medium">
                  Our Process
                </Link>
              </NavigationMenuItem>

              <NavigationMenuItem>
                <Link to="/case-studies" className="text-white hover:text-[#0EA5E9] transition-colors duration-200 font-medium">
                  Case Studies
                </Link>
              </NavigationMenuItem>

              <NavigationMenuItem>
                <Link to="/contact" className="text-white hover:text-[#0EA5E9] transition-colors duration-200 font-medium">
                  Contact Us
                </Link>
              </NavigationMenuItem>
            </NavigationMenuList>
          </NavigationMenu>

          <div className="hidden lg:block">
            <Link to="/contact">
              <Button 
                className="relative overflow-hidden group bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] hover:from-[#38BDF8] hover:via-[#0EA5E9] hover:to-[#8B5CF6] text-white px-6 py-2.5 rounded-lg transition-all duration-300 transform hover:scale-105 shadow-lg"
                aria-label="Get Started - Contact Us"
              >
                <span className="absolute inset-0 bg-black/10 group-hover:translate-y-12 transition-transform duration-300" />
                <span className="relative flex items-center gap-2 font-semibold text-white drop-shadow-[0_2px_2px_rgba(0,0,0,0.3)]">
                  Get Started
                  <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform duration-200" />
                </span>
              </Button>
            </Link>
          </div>

          <button
            className="lg:hidden text-white"
            onClick={() => setIsOpen(!isOpen)}
            aria-label={isOpen ? "Close Menu" : "Open Menu"}
            aria-expanded={isOpen}
          >
            {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
          </button>
        </div>
      </div>

      <div
        className={`lg:hidden fixed inset-0 top-20 z-[999] transition-transform duration-300 ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
        style={{ backgroundColor: '#000' }}
      >
        <div className="fixed inset-0 bg-black" />
        <nav className="relative h-[calc(100vh-5rem)] overflow-y-auto bg-black container mx-auto px-4 py-6 space-y-6">
          <Link
            to="/"
            className="block text-white hover:text-[#0EA5E9] text-lg font-medium transition-colors duration-200"
            onClick={handleNavigation}
          >
            Home
          </Link>
          
          <div className="space-y-4">
            <div className="text-white/60 text-sm font-medium px-4">Services</div>
            {services.map((service) => (
              <Link
                key={service.title}
                to={service.href}
                className="block px-4 py-2 text-white hover:text-[#0EA5E9] transition-colors duration-200"
                onClick={handleNavigation}
              >
                {service.title}
              </Link>
            ))}
          </div>

          <Link
            to="/about"
            className="block text-white hover:text-[#0EA5E9] text-lg font-medium transition-colors duration-200"
            onClick={handleNavigation}
          >
            About Us
          </Link>

          <Link
            to="/process"
            className="block text-white hover:text-[#0EA5E9] text-lg font-medium transition-colors duration-200"
            onClick={handleNavigation}
          >
            Our Process
          </Link>

          <Link
            to="/case-studies"
            className="block text-white hover:text-[#0EA5E9] text-lg font-medium transition-colors duration-200"
            onClick={handleNavigation}
          >
            Case Studies
          </Link>

          <Link
            to="/contact"
            className="block text-white hover:text-[#0EA5E9] text-lg font-medium transition-colors duration-200"
            onClick={handleNavigation}
          >
            Contact Us
          </Link>

          <div className="pt-6">
            <Link to="/contact" onClick={handleNavigation}>
              <Button 
                className="w-full relative overflow-hidden group bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] hover:from-[#38BDF8] hover:via-[#0EA5E9] hover:to-[#8B5CF6] text-white px-6 py-2.5 shadow-lg"
                aria-label="Get Started - Contact Us"
              >
                <span className="absolute inset-0 bg-black/10 group-hover:translate-y-12 transition-transform duration-300" />
                <span className="relative flex items-center justify-center gap-2 font-semibold text-white drop-shadow-[0_2px_2px_rgba(0,0,0,0.3)]">
                  Get Started
                  <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform duration-200" />
                </span>
              </Button>
            </Link>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
