import { Button } from "./ui/button";
import { Badge } from "./ui/badge";
import { ArrowRight } from "lucide-react";
import { Link } from "react-router-dom";
import { useIsMobile } from "../hooks/use-mobile";
interface HeroAction {
  text: string;
  href: string;
  icon?: React.ReactNode;
  variant?: "default" | "outline";
}
interface HeroProps {
  badge?: {
    text: string;
    action: {
      text: string;
      href: string;
    };
  };
  title: string;
  description: string;
  actions: HeroAction[];
}
const HeroAlternative = ({
  badge,
  title,
  description,
  actions
}: HeroProps) => {
  const isMobile = useIsMobile();
  return <section className="relative text-white py-12 sm:py-24 md:py-32 px-4 overflow-hidden">
      {!isMobile ? <div className="absolute inset-0">
          <div className="absolute -left-20 top-20 h-[400px] w-[400px] rounded-full bg-[#8B5CF6]/30 translate-gpu" style={{
        filter: 'blur(100px)'
      }} />
          <div className="absolute -right-20 bottom-20 h-[400px] w-[400px] rounded-full bg-[#0EA5E9]/30 translate-gpu" style={{
        filter: 'blur(100px)'
      }} />
          <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]" />
        </div> : <div className="absolute inset-0 bg-gradient-to-b from-[#8B5CF6]/10 to-[#0EA5E9]/10" />}

      <div className="mx-auto flex max-w-[1400px] flex-col gap-8 sm:gap-12 pt-8 sm:pt-16 relative z-10">
        <div className="flex flex-col items-center gap-6 text-center">
          {badge && <div className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-white/5 backdrop-blur-sm border border-white/10">
              <span className="text-sm text-gray-400">{badge.text}</span>
              <Link to={badge.action.href} className="flex items-center gap-1 text-[#0EA5E9] text-sm" aria-label={`${badge.action.text} about ${badge.text}`}>
                {badge.action.text}
                <ArrowRight className="h-4 w-4" />
              </Link>
            </div>}

          <div className="space-y-4">
            <h1 className="text-3xl sm:text-5xl md:text-6xl lg:text-7xl font-bold leading-tight mb-6">
              Transform Your Business
              <br />
              <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent inline-block py-[12px]">
                Through Innovation
              </span>
            </h1>
            <p className="text-xl sm:text-2xl md:text-3xl text-gray-300 font-normal max-w-3xl mx-auto">
              AI-Powered Solutions for the Modern Enterprise
            </p>
          </div>

          <p className="text-base sm:text-lg text-gray-300 max-w-[600px] mx-auto leading-relaxed">
            {description}
          </p>

          <div className="flex flex-col sm:flex-row justify-center gap-4 mt-8 w-full max-w-[500px] sm:max-w-none">
            {actions.map((action, index) => <div key={index} className="w-full sm:w-auto">
                <Button variant={action.variant} size="lg" className={`
                    w-full sm:w-auto px-6 py-3
                    ${action.variant === "default" ? "bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] hover:opacity-90 text-white" : "bg-white/5 border border-white/10 hover:bg-white/10"}
                  `} aria-label={action.text}>
                  <Link to={action.href} className="flex items-center justify-center gap-2">
                    {action.icon}
                    <span className={action.variant === "outline" ? "bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent" : "text-white"}>
                      {action.text}
                    </span>
                    <ArrowRight className={`w-4 h-4 ${action.variant === "outline" ? "text-[#0EA5E9]" : "text-white"}`} />
                  </Link>
                </Button>
              </div>)}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-12 w-full max-w-[900px]">
            {[{
            number: "100+",
            text: "Custom Software Solutions"
          }, {
            number: "500+",
            text: "Projects Delivered"
          }, {
            number: "20+",
            text: "Years Experience"
          }].map((stat, index) => <div key={index} className="bg-white/5 backdrop-blur-sm p-6 rounded-lg border border-white/10">
                <div className="text-3xl font-bold text-[#0EA5E9] mb-1">{stat.number}</div>
                <div className="text-sm text-white">{stat.text}</div>
              </div>)}
          </div>
        </div>
      </div>
    </section>;
};
export default HeroAlternative;