import React from "react";
import { Helmet } from "react-helmet";
import Header from "@/components/Header";
import FooterComponent from "@/components/footer-component";
import { 
  Mail, ArrowRight, Send, MailCheck, MailSearch, 
  Users, Target, BarChart, ThumbsUp, ThumbsDown, 
  CheckCircle, XCircle 
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { Link } from "react-router-dom";
import ServiceAreas from "@/components/ServiceAreas";

const EmailMarketingPage = () => {
  const emailServices = [
    {
      title: "Campaign Strategy & Planning",
      description: "Data-driven email campaign planning tailored to your goals",
      icon: <Target className="h-10 w-10 text-[#0EA5E9] mb-6" />,
      features: [
        "Audience segmentation",
        "Campaign calendar creation",
        "A/B testing strategy",
        "Performance goal setting"
      ]
    },
    {
      title: "Email Design & Content",
      description: "Professional email templates and compelling content",
      icon: <Mail className="h-10 w-10 text-[#0EA5E9] mb-6" />,
      features: [
        "Responsive email templates",
        "Dynamic content creation",
        "Brand consistency",
        "Mobile optimization"
      ]
    },
    {
      title: "Automation & Workflows",
      description: "Sophisticated email automation for better engagement",
      icon: <Send className="h-10 w-10 text-[#0EA5E9] mb-6" />,
      features: [
        "Welcome sequences",
        "Nurture campaigns",
        "Behavior triggers",
        "Re-engagement flows"
      ]
    },
    {
      title: "Analytics & Optimization",
      description: "Comprehensive tracking and continuous improvement",
      icon: <BarChart className="h-10 w-10 text-[#0EA5E9] mb-6" />,
      features: [
        "Open rate analysis",
        "Click-through tracking",
        "Conversion metrics",
        "ROI measurement"
      ]
    }
  ];

  const campaignTypes = [
    {
      title: "Welcome Series",
      description: "Onboard new subscribers with engaging welcome sequences",
      icon: <MailCheck className="h-8 w-8 text-[#0EA5E9]" />,
      metrics: "45% higher engagement"
    },
    {
      title: "Nurture Campaigns",
      description: "Guide prospects through their customer journey",
      icon: <Users className="h-8 w-8 text-[#0EA5E9]" />,
      metrics: "3x conversion rate"
    },
    {
      title: "Re-engagement",
      description: "Win back inactive subscribers with targeted campaigns",
      icon: <MailSearch className="h-8 w-8 text-[#0EA5E9]" />,
      metrics: "25% reactivation rate"
    },
    {
      title: "Promotional Emails",
      description: "Drive sales with compelling promotional campaigns",
      icon: <Send className="h-8 w-8 text-[#0EA5E9]" />,
      metrics: "30% revenue increase"
    }
  ];

  const pros = [
    "High ROI - average $42 per $1 spent",
    "Direct communication channel",
    "Highly personalized messaging",
    "Automated customer journeys",
    "Easy to measure and optimize",
    "Builds customer loyalty",
    "Scalable marketing solution",
    "Own your subscriber list"
  ];

  const cons = [
    "List maintenance required",
    "Deliverability challenges",
    "Need for fresh content",
    "SPAM filter navigation",
    "Competitive inbox space",
    "Requires consistent effort",
    "Privacy regulations compliance",
    "Technical setup needed"
  ];

  return (
    <div className="min-h-screen bg-black flex flex-col">
      <Helmet>
        <title>Email Marketing Services Vancouver | Marketing Automation</title>
        <meta name="description" content="Build lasting customer relationships through intelligent email automation. Vancouver's experts in personalized campaigns and customer journey optimization." />
        <meta property="og:title" content="Email Marketing Services Vancouver | Marketing Automation" />
        <meta property="og:description" content="Maximize customer lifetime value with strategic email marketing. Vancouver's automation experts delivering personalized subscriber experiences." />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Email Marketing Services Vancouver | Marketing Automation" />
        <meta name="twitter:description" content="Maximize customer lifetime value with strategic email marketing. Vancouver's automation experts delivering personalized subscriber experiences." />
      </Helmet>
      <Header />
      <main className="flex-grow pt-20">
        <section className="relative text-white py-24 px-4 overflow-hidden border-b border-white/5">
          <div className="absolute inset-0">
            <div 
              className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1557200134-90327ee9fafa?q=80&w=1920&auto=format')] 
              bg-cover bg-center bg-no-repeat"
              style={{ backgroundSize: 'cover' }}
            />
            <div className="absolute inset-0 bg-gradient-to-b from-black/80 via-black/70 to-black" />
          </div>

          <div className="absolute inset-0">
            <div className="absolute -left-20 top-20 h-[400px] w-[400px] rounded-full bg-[#8B5CF6]/30 blur-[100px] animate-pulse" />
            <div className="absolute -right-20 bottom-20 h-[400px] w-[400px] rounded-full bg-[#0EA5E9]/30 blur-[100px] animate-pulse" />
            <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]" />
          </div>

          <div className="container mx-auto relative z-10">
            <div className="max-w-4xl mx-auto text-center">
              <div className="inline-block p-4 bg-white/5 backdrop-blur-sm rounded-2xl mb-6 border border-white/10">
                <Mail className="h-8 w-8 text-[#0EA5E9]" />
              </div>
              <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-6">
                Strategic
                <br />
                <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent">
                  Email Marketing
                </span>
              </h1>
              <p className="text-xl text-gray-300 mb-10 leading-relaxed max-w-3xl mx-auto">
                Transform your customer relationships with intelligent email automation and personalized campaigns that drive results.
              </p>
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <Link to="/contact">
                  <Button size="lg" className="w-full sm:w-auto text-lg py-7 px-8 bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] hover:opacity-90 text-white font-semibold shadow-[0_0_20px_rgba(14,165,233,0.3)] hover:shadow-[0_0_30px_rgba(14,165,233,0.5)] transform hover:scale-105 transition-all duration-300">
                    <span className="bg-gradient-to-r from-white via-white to-white/90 bg-clip-text text-transparent drop-shadow-[0_2px_2px_rgba(0,0,0,0.4)]">Get Started</span>
                    <ArrowRight className="ml-2 h-5 w-5 text-white" />
                  </Button>
                </Link>
                <Link to="/case-studies">
                  <Button size="lg" variant="outline" className="w-full sm:w-auto text-lg py-7 px-8 bg-white/5 backdrop-blur-sm border-2 border-white/10 hover:bg-white/10 hover:border-[#0EA5E9]/50 transform hover:scale-105 transition-all duration-300">
                    <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent">View Case Studies</span>
                    <ArrowRight className="ml-2 h-5 w-5 text-[#0EA5E9]" />
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="py-24 relative overflow-hidden border-b border-white/5">
          <div className="container mx-auto px-4 relative z-10">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold text-white mb-6">Our Email Marketing Services</h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                Comprehensive email marketing solutions designed to engage your audience and drive conversions.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {emailServices.map((service) => (
                <div key={service.title} className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10 hover:border-[#0EA5E9]/30 transition-all duration-300">
                  {service.icon}
                  <h3 className="text-2xl font-semibold text-white mb-4">{service.title}</h3>
                  <p className="text-gray-300 mb-6">{service.description}</p>
                  <ul className="space-y-3">
                    {service.features.map((feature, index) => (
                      <li key={index} className="flex items-center gap-3 text-gray-300">
                        <CheckCircle className="h-5 w-5 text-[#0EA5E9] flex-shrink-0" />
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="py-24 relative overflow-hidden border-b border-white/5">
          <div className="container mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold text-white mb-6">Campaign Types</h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                Strategic email campaigns designed to achieve your business goals.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {campaignTypes.map((type) => (
                <Card key={type.title} className="bg-white/5 backdrop-blur-sm border-white/10 hover:bg-white/10 transition-all duration-300">
                  <CardContent className="p-6">
                    <div className="mb-4">{type.icon}</div>
                    <h3 className="text-xl font-semibold text-white mb-2">{type.title}</h3>
                    <p className="text-gray-300 mb-4">{type.description}</p>
                    <p className="text-sm text-[#0EA5E9]">{type.metrics}</p>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </section>

        <section className="py-24 relative overflow-hidden border-b border-white/5">
          <div className="container mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold text-white mb-6">Email Marketing Insights</h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                Understanding the advantages and challenges of email marketing.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-5xl mx-auto">
              <div className="bg-gradient-to-br from-[#0EA5E9]/10 to-[#0EA5E9]/5 backdrop-blur-sm rounded-xl p-8 border border-[#0EA5E9]/20">
                <div className="flex items-center gap-3 mb-6">
                  <ThumbsUp className="h-8 w-8 text-[#0EA5E9]" />
                  <h3 className="text-2xl font-semibold text-white">Benefits</h3>
                </div>
                <ul className="space-y-4">
                  {pros.map((pro, index) => (
                    <li key={index} className="flex items-center gap-3 text-gray-300">
                      <CheckCircle className="h-5 w-5 text-[#0EA5E9] flex-shrink-0" />
                      <span>{pro}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="bg-gradient-to-br from-[#ea384c]/10 to-[#ea384c]/5 backdrop-blur-sm rounded-xl p-8 border border-[#ea384c]/20">
                <div className="flex items-center gap-3 mb-6">
                  <ThumbsDown className="h-8 w-8 text-[#ea384c]" />
                  <h3 className="text-2xl font-semibold text-white">Challenges</h3>
                </div>
                <ul className="space-y-4">
                  {cons.map((con, index) => (
                    <li key={index} className="flex items-center gap-3 text-gray-300">
                      <XCircle className="h-5 w-5 text-[#ea384c] flex-shrink-0" />
                      <span>{con}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="py-24 relative overflow-hidden">
          <div className="container mx-auto px-4">
            <div className="max-w-4xl mx-auto bg-white/5 backdrop-blur-sm rounded-2xl p-12 border border-white/10">
              <div className="text-center">
                <h2 className="text-4xl font-bold text-white mb-6">Ready to Get Started?</h2>
                <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
                  Transform your email marketing strategy with our expert team. Let's create campaigns that connect and convert.
                </p>
                <div className="flex flex-col sm:flex-row gap-4 justify-center">
                  <Link to="/contact">
                    <Button size="lg" className="w-full sm:w-auto text-lg py-7 px-8 bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] hover:opacity-90 text-white font-semibold shadow-[0_0_20px_rgba(14,165,233,0.3)] hover:shadow-[0_0_30px_rgba(14,165,233,0.5)] transform hover:scale-105 transition-all duration-300">
                      <span className="bg-gradient-to-r from-white via-white to-white/90 bg-clip-text text-transparent drop-shadow-[0_2px_2px_rgba(0,0,0,0.4)]">Get Started</span>
                      <ArrowRight className="ml-2 h-5 w-5 text-white" />
                    </Button>
                  </Link>
                  <Link to="/case-studies">
                    <Button size="lg" variant="outline" className="w-full sm:w-auto text-lg py-7 px-8 bg-white/5 backdrop-blur-sm border-2 border-white/10 hover:bg-white/10 hover:border-[#0EA5E9]/50 transform hover:scale-105 transition-all duration-300">
                      <span className="bg-gradient-to-r from-[#8B5CF6] via-[#0EA5E9] to-[#38BDF8] bg-clip-text text-transparent">View Case Studies</span>
                      <ArrowRight className="ml-2 h-5 w-5 text-[#0EA5E9]" />
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <FooterComponent />
    </div>
  );
};

export default EmailMarketingPage;
